import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'

import { axios_get_call } from '../../../components/AxiosCall';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';
import Container from '../../../components/Container';

import FormEntriesIcon from '@mui/icons-material/Notes';
import { DropdownInput } from '../../../components/DropdownInput';
const FormEntries = ({ user }) => {

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [all_form_ids, set_all_form_ids] = useState(false);
    const [form_id, set_form_id] = useState(false);
    const [form_entries, set_form_entries] = useState(false);

    const navigate = useNavigate()

    const get_all_form_ids = async () => {
        let url = '/saas/forms/get-all-form-ids/';
        let res = await axios_get_call(url, {}, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_all_form_ids(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }
    const get_form_entries = async () => {
        let url = '/saas/forms/get-form-entries/';
        let res = await axios_get_call(url, { form_id }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_form_entries(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    useEffect(() => {
        get_all_form_ids()
    }, [])
    useEffect(() => {
        if (form_id) {
            get_form_entries()
        }
    }, [form_id])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])

    return (
        user ? <Box>
            <LoadingScreen loading={loading} />
            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                    sx={{
                        p: 2,
                        mb: 2,
                        width: 1,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}

            <Container>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormEntriesIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
                        <Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
                            Form Entries
                        </Typography>
                    </Box>

                </Box>
            </Container>

            <Box sx={{ my: 4 }}>
                {all_form_ids &&
                    <Container>
                        <Typography sx={{ fontSize: "bold" }} variant='subtitle2'>Select a form</Typography>
                        <DropdownInput state={form_id} set_state={set_form_id} dropdown_options={all_form_ids} create_url={"/dashboard/forms/id=add"} title={"Forms"} />
                    </Container>
                }
            </Box>
            <Box sx={{ my: 4 }}>
                {form_id != -1 && form_id ?  form_entries  ?
                    <Container>
                        {render_dynamic_table(form_entries)}
                    </Container>
                :
                <Container>
                        <CircularProgress />
                    </Container>
                :""}
            </Box>
        </Box>
            : ''
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(FormEntries);

function render_dynamic_table(entries) {
    function convertSnakeCaseToTitleCase(snakeCaseString) {
        return snakeCaseString
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    
    // Extract unique keys from the entries
    const keys = Array.from(new Set(entries.flatMap(entry => Object.keys(entry))));

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {keys.map((key, index) => (
                    <Box sx={{ flex: 1, p:2, bgcolor:"primary.main", borderRadius:0 }}>

                        <Typography key={index} variant='subtitle2' align='left' style={{ textAlign: "left !important", fontWeight: "bold", color:"white", }} >
                        {convertSnakeCaseToTitleCase(key)}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Box>
                {entries.map((entry, rowIndex) => (
                    <Box key={rowIndex} sx={{ display: "flex", justifyContent: "space-between", bgcolor:  rowIndex % 2 && "#e8e8e8"  }}>
                        {keys.map((key, colIndex) => (
                            <Box sx={{ flex: 1, p:1}}>
                                <Typography key={colIndex} sx={{ color: "#191919" }} variant='subtitle2' align='left'>
                                    {entry[key]}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>
    );
}