import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Divider, TextField, Typography } from '@mui/material'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { axios_post_call, axios_get_call } from '../../components/AxiosCall';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '../../components/Container';

import SettingsIcon from "@mui/icons-material/Settings"
import RedirectIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LoadingScreen from '../../components/LoadingScreen';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function AccountSettings({ user, set_loading, set_alert }) {
    const [first_name, set_first_name] = useState(user ? user.employee.first_name : "")
    const [last_name, set_last_name] = useState(user ? user.employee.last_name : "")
    const [title, set_title] = useState(user ? user.employee.title : "")
    const [phone_no, set_phone_no] = useState(user ? user.employee.phone_no : "")
    const [website, set_website] = useState(user ? user.employee.website : "")

    const save_account_settings = async () => {
        let url = '/saas/user-settings/save-account-settings/';
        let res = await axios_post_call(url, { first_name, last_name, title, phone_no, website }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            // Reload the current page
            window.location.reload();

        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }

    }

    return (
        user && <Box>
            <Box sx={{ display: "flex", flexDirection: { md: "row", sm: "column", xs: "column" } }}>
                <Box sx={{ flex: 1, mr: 2 }}>
                    {/* First name input  */}
                    <Box sx={{ display: "flex", alignItems: "center", width: 1, }}>
                        <Box sx={{ flex: 1 }}><Typography sx={{ whiteSpace: "nowrap" }} variant='subtitle2' >First Name</Typography></Box>
                        <Box sx={{ flex: 3 }}>
                            <TextField value={first_name} onChange={(e) => { set_first_name(e.target.value) }} variant='outlined' my={2} size="small" fullWidth />
                        </Box>
                    </Box>
                    {/* last name input  */}
                    <Box sx={{ display: "flex", alignItems: "center", width: 1, my: 1 }}>
                        <Box sx={{ flex: 1 }}><Typography sx={{ whiteSpace: "nowrap" }} variant='subtitle2' >Last Name</Typography></Box>
                        <Box sx={{ flex: 3 }}>
                            <TextField value={last_name} onChange={(e) => { set_last_name(e.target.value) }} variant='outlined' my={2} size="small" fullWidth />
                        </Box>
                    </Box>
                    {/* Reset password  */}
                    <Box sx={{ display: "flex", alignItems: "center", width: 1, my: 1 }}>
                    <Button variant='outlined' onClick={() => { window.open("/reset-password", "_blank") }}>Reset Password <RedirectIcon /></Button>
                    </Box>
                    <Box my={4}>
                        <Divider />
                    </Box>
                    {/* title input  */}
                    <Box sx={{ display: "flex", alignItems: "center", width: 1, my: 1 }}>
                        {user.is_company_owner || user.is_also_admin ?
                            <>
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ whiteSpace: "nowrap" }} variant='subtitle2' >Title</Typography></Box>
                                <Box sx={{ flex: 3 }}>
                                    <TextField value={title} onChange={(e) => { set_title(e.target.value) }} variant='outlined' my={2} size="small" fullWidth />
                                </Box>
                            </>
                            :
                            <>
                                <Box sx={{ flex: 1 }}><Typography sx={{ whiteSpace: "nowrap" }} variant='subtitle2' >Title (Read only)</Typography></Box>
                                <Box sx={{ flex: 3 }}>
                            <TextField value={title} placeholder='Designation - Only admins can update it for you' onChange={(e) => { set_title(e.target.value) }} variant='outlined' my={2} size="small" fullWidth readonly disabled/>
                                    
                                </Box>
                            </>
                        }
                    </Box>
                    {/* phone_no input  */}
                    <Box sx={{ display: "flex", alignItems: "center", width: 1, my: 1 }}>
                        <Box sx={{ flex: 1 }}><Typography sx={{ whiteSpace: "nowrap" }} variant='subtitle2' >Phone no</Typography></Box>
                        <Box sx={{ flex: 3 }}>
                            <TextField value={phone_no} onChange={(e) => { set_phone_no(e.target.value) }} variant='outlined' my={2} size="small" fullWidth />
                        </Box>
                    </Box>
                    {/* website input  */}
                    <Box sx={{ display: "flex", alignItems: "center", width: 1, my: 1 }}>
                        <Box sx={{ flex: 1 }}><Typography sx={{ whiteSpace: "nowrap" }} variant='subtitle2' >Website</Typography></Box>
                        <Box sx={{ flex: 3 }}>
                            <TextField value={website} onChange={(e) => { set_website(e.target.value) }} variant='outlined' my={2} size="small" fullWidth />
                        </Box>
                    </Box>
                    <Box mt={4} mb={3}>
                        <Divider />
                    </Box>
                    <Box sx={{my:2, mr:2}}>
                    <Button variant='contained' onClick={() => { save_account_settings() }}>Save Account Settings</Button>
                    </Box>
                </Box>
                
            </Box>
        </Box>
    )
}



function OptionsSettings({ user, set_loading, set_alert }) {
    const [job_stages, set_job_stages] = useState([])
    const [project_stages, set_project_stages] = useState([])
    const [job_types, set_job_types] = useState([])
    const [lead_sources, set_lead_sources] = useState([])

    const [job_stages_value, set_job_stages_value] = useState("")
    const [project_stages_value, set_project_stages_value] = useState("")
    const [job_types_value, set_job_types_value] = useState("")
    const [lead_sources_value, set_lead_sources_value] = useState("")

    const get_all_job_stages = async () => {
        let url = '/saas/job-scheduling/get-all-job-stages/';
        let res = await axios_get_call(url, {}, set_loading);

        if (res.data.status == 1) {
            set_loading(false)
            set_job_stages(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }
    const get_all_project_stages = async () => {
        let url = '/saas/job-scheduling/get-all-project-stages/';
        let res = await axios_get_call(url, {}, set_loading);

        if (res.data.status == 1) {
            set_loading(false)
            set_project_stages(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }
    const get_all_job_types = async () => {
        let url = '/saas/job-scheduling/get-all-job-types/';
        let res = await axios_get_call(url, {}, set_loading);

        if (res.data.status == 1) {
            set_loading(false)
            set_job_types(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }
    const get_all_lead_sources = async () => {
        let url = '/saas/job-scheduling/get-all-lead-sources/';
        let res = await axios_get_call(url, {}, set_loading);

        if (res.data.status == 1) {
            set_loading(false)
            set_lead_sources(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }


    const save_option_settings = async () => {
        let url = '/saas/user-settings/save-option-settings/';
        let body = { "job_stages": job_stages, "project_stages": project_stages, "job_types": job_types, "lead_sources": lead_sources }
        let res = await axios_post_call(url, body, set_loading, set_alert);

        if (res.data.status == 1) {
            window.location.reload();

        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }

    }
    useEffect(() => {
        get_all_job_stages();
    }, [])
    useEffect(() => {
        get_all_project_stages();
    }, [])
    useEffect(() => {
        get_all_job_types();
    }, [])
    useEffect(() => {
        get_all_lead_sources();
    }, [])

    return (
        <Box>
            <Button variant='contained' onClick={() => { save_option_settings() }}>Save</Button>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box sx={{ flex: 1, my: 4, minWidth: "280px", height: "300px", }}>
                    <Box component={Container}>
                        <Box sx={{ minWidth: "280px", height: "300px", overflowY: "scroll" }}>
                            <Typography variant='h3'>Job Stage</Typography>
                            <Box my={1} sx={{ width: '92%' }}>
                                <Divider />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", width: '92%' }}>
                                <Box sx={{ flex: 1 }}>
                                    <TextField size="small" fullWidth placeholder='New Job Stage' value={job_stages_value} onChange={(e) => { set_job_stages_value(e.target.value) }} />
                                </Box>
                                <Box sx={{ flex: 0,  }}>
                                    <Button onClick={() => {
                                        let job_stages_state = JSON.parse(JSON.stringify(job_stages));
                                        for (const item of job_stages_state) {
                                            if (item.name == job_stages_value) {
                                                return;
                                            }
                                        }
                                        job_stages_state.push({ "id": "new", "name": job_stages_value })
                                        set_job_stages(job_stages_state)
                                        set_job_stages_value("")
                                    }} variant='outlined' sx={{ borderRadius: 2, width:"20px !important", maxWidth: "20px !important", height:40,  }}>Add +</Button>
                                </Box>
                            </Box>
                            <Box my={1} sx={{ width: '92%' }}>
                                <Divider />
                            </Box>
                            {job_stages.map((job_stage, index) => {
                                return (
                                    <Box key={job_stage.id} id={job_stage.id} sx={{
                                        display: 'flex',
                                        width: '92%',
                                        alignItems: "center",
                                        p: 1,
                                        "&:hover": {
                                            bgcolor: "#F9F9F9"
                                        }
                                    }}>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant='subtitle2'>{job_stage.name}
                                            </Typography>
                                        </Box>
                                        <Box sx={{
                                            flex: 0, p: 1, display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "&:hover": {
                                                borderRadius: 12,
                                                bgcolor: "#DDDDDD",
                                                cursor: "pointer"
                                            }
                                        }}>
                                            <DeleteIcon sx={{ color: "red" }} onClick={() => {
                                                let job_stages_state = JSON.parse(JSON.stringify(job_stages));
                                                job_stages_state.splice(index, 1)
                                                set_job_stages(job_stages_state)
                                            }} />
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ flex: 1, my: 4, minWidth: "280px", height: "300px", }}>
                    <Box component={Container}>
                        <Box sx={{ minWidth: "280px", height: "300px", overflowY: "scroll" }}>
                            <Typography variant='h3'>Project Stage</Typography>
                            <Box my={1} sx={{ width: '92%' }}>
                                <Divider />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", width: '92%' }}>
                                <Box sx={{ flex: 1 }}>
                                    <TextField size="small" fullWidth placeholder='New Project Stage' value={project_stages_value} onChange={(e) => { set_project_stages_value(e.target.value) }} />
                                </Box>
                                <Box sx={{ flex: 0,  }}>
                                    <Button onClick={() => {
                                        let project_stages_state = JSON.parse(JSON.stringify(project_stages));
                                        for (const item of project_stages_state) {
                                            if (item.name == project_stages_value) {
                                                return;
                                            }
                                        }
                                        project_stages_state.push({ "id": "new", "name": project_stages_value })
                                        set_project_stages(project_stages_state)
                                        set_project_stages_value("")
                                    }} variant='outlined' size="small" sx={{ borderRadius: 2, width:"20px", maxWidth: "20px", height:40,  }}>Add +</Button>
                                </Box>
                            </Box>
                            <Box my={1} sx={{ width: '92%' }}>
                                <Divider />
                            </Box>
                            {project_stages.map((project_stage, index) => {
                                return (
                                    <Box key={project_stage.id} id={project_stage.id} sx={{
                                        display: 'flex',
                                        width: '92%',
                                        alignItems: "center",
                                        p: 1,
                                        "&:hover": {
                                            bgcolor: "#F9F9F9"
                                        }
                                    }}>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant='subtitle2'>{project_stage.name}
                                            </Typography>
                                        </Box>
                                        <Box sx={{
                                            flex: 0, p: 1, display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "&:hover": {
                                                borderRadius: 12,
                                                bgcolor: "#DDDDDD",
                                                cursor: "pointer"
                                            }
                                        }}>
                                            <DeleteIcon sx={{ color: "red" }} onClick={() => {
                                                let project_stages_state = JSON.parse(JSON.stringify(project_stages));
                                                project_stages_state.splice(index, 1)
                                                set_project_stages(project_stages_state)
                                            }} />
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ flex: 1, my: 4, minWidth: "280px", height: "300px", }}>
                    <Box component={Container}>
                        <Box sx={{ minWidth: "280px", height: "300px", overflowY: "scroll" }}>
                            <Typography variant='h3'>Job Type</Typography>
                            <Box my={1} sx={{ width: '92%' }}>
                                <Divider />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", width: '92%' }}>
                                <Box sx={{ flex: 1 }}>
                                    <TextField size="small" fullWidth placeholder='New Job Type' value={job_types_value} onChange={(e) => { set_job_types_value(e.target.value) }} />
                                </Box>
                                <Box sx={{ flex: 0,  }}>
                                    <Button onClick={() => {
                                        let job_types_state = JSON.parse(JSON.stringify(job_types));
                                        for (const item of job_types_state) {
                                            if (item.name == job_types_value) {
                                                return;
                                            }
                                        }
                                        job_types_state.push({ "id": "new", "name": job_types_value })
                                        set_job_types(job_types_state)
                                        set_job_types_value("")
                                    }} variant='outlined' size="small" sx={{ borderRadius: 2, width:"20px", maxWidth: "20px", height:40,  }}>Add +</Button>
                                </Box>
                            </Box>
                            <Box my={1} sx={{ width: '92%' }}>
                                <Divider />
                            </Box>
                            {job_types.map((job_type, index) => {
                                return (
                                    <Box key={job_type.id} id={job_type.id} sx={{
                                        display: 'flex',
                                        width: '92%',
                                        alignItems: "center",
                                        p: 1,
                                        "&:hover": {
                                            bgcolor: "#F9F9F9"
                                        }
                                    }}>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant='subtitle2'>{job_type.name}
                                            </Typography>
                                        </Box>
                                        <Box sx={{
                                            flex: 0, p: 1, display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "&:hover": {
                                                borderRadius: 12,
                                                bgcolor: "#DDDDDD",
                                                cursor: "pointer"
                                            }
                                        }}>
                                            <DeleteIcon sx={{ color: "red" }} onClick={() => {
                                                let job_types_state = JSON.parse(JSON.stringify(job_types));
                                                job_types_state.splice(index, 1)
                                                set_job_types(job_types_state)
                                            }} />
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ flex: 1, my: 4, minWidth: "280px", height: "300px", }}>
                    <Box component={Container}>
                        <Box sx={{ minWidth: "280px", height: "300px", overflowY: "scroll" }}>
                            <Typography variant='h3'>Lead Source</Typography>
                            <Box my={1} sx={{ width: '92%' }}>
                                <Divider />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", width: '92%' }}>
                                <Box sx={{ flex: 1 }}>
                                    <TextField size="small" fullWidth placeholder='New Lead Source' value={lead_sources_value} onChange={(e) => { set_lead_sources_value(e.target.value) }} />
                                </Box>
                                <Box sx={{ flex: 0,  }}>
                                    <Button onClick={() => {
                                        let lead_sources_state = JSON.parse(JSON.stringify(lead_sources));
                                        for (const item of lead_sources_state) {
                                            if (item.name == lead_sources_value) {
                                                return;
                                            }
                                        }
                                        lead_sources_state.push({ "id": "new", "name": lead_sources_value })
                                        set_lead_sources(lead_sources_state)
                                        set_lead_sources_value("")
                                    }} variant='outlined' size="small" sx={{ borderRadius: 2, width:"20px", maxWidth: "20px", height:40,  }}>Add +</Button>
                                </Box>
                            </Box>
                            <Box my={1} sx={{ width: '92%' }}>
                                <Divider />
                            </Box>
                            {lead_sources.map((lead_source, index) => {
                                return (
                                    <Box key={lead_source.id} id={lead_source.id} sx={{
                                        display: 'flex',
                                        width: '92%',
                                        alignItems: "center",
                                        p: 1,
                                        "&:hover": {
                                            bgcolor: "#F9F9F9"
                                        }
                                    }}>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant='subtitle2'>{lead_source.name}
                                            </Typography>
                                        </Box>
                                        <Box sx={{
                                            flex: 0, p: 1, display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "&:hover": {
                                                borderRadius: 12,
                                                bgcolor: "#DDDDDD",
                                                cursor: "pointer"
                                            }
                                        }}>
                                            <DeleteIcon sx={{ color: "red" }} onClick={() => {
                                                let lead_sources_state = JSON.parse(JSON.stringify(lead_sources));
                                                lead_sources_state.splice(index, 1)
                                                set_lead_sources(lead_sources_state)
                                            }} />
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}



const Settings = ({ user }) => {
    const search = useLocation().search;
    const [tab_index_from_url, set_tab_index_from_url] = useState(new URLSearchParams(search).get("tab_index"))

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);

    const navigate = useNavigate()
    const [tab_index, set_tab_index] = React.useState(0);

    const change_tab_index = (event, index) => {
        set_tab_index(index);
    };

    useEffect(() => {
        if (!tab_index_from_url) {
            navigate(`/dashboard/settings?tab_index=${tab_index}`)
        }
        else {
            set_tab_index(parseInt(tab_index_from_url))
            set_tab_index_from_url(false)
        }
    }, [tab_index])
    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])

    return (
        user ? <Box sx={{mx:2}}>
            <LoadingScreen loading={loading} />

            <Box >
                <Container>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <SettingsIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
                            <Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
                                Settings
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                    sx={{
                        p: 2,
                        my: 2,
                        width: 1,
                        borderRadius: 2,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}
            <Box sx={{ my: 4 }}>
                <Container>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab_index} onChange={change_tab_index} aria-label="basic tabs example">
                            <Tab label="Account Settings" {...a11yProps(0)} />
                             {user && user.is_superuser && <Tab label="Option Settings" {...a11yProps(1)} />} 
                            {/* {user ? user.is_company_owner || user.is_also_admin ?
                                <Tab label="Company Settings" {...a11yProps(2)} />
                                : ""
                                : ""} */}
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tab_index} index={0}>
                        <AccountSettings user={user} set_loading={set_loading} set_alert={set_alert} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tab_index} index={1}>
                        <OptionsSettings user={user} set_loading={set_loading} set_alert={set_alert} />
                    </CustomTabPanel>
                    {/* {user ? user.is_company_owner || user.is_also_admin ?
                        <CustomTabPanel value={tab_index} index={2}>
                            <OptionsSettings user={user} set_loading={set_loading} set_alert={set_alert} />
                        </CustomTabPanel>
                        : ""
                        : ""} */}

                </Container>
            </Box>
        </Box > : ''
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});


export default connect(mapStateToProps, {})(Settings);
