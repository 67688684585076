import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Fab, Input, TextField, Tooltip, Typography } from '@mui/material'

import { axios_get_call, axios_post_call, axios_delete_call } from '../../../../components/AxiosCall';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '../../../../components/Container';
import LoadingScreen from '../../../../components/LoadingScreen';
import ProjectsIcon from '@mui/icons-material/WorkHistory';

import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import generate_local_timezone_based_iso_date from '../../../../components/generate_local_timezone_based_iso_date';

import { DropdownInput, MultiSelectDropdownInput } from '../../../../components/DropdownInput';

import ShareIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import FinishIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

import MultiFileInput from '../../../../components/MultiFileInput';
import Files from './Files';
import CustomModal from '../../../../components/CustomModal';

const ProjectDetails = ({ user }) => {

	const [alert, set_alert] = useState(false);
	const [loading, set_loading] = useState(false);
	const [project, set_project] = useState({});

	const navigate = useNavigate()
	const url_path = useLocation().search;
	const [project_id, set_project_id] = useState(new URLSearchParams(url_path).get('id'))

	const [editing_mode_disabled, set_editing_mode_disabled] = useState(true)
	const [share_with_client, set_share_with_client] = useState(false)
	const [delete_the_project, set_delete_the_project] = useState(false)

	const [selected_members, set_selected_members] = useState([])
	const [members, set_members] = useState([])

	const [clients, set_clients] = useState([])
	const [client, set_client] = useState(-1)

	const [project_stages, set_project_stages] = useState([])
	const [project_stage, set_project_stage] = useState(-1)

	const [job_types, set_job_types] = useState([])
	const [job_type, set_job_type] = useState(-1)

	const [lead_sources, set_lead_sources] = useState([])
	const [lead_source, set_lead_source] = useState(-1)

	const [title, set_title] = useState("")
	const [about, set_about] = useState("")
	const [details, set_details] = useState("")

	const [files, set_files] = useState([])
	const [project_files, set_project_files] = useState([])

	const today = new Date();
	const todayFormatted = today.toISOString().split('T')[0]; // 
	const [date, set_date] = useState(todayFormatted)
	const [timezone, set_timezone] = useState("")
	const [location, set_location] = useState("")

	const update_details = (new_state) => {
		set_details(new_state);
	}
	const convertEditorStateToHTML = (editorState) => {
		if (editorState.getCurrentContent) {
			const contentState = editorState.getCurrentContent();
			return stateToHTML(contentState);


		}

	};
	const convertHTMLToEditorState = (html) => {
		const contentBlock = htmlToDraft(html);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(
				contentBlock.contentBlocks
			);
			return EditorState.createWithContent(contentState);
		}
		return EditorState.createEmpty();
	};

	const get_project = async (project_id) => {
		let url = '/saas/job-scheduling/get-project/';
		let res = await axios_get_call(url, { project_id }, set_loading, set_alert);

		if (res.data.status == 1) {
			set_loading(false);
			set_project(res.data.response);
			set_title(res.data.response.name)
			set_about(res.data.response.about)
			set_details(convertHTMLToEditorState(res.data.response.details))
			set_client(res.data.response.client.id)
			set_project_stage(res.data.response.project_stage.id)
			set_job_type(res.data.response.job_type.id)
			set_lead_source(res.data.response.lead_source.id)
			set_project_files(res.data.response.files)
			set_date(new Date(res.data.response.date).toISOString().split('T')[0])
			set_timezone(res.data.response.timezone)
			set_location(res.data.response.location)

			set_selected_members(res.data.response.members)
		} else if (res.data.status == 0) {
			set_alert(res.data.response);
			set_loading(false);
		}
	}
	const get_clients = async () => {
		let url = '/saas/clients/get-clients/';
		let res = await axios_get_call(url, {}, set_loading, set_alert);

		if (res.data.status == 1) {
			set_loading(false);
			set_clients(res.data.response)
		} else if (res.data.status == 0) {
			set_alert(res.data.response);
			set_loading(false);
		}
	}
	const get_all_members = async () => {
		set_loading(true);
		let url = "/saas/user-management-system/get-all-members/";
		let res = await axios_get_call(url, { 'member_type': "Team Members" }, set_loading, set_alert);

		if (res.data.status == 1) {
			set_loading(false);
			set_members(res.data.response);
		} else if (res.data.status == 0) {
			set_alert(res.data.response);
			set_loading(false);
		}
	}
	const get_all_project_stages = async () => {
		set_loading(true);
		let url = "/saas/job-scheduling/get-all-project-stages/";
		let res = await axios_get_call(url, { 'member_type': "Team Members" }, set_loading, set_alert);

		if (res.data.status == 1) {
			set_loading(false);
			set_project_stages(res.data.response);
		} else if (res.data.status == 0) {
			set_alert(res.data.response);
			set_loading(false);
		}
	}
	const get_all_job_types = async () => {
		set_loading(true);
		let url = "/saas/job-scheduling/get-all-job-types/";
		let res = await axios_get_call(url, { 'member_type': "Team Members" }, set_loading, set_alert);

		if (res.data.status == 1) {
			set_loading(false);
			set_job_types(res.data.response);
		} else if (res.data.status == 0) {
			set_alert(res.data.response);
			set_loading(false);
		}
	}
	const get_all_lead_sources = async () => {
		set_loading(true);
		let url = "/saas/job-scheduling/get-all-lead-sources/";
		let res = await axios_get_call(url, { 'member_type': "Team Members" }, set_loading, set_alert);

		if (res.data.status == 1) {
			set_loading(false);
			set_lead_sources(res.data.response);
		} else if (res.data.status == 0) {
			set_alert(res.data.response);
			set_loading(false);
		}
	}
	const create_or_update_project = async (e) => {
		e.preventDefault()
		set_loading(true);
		let url = "/saas/job-scheduling/create-or-updated-project/";
		let body = new FormData();
		body.append('details', convertEditorStateToHTML(details))
		body.append('project_id', project_id)
		body.append('selected_members', JSON.stringify(selected_members))
		body.append('client', client)
		body.append('project_stage', project_stage)
		body.append('job_type', job_type)
		body.append('lead_source', lead_source)
		body.append('title', title)
		body.append('about', about)
		body.append('timezone', timezone)
		body.append('location', location)
		body.append('date', generate_local_timezone_based_iso_date(date))
		body.append('project_files', JSON.stringify(project_files))
		for (const index in files) {
			if (files.hasOwnProperty(index)) {
				body.append(`files_${index}`, files[index]);
			}
		}

		let res = await axios_post_call(url, body, set_loading, set_alert, "multipart/form-data");

		if (res.data.status == 1) {
			set_loading(false);
			get_project(res.data.response.project_id)
			set_project_id(res.data.response.project_id)
			set_alert(res.data.response.response);
			set_files([])
		} else if (res.data.status == 0) {
			set_alert(res.data.response);
			set_loading(false);
		}
	}

	const delete_project = async () => {
		set_loading(true);
		let url = "/saas/job-scheduling/delete-project/";
		let res = await axios_delete_call(url, { project_id }, set_loading, set_alert);

		if (res.data.status == 1) {
			set_loading(false);
			set_delete_the_project(false);
			navigate("/dashboard/projects")
		} else if (res.data.status == 0) {
			set_alert(res.data.response);
			set_loading(false);
		}
	}

	const calculateUserTimezone = () => {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	};

	// Use the useEffect hook to calculate the timezone when the component mounts
	useEffect(() => {
		const timezone = calculateUserTimezone();
		set_timezone(timezone);
	}, []);
	useEffect(() => {
		if (project_id)
			get_project(project_id)
	}, [])
	useEffect(() => {
		get_clients()
	}, [])
	useEffect(() => {
		get_all_members()
	}, [])
	useEffect(() => {
		get_all_project_stages()
	}, [])
	useEffect(() => {
		get_all_job_types()
	}, [])
	useEffect(() => {
		get_all_lead_sources()

	}, [])

	useEffect(() => {
		if (!(localStorage.getItem('access'))) {
			navigate('/login')
		}
	}, [])

	return (
		user ?
			user.is_company_owner || user.is_also_admin ?
				<Box sx={{ transition: "0.2s all" }} component="form" onSubmit={(e) => { create_or_update_project(e) }}>
					{(project_id && editing_mode_disabled) &&
						<Tooltip title="Enable editing mode" placement='top'>
							<Fab onClick={() => { set_editing_mode_disabled(false) }} size="large" color="primary" aria-label="add" sx={{ position: "fixed", bottom: 30, right: 30 }}>
								<EditIcon />
							</Fab>
						</Tooltip>
					}
					{(project_id && !editing_mode_disabled) &&
						<Tooltip title="Finish editing mode" placement='top'>
							<Fab onClick={() => { set_editing_mode_disabled(true) }} size="large" aria-label="add" sx={{ position: "fixed", bottom: 30, right: 30, bgcolor: "#03a806", "&:hover": { bgcolor: "#006001" } }}>
								<FinishIcon sx={{ color: "white" }} />
							</Fab>
						</Tooltip>
					}
					{project_id &&
						<Tooltip title="Share with client" placement='top'>
							<Fab onClick={() => { set_share_with_client(true) }} size="large" color="secondary" aria-label="add" sx={{ position: "fixed", bottom: 30, right: 170 }}>
								<ShareIcon sx={{ rotate: "-25Deg" }} />
							</Fab>
						</Tooltip>
					}
					{project_id &&
						<Tooltip title="Delete the project" placement='top'>
							<Fab onClick={() => { set_delete_the_project(true) }} size="large" color="error" aria-label="add" sx={{ position: "fixed", bottom: 30, right: 100 }}>
								<DeleteIcon />
							</Fab>
						</Tooltip>
					}
					{delete_the_project &&
						<CustomModal set_preview={set_delete_the_project}>
							<Box
								sx={{
									width: { xl: "35%", lg: "50%", md: "60%", sm: "80%", xs: "90%" },
									h: 1,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									bgcolor: "white",
									cursor: "auto",
									borderRadius: 3,
									boxShadow: 24,
									p: { xl: 4, lg: 4, md: 4, sm: 4, xs: 2 },
								}}
							>
								{alert ? (
									<Alert
										onClose={() => {
											set_alert(false);
										}}
										severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
										sx={{
											p: 2,
											width: '80%',
											zIndex: 9992000,

										}}
									>
										{alert}
									</Alert>
								) : (
									""
								)}

								<style
									dangerouslySetInnerHTML={{
										__html: "\n#menu-{\nz-index:9992000}\n",
									}}
								/>
								<Typography variant="h2" my={2}>
									Are you sure you want to delete this project?
								</Typography>
								<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<Box sx={{ flex: 1 }}><Button variant='contained' onClick={() => { delete_project() }}>Yes, Delete</Button></Box>
									<Box sx={{ flex: 1 }}><Button variant='outlined' onClick={() => { set_delete_the_project(false) }}>Cancel</Button></Box>
								</Box>
							</Box>
						</CustomModal>
					}
					<LoadingScreen loading={loading} />
					{alert ? (
						<Alert
							onClose={() => {
								set_alert(false);
							}}
							severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
							sx={{
								p: 2,
								mb: 2,
								width: 1,
								borderRadius:2,
zIndex: 9923213,
							}}
						>
							{alert}
						</Alert>
					) : (
						''
					)}
					{project && <Box>
						<Container>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: "wrap" }}>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<ProjectsIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
									{project_id && editing_mode_disabled ?
										<Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
											{project.name}
										</Typography>
										:
										<Box>
											<TextField required value={title} onChange={(e) => { set_title(e.target.value) }} size='small' id="Title-input" label="Project Name" variant="outlined" />
										</Box>
									}
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									{project_id ?
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Button variant='outlined' sx={{ mr: 1 }} onClick={() => { navigate("/dashboard/job-scheduling") }}>Assign as job</Button>
											<Button variant='contained' sx={{ ml: 1 }} type="submit">Update project</Button>
										</Box>
										:
										<Button variant='contained' type="submit">Create project</Button>
									}
								</Box>
							</Box>
						</Container>
						<Box sx={{ my: 2, display: "flex", justifyContent: "space-between", flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" } }}>
							{/* side 1 */}
							<Box sx={{ flex: 1, minWidth: "300px", mr: { xs: 0, sm: 0, md: 0, lg: 2 }, my: 2 }}>
								{/* About section  */}
								<Box>
									<Container>
										{project_id && project.about && editing_mode_disabled ?
											<Box>
												<Box>
													<Typography variant='body' sx={{ fontWeight: "bold" }}>About</Typography>
												</Box>
												<Box>
													<Typography variant='body'>{project.about}</Typography>
												</Box>
											</Box>
											: <TextField  value={about} onChange={(e) => { set_about(e.target.value) }} size='small' id="about-input" label="About" placeholder='A brief overview of what this project is about.' multiline rows={2} fullWidth variant="outlined" />}
									</Container>
								</Box>
								{/* Details section Description section */}
								<Box sx={{ my: 2 }}>
									<Container>
										{project_id && project.details && editing_mode_disabled ?
											<Box>
												<Box>
													<Typography variant='body' sx={{ fontWeight: "bold" }}>Project Details</Typography>
												</Box>
												<Box>
													<Typography variant='body' dangerouslySetInnerHTML={{ __html: convertEditorStateToHTML(details) }}></Typography>

												</Box>
											</Box>
											: <Editor
												editorState={details}
												toolbarClassName="toolbarClassName"
												wrapperClassName="wrapperClassName"
												editorClassName="editorClassName"
												onEditorStateChange={update_details}
												placeholder='Details like services, terms etc'
												spellCheck={true}
												mention={true}
												hashtag={true}
												
											/>
										}
									</Container>
								</Box>
								{/* Files section */}
								<Box sx={{ my: 2 }}>
									<Container>
										<Box>
											<Box>
												<Typography variant='body' sx={{ fontWeight: "bold" }}>Files</Typography>
											</Box>
											<Box>
												<Files files={project_files} set_files={set_project_files} editing_mode_disabled={editing_mode_disabled} />
												{(!editing_mode_disabled || !project_id) && <MultiFileInput already_created_files_length={project_files.length} files={files} set_files={set_files} />}
											</Box>
										</Box>
									</Container>
								</Box>
							</Box>
							{/* Side 2 */}
							<Box sx={{ flex: 1, minWidth: "300px", ml: { xs: 0, sm: 0, md: 0, lg: 2 }, my: 2 }}>
								{/* Client, members, stages etc area */}
								<Box mb={2}>
									<Container>
										{/* Clients input  */}
										<Box sx={{ my: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<Box sx={{ flex: 1 }}>
												<Typography variant='subtitle2' sx={{ mr: 1 }}>Client</Typography>
											</Box>
											<Box sx={{ flex: 1 }}>
												<DropdownInput state={client} set_state={set_client} dropdown_options={clients} create_url="/dashboard/clients" title="Client" />
											</Box>
										</Box>
										{/* Members input  */}
										<Box sx={{ my: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<Box sx={{ flex: 1 }}>
												<Typography variant='subtitle2' sx={{ mr: 1 }}>Members</Typography>
											</Box>
											<Box sx={{ flex: 1 }}>
												<MultiSelectDropdownInput state={selected_members} set_state={set_selected_members} dropdown_options={members} create_url="/dashboard/members" title="Member" />
											</Box>

										</Box>

										{/* Stages input  */}
										<Box sx={{ my: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<Box sx={{ flex: 1 }}>
												<Typography variant='subtitle2' sx={{ mr: 1 }}>Project Stage</Typography>
											</Box>
											<Box sx={{ flex: 1 }}>
												<DropdownInput state={project_stage} set_state={set_project_stage} dropdown_options={project_stages} create_url="/dashboard/settings?tab=inputs" title="Stage" />
											</Box>
										</Box>
										{/* Types input  */}
										<Box sx={{ my: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<Box sx={{ flex: 1 }}>
												<Typography variant='subtitle2' sx={{ mr: 1 }}>Job Type</Typography>
											</Box>
											<Box sx={{ flex: 1 }}>
												<DropdownInput state={job_type} set_state={set_job_type} dropdown_options={job_types} create_url="/dashboard/settings?tab=inputs" title="Job Type" />
											</Box>
										</Box>
										{/* Lead Sources input  */}
										<Box sx={{ my: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<Box sx={{ flex: 1 }}>
												<Typography variant='subtitle2' sx={{ mr: 1 }}>Lead Source</Typography>
											</Box>
											<Box sx={{ flex: 1 }}>
												<DropdownInput state={lead_source} set_state={set_lead_source} dropdown_options={lead_sources} create_url="/dashboard/settings?tab=inputs" title="Lead Source" />
											</Box>
										</Box>
									</Container>
								</Box>
								{/* Timezones and date area */}
								<Box mt={2}>
									<Container>
										{/* Date input  */}
										<Box sx={{ my: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<Box sx={{ flex: 1 }}>
												<Typography variant='subtitle2' sx={{ mr: 1 }}>Date</Typography>
											</Box>
											<Box sx={{ flex: 1 }}>
												<TextField  value={date} onChange={(e) => { set_date(e.target.value) }} size='small' id="date-input" placeholder='Expected deadline/finishing date' fullWidth variant="outlined" type="date" />
											</Box>
										</Box>

										{/* Timezone input  */}
										<Box sx={{ my: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<Box sx={{ flex: 1 }}>
												<Typography variant='subtitle2' sx={{ mr: 1 }}>Timezone</Typography>
											</Box>
											<Box sx={{ flex: 1 }}>
												<TextField required value={timezone} onChange={(e) => { set_timezone(e.target.value) }} size='small' id="timezone-input" placeholder='Your timezone' fullWidth variant="outlined" />
											</Box>
										</Box>
										{/* Location input  */}
										<Box sx={{ my: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<Box sx={{ flex: 1 }}>
												<Typography variant='subtitle2' sx={{ mr: 1 }}>Location</Typography>
											</Box>
											<Box sx={{ flex: 1 }}>
												<TextField  value={location} onChange={(e) => { set_location(e.target.value) }} size='small' id="location-input" placeholder='Location at which the project execution will take place' fullWidth variant="outlined" multiline rows={2} />
											</Box>
										</Box>

									</Container>
								</Box>

							</Box>
						</Box>
					</Box>}

				</Box > : '' : ''
	)
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, {})(ProjectDetails);