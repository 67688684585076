import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'
import Stack from '@mui/material/Stack';
import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import CustomModal from '../../../../components/CustomModal';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DrawerLinks from './DrawerLinks'


const MemberOptionButtons = ({ user }) => {
	var csrftoken = csrf_grabber('csrftoken');

	const [alert, set_alert] = useState(false);
	const [loading, set_loading] = useState(false);

	const navigate = useNavigate()

	const [drawer, set_drawer] = React.useState({
		member_details_drawer: false,
		qr_code_drawer: false,
		import_members_drawer: false,
		get_invite_link_drawer: false,
	});
	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		set_drawer({ ...drawer, [anchor]: open });
	};

	
	useEffect(() => {
		if (!(localStorage.getItem('access'))) {
			navigate('/login')
		}
	}, [])
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Stack spacing={2} direction="row">
				{/* <Button onClick={toggleDrawer("member_details_drawer", true)} variant="outlined" sx={{ color: 'primary.main' }}><AccountCircleOutlinedIcon sx={{ m: 1 }} />Custom Fields</Button> */}
				{/* <Button onClick={toggleDrawer("qr_code_drawer", true)} variant="outlined" sx={{ color: 'primary.main' }}><QrCode2OutlinedIcon sx={{ m: 1 }} /> QR code</Button> */}
				{/* <Button onClick={toggleDrawer("get_invite_link_drawer", true)} variant="outlined" sx={{ color: 'primary.main' }}><LinkOutlinedIcon sx={{ m: 1 }} /> Get Invite link</Button> */}
				{/* <Button onClick={toggleDrawer("import_members_drawer", true)} variant="outlined" sx={{ color: 'primary.main' }}><FileDownloadOutlinedIcon sx={{ m: 1 }} /> Import Members</Button> */}
			</Stack>


			<DrawerLinks state={drawer} set_state={set_drawer} toggleDrawer={toggleDrawer} />
		</Box>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, {})(MemberOptionButtons);