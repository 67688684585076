import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'
import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import SortIcon from '@mui/icons-material/Sort';
import LabelIcon from '@mui/icons-material/Label';
import Taskdrawer from './Taskdrawer';
import { styled, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 170,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
const Tasksbutton = ({ user, set_alert, set_loading }) => {
  const [deleteTask, setDeleteTask] = React.useState(false);
  const openSetDeleteTask = () => setDeleteTask(true);
  const closeSetDeleteTask = () => setDeleteTask(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [drawer, set_drawer] = React.useState({
    right: false,
    taskDetails: false,
    taskSetting: false,
    editAssignment: false,
    label: false
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    set_drawer({ ...drawer, [anchor]: open });
  };
  var csrftoken = csrf_grabber('csrftoken');
  const [something, set_something] = useState(false);

  const navigate = useNavigate()

  const get_something = async () => {
    set_loading(true)
    const config = {
      params: {},
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': csrftoken
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
      config
    );
    if (res.data.status == 1) {
      set_loading(false)
      set_something(res.data.response);
    } else {
      set_loading(false)
      set_alert(res.data.response)
    }
  }
  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])
  return (

    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Stack spacing={2} direction="row">
        <Button onClick={toggleDrawer("label", true)} variant="outlined" sx={{ color: 'primary.main' }}><LabelIcon sx={{ m: 1 }} /> Labels</Button>
        <Button onClick={toggleDrawer("right", true)} variant="outlined" sx={{ color: 'primary.main' }}><SortIcon /> Activity</Button>
        <div>
          <Button

            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="outlined"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Options
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={toggleDrawer("taskSetting", true)}>
              settings
            </MenuItem>
            <MenuItem onClick={toggleDrawer("editAssignment", true)} >
              Edit assigment
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem >
              Archive Quick Tasks
            </MenuItem>
            <MenuItem onClick={openSetDeleteTask}>
              Delete Quick Tasks
            </MenuItem>
          </StyledMenu>
        </div>
      </Stack>
      <Modal
        open={deleteTask}
        onClose={closeSetDeleteTask}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseSharpIcon onClick={closeSetDeleteTask} sx={{ cursor: 'pointer' }} />

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ margin: '5px' }}>Do you really want to delete this content ?</Typography>
            <Typography sx={{ margin: '5px' }}>Deleted content cannot be restored.</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Button sx={{ marginRight: '10px' }} variant='outlined'>Delete</Button>
            <Button variant='outlined'>Archive instead</Button>
          </Box>

        </Box>
      </Modal>
      <Taskdrawer state={drawer} set_state={set_drawer} toggleDrawer={toggleDrawer} />
    </Box>

  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Tasksbutton);