      import React, { useEffect, useState } from 'react'
      import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'
      import { Divider } from '@mui/material'      
      import axios from 'axios';
      import csrf_grabber from '../../../../components/csrf_grabber';
      import CustomModal from '../../../../components/CustomModal';
      import { connect } from 'react-redux';
      import { useNavigate } from 'react-router-dom';
      import CloseIcon from '@mui/icons-material/Close';
      
      const ImportUsers = ({user, toggleDrawer}) => {
      
      var csrftoken = csrf_grabber('csrftoken');
        
  const [something, set_something] = useState(false);
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
      
        const navigate = useNavigate()
      
          const get_something = async () => {
            set_loading(true)
            const config = {
                params: { },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRFToken': csrftoken
                },
            };
    
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
                config
            );
            if (res.data.status == 1) {
                set_loading(false)
                set_something(res.data.response);
            } else {
                set_loading(false)
                set_alert(res.data.response)
            }
        }
        useEffect(() => {
          if (!(localStorage.getItem('access'))) {
            navigate('/login')
          }
        }, [])
        return (
          <Box>
             <Box sx={{ display: 'flex', alignItems:'center' }}>
      <Box >
        <Box sx={{ cursor:"pointer"}}>
          <CloseIcon onClick={toggleDrawer("import_members_drawer", false)} sx={{ color: 'black', m:2, mb:1}} />
        </Box>
      </Box>
      <Box>
        <Typography variant="h2">Import users via excel or csv list</Typography>
      </Box>
    </Box>

    <Divider sx={{ height: 2,  }} />

            <Box>
            <Box  sx={{display:'flex', justifyContent:'center', flexDirection:'column',alignItems:'center'}}>
                <Box sx={{my:'30px'}} >
                <Typography>First Steps</Typography>
                </Box>
            <Box sx={{display:'flex',width:'50%', justifyContent:'space-around', marginTop:'20px'}}  >
                <Typography>One</Typography>
                <Typography>Two</Typography>
                <Typography>three</Typography>
            </Box>
            </Box>
          </Box>
            </Box>
          
        )
      }
      
      const mapStateToProps = (state) => ({
        user: state.auth.user,
      });
      
      export default connect(mapStateToProps, {})(ImportUsers);