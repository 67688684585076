import React, { useEffect, useRef, useState } from "react";
import { Alert, Box, Typography } from "@mui/material";

import { axios_get_call } from "../../../components/AxiosCall";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Container from "../../../components/Container";
import { Chart, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";

Chart.register(ChartDataLabels);
Chart.register(zoomPlugin);
Chart.register(...registerables);

const RecentActivityGraph = ({ user }) => {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null); // State to hold the chart instance

  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  const [activity_level, set_activity_level] = useState(false);

  const navigate = useNavigate();

  const get_activity_level = async () => {
    let url = "/saas/analytics/get-activity-level/";
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      set_activity_level(res.data.response);
      console.log("activity_level res.data.response");
      console.log(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  useEffect(() => {
    get_activity_level();
  }, []);

  useEffect(() => {}, [chartRef]);

  useEffect(() => {
    // Function to update chart dimensions and redraw
    const updateChart = () => {
      if (chart) {
        console.log("resizing");
        console.log(chart);
        chart.resize();
        chart.update();
      } else {
        if (chartRef.current) {
          setChart(chartRef.current);
        }
      }
    };

    // Add resize event listener
    window.addEventListener("resize", updateChart);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateChart);
    };
  }, [chart]);

  useEffect(() => {
    if (!localStorage.getItem("access")) {
      navigate("/login");
    }
  }, []);

  return user && activity_level ? (
    <Box>
      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity={alert.toLowerCase().includes("[error]") ? "error" : "info"}
          sx={{
            p: 2,
            mb: 2,
            width: 1,
            borderRadius: 2,
            zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ""
      )}
      <Box>
        <Container>
          {/* Line chart  */}
          <Box style={{ width: "100%" }}>
            <Line
              ref={chartRef}
              data={{
                labels: activity_level.labels,
                datasets: activity_level.datasets,
              }}
              plugins={[
                ChartDataLabels,
                {
                  afterDraw: (chart) => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      ctx.moveTo(x, yAxis.top / 2);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 0.2;
                      ctx.strokeStyle = "#ff0000";
                      ctx.stroke();
                      ctx.restore();
                    }
                  },
                },
              ]}
              options={{
                interaction: {
                  intersect: false,
                  mode: "index",
                },

                onClick: (click) => {
                  const points =
                    window.aggregate_chart.getElementsAtEventForMode(
                      click,
                      "nearest",
                      { intersect: true },
                      true
                    );
                  if (points.length) {
                    const clicked_index = points[0]["index"];
                    window.apply_hyperlinks_to_the_initiative_mos_points(
                      clicked_index
                    );
                  }
                },

                responsive: true,
                maintainAspectRatio: true,
                scales: {
                  x: {
                    suggestedMin: 0,
                    ticks: {},
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    suggestedMin: 0,
                    suggestedMax: 5,
                    beginAtZero: true,
                  },
                },
                hover: {
                  mode: "nearest",
                  intersect: true,
                },
                plugins: {
                  zoom: {
                    pan: {
                      enabled: true,
                      mode: "xy",
                      modifierKey: "shift",
                      threshold: 1,
                    },
                    limits: {
                      y: {
                        min: 0,
                      },
                    },
                    zoom: {
                      // enabled: true,
                      drag: {
                        enabled: true,
                        modifierKey: "ctrl",
                      },
                      wheel: {
                        enabled: true,
                        modifierKey: "shift",
                      },
                      pinch: {
                        enabled: true,
                      },
                      mode: "x",
                      onZoomComplete: function ({ chart }) {
                        chart.update("none");
                      },
                    },
                  },
                },
              }}
            />
          </Box>
        </Container>
      </Box>
    </Box>
  ) : (
    ""
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(RecentActivityGraph);
