import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Checkbox, CircularProgress, FormControl, Menu, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { Divider } from '@mui/material'
import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import FormCreator from '../../../../components/Dynamic Form Builder/FormCreator';
import RenderFields from '../../../../components/Dynamic Form Builder/RenderFields';

const MemberDetails = ({ user, toggleDrawer }) => {
	const [alert, set_alert] = useState(false);
	const [loading, set_loading] = useState(false);
	var csrftoken = csrf_grabber('csrftoken');
	const navigate = useNavigate()

	const [fields, set_fields] = useState([

	]);

	const [add_or_edit_new_field, set_add_or_edit_new_field] = React.useState(false);

	const [field_name, set_field_name] = React.useState("");
	const [is_field_required, set_is_field_required] = React.useState(false);
	const [field_data_type, set_field_data_type] = React.useState("Text");


	const get_extra_member_fields = async () => {
		set_loading(true)
		const config = {
			params: {},
			headers: {
				Authorization: `Bearer ${localStorage.getItem('access')}`,
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'X-CSRFToken': csrftoken
			},
		};

		const res = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/saas/user-management-system/get-extra-member-fields/`,
			config
		);
		if (res.data.status == 1) {
			set_loading(false)
			set_fields(res.data.response);
		} else {
			set_loading(false)
			set_alert(res.data.response)
		}
	}

	const save_extra_member_fields = async () => {
		set_loading(true)
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('access')}`,
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'X-CSRFToken': csrftoken
			},
		};
		let body = JSON.stringify({
			fields
		})


		const res = await axios.post(
			`${process.env.REACT_APP_BACKEND_URL}/saas/user-management-system/save-extra-member-fields/`,
			body,
			config
		);
		set_loading(false)
		set_alert(res.data.response)
	}



	useEffect(() => {
		get_extra_member_fields()
	}, [])


	useEffect(() => {
		if (!(localStorage.getItem('access'))) {
			navigate('/login')
		}
	}, [])


	return (
		<Box>




			<Box sx={{ width: 1, position: "fixed", display: 'flex', alignItems: 'center', bgcolor: "white", boxShadow: "0px 0px 13px -2px rgba(0,0,0,0.1)" }}>
				<Box >
					<Box sx={{ cursor: "pointer" }}>
						<CloseIcon onClick={toggleDrawer("member_details_drawer", false)} sx={{ color: 'black', m: 2, mb: 1 }} />
					</Box>
				</Box>
				<Box>
					<Typography variant="h2">Custom Fields</Typography>
				</Box>
				<Box sx={{ flex: 1, textAlign: "right", mr: 2 }}>
					<Button sx={{ width: '100px' }} onClick={() => { save_extra_member_fields() }} variant='contained'>Save</Button>
				</Box>
			</Box>
			<Divider sx={{ height: 2, }} />
			<Box sx={{ mt: '70px' }}>
				{alert ? (
					<Alert
						onClose={() => {
							set_alert(false);
						}}
						severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}

						sx={{
							p: 2,
							mb: 2,
							width: "99.5%",
							borderRadius: 2,
							zIndex: 9923213,
						}}
					>
						{alert}
					</Alert>
				) : (
					''
				)}

				<Box sx={{
					display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", my: 4,
				}}>
					<Box sx={{ width: { xs: 1, sm: "90%", md: "70%", lg: "60%" } }}>
						<Box sx={{ width: 1, flex: 1 }}>
							<Typography align="left" variant="h3" fontWeight="bold">Fields every member has to enter</Typography>
							<Typography align="left" variant="subtitle2">
								All the fields you create here will be asked from the member to fill.
							</Typography>
						</Box>

						<Box sx={{ width: 1, flex: 1, display: "flex" }}>
							<Box sx={{ flex: 1 }}>
								<Button onClick={() => { set_add_or_edit_new_field(true); set_alert(false) }} sx={{ float: 'right' }} variant="outlined">Add fields</Button>
							</Box>
						</Box>
						<FormCreator fields={fields} set_fields={set_fields} add_or_edit_new_field={add_or_edit_new_field} set_add_or_edit_new_field={set_add_or_edit_new_field}
							field_name={field_name}
							set_field_name={set_field_name}
							is_field_required={is_field_required}
							set_is_field_required={set_is_field_required}
							field_data_type={field_data_type}
							set_field_data_type={set_field_data_type}
						/>
						<RenderFields
							fields={fields} set_fields={set_fields} set_field_name={set_field_name} set_field_data_type={set_field_data_type} set_is_field_required={set_is_field_required} set_add_or_edit_new_field={set_add_or_edit_new_field}
						/>

					</Box>
				</Box>
			</Box>
		</Box>


	)
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, {})(MemberDetails);
