import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'

import csrf_grabber from '../../../components/csrf_grabber';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '../../../components/Container';
import LoadingScreen from '../../../components/LoadingScreen';

// Icons 
import FormsIcon from '@mui/icons-material/DocumentScannerOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CustomModal from '../../../components/CustomModal';
import { axios_delete_call, axios_get_call } from '../../../components/AxiosCall';

const Forms = ({ user }) => {

  var csrftoken = csrf_grabber('csrftoken');
  const [user_forms, set_user_forms] = useState(false);
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  const [form_id_to_delete, set_form_id_to_delete] = useState(false);

  const navigate = useNavigate()
  const location = useLocation()

  const get_user_forms = async () => {
    let url = '/saas/forms/get-user-forms/';
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false)
      set_user_forms(res.data.response);

    } else {
      set_loading(false)
      set_alert(res.data.response)
    }
  }


  async function delete_user_form() {
    let url = '/saas/forms/delete-user-form/';
    let res = await axios_delete_call(url, { form_id: user_forms[form_id_to_delete]['id'] }, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      set_form_id_to_delete(false)
      get_user_forms()
      set_alert("Form deleted")

    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  }
  useEffect(() => {
    get_user_forms()
  }, [])

  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])

  return (
    <Box >

      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
          sx={{
            p: 2,
            width: 1,
            borderRadius: 2,
            zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ''
      )}
      <LoadingScreen loading={loading} />

      {user_forms && (typeof form_id_to_delete == 'number') ?
        <CustomModal set_preview={set_form_id_to_delete}>
          <Box
            sx={{
              width: { xl: "35%", lg: "50%", md: "60%", sm: "80%", xs: "90%" },
              h: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "white",
              cursor: "auto",
              borderRadius: 3,
              boxShadow: 24,
              p: { xl: 4, lg: 4, md: 4, sm: 4, xs: 2 },
            }}
          >
            {alert ? (
              <Alert
                onClose={() => {
                  set_alert(false);
                }}
                severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                sx={{
                  p: 2,
                  width: '100%',
                  zIndex: 9992000,

                }}
              >
                {alert}
              </Alert>
            ) : (
              ""
            )}

            <style
              dangerouslySetInnerHTML={{
                __html: "\n#menu-{\nz-index:9992000}\n",
              }}
            />
            <Typography variant="h2" my={2}>
              Select Clients
            </Typography>
            <Box sx={{ width: 1, my: 1 }}>
              Are you sure you want to delete the form "{user_forms[form_id_to_delete]['form_title']}"
            </Box>
            <Box sx={{ display: "flex", width: 1, my: 2 }}>
              <Button fullWidth onClick={() => set_form_id_to_delete(false)} variant='gray_button'>Cancel</Button>
              <Button fullWidth onClick={() => {
                delete_user_form()
              }} variant='contained'>{loading ? <CircularProgress color="secondary" /> : "Yes, delete"}</Button>
            </Box>

          </Box>
        </CustomModal>
        : ""}

      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormsIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
            <Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
              Forms
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button variant='contained' sx={{ width: '100px' }} onClick={() => { navigate("/dashboard/forms/id=add") }}>Create</Button>
          </Box>
        </Box>
      </Container>

      <Box sx={{ my: 4 }}>
        <Container>

          {user_forms && user_forms.length > 0 ? user_forms.map((form, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bgcolor: "white", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)", my: 3,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#eaeaea",

                  }
                }}>
                <Box sx={{
                  flex: 1,
                  padding: '20px',

                }}
                  onClick={() => { navigate(`/dashboard/forms/id=${form.id}`) }}
                >
                  <Typography variant='subtitle2'>{form.form_title}</Typography>
                </Box>
                <Box sx={{ mr: 2 }}>
                  <DeleteIcon
                    onClick={() => {
                      set_form_id_to_delete(index)
                    }}
                    sx={{
                      color: "red",
                      transition: "0.1s all",
                      "&:hover": {
                        bgcolor: "pink",
                        p: 1,
                        fontSize: '250%',
                        borderRadius: 12,
                        cursor: "pointer"
                      }
                    }} />
                </Box>
              </Box>
            )
          }) :
            <Typography variant='subtitle2'>No forms found!</Typography>
          }
        </Container>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Forms);