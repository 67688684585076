import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

function Files({ files, set_files, editing_mode_disabled }) {
    const download_the_file = (file_url) => {
        let url = process.env.REACT_APP_BACKEND_URL.split("/api")[0] + file_url
        window.open(url, "_blank")
    }
    const delete_the_file = (index) => {
        let new_files_state = JSON.parse(JSON.stringify(files))
        new_files_state.splice(index, 1);
        set_files(new_files_state)
    }

    return (
        files &&
        (
            <Box>
                <Box >
                    {files.map((file, index) => {
                        return (
                            <Box sx={{ position: "relative" }} id={file.id} key={file.id}>
                                {!editing_mode_disabled && (
                                    <Box sx={{ transition: "0.2s all", position: "absolute", width: '22px', height: '22px', "&:hover": { width: '26px', height: '26px', }, display: "flex", justifyContent: "center", alignItems: "center", top: 2, left: 2, borderRadius: 15, bgcolor: "red", cursor: "pointer" }} onClick={() => { delete_the_file(index) }}>
                                        <Typography sx={{ fontSize: 12, color: "white", fontWeight: "bold", }}>X</Typography>
                                    </Box>
                                )}
                                <Box sx={{ cursor: "pointer", "&:hover": { bgcolor: "#f2f2f2" }, flex: 1, my: 2, overflow: "hidden", p: 2, borderRadius: 1, bgcolor: "#F9F9F9" }} onClick={() => { download_the_file(file.url) }}>
                                    <Typography variant='subtitle2'>{file.name}</Typography>
                                </Box>

                            </Box>
                        )
                    })}
                </Box>
            </Box>
        )
    )
}

export default Files