import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Typography ,Paper } from '@mui/material'
import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow } from '@mui/material';      
import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import CustomModal from '../../../../components/CustomModal';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';    
import Divider from '@mui/material/Divider';
import IosShareIcon from "@mui/icons-material/IosShare";
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Taskdrawer from './Taskdrawer';
const tableData = [
  {
      id: 1,
      generalTasks:['muhammad' ,<CommentRoundedIcon/>],
      // status: <Button variant='contained'  > open</Button>,
      subTasks:'',
      startDate:' 1/2/23',
      dueDate: '1/2/23',
      assignedTo: '3/5/2023',
  }
  
]      


const TaskArchived = ({user, set_alert, set_loading}) => {
   
const [select, setSelect] = React.useState('');

const handleChange = (event) => {
  setSelect(event.target.value);
};
const [drawer, set_drawer] = React.useState({
  right: false,
  taskDetails:false
});
const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
  }
  set_drawer({ ...drawer, [anchor]: open });
};   
var csrftoken = csrf_grabber('csrftoken');
  const [something, set_something] = useState(false);

  const navigate = useNavigate()

    const get_something = async () => {
      set_loading(true)
      const config = {
          params: { },
          headers: {
              Authorization: `Bearer ${localStorage.getItem('access')}`,
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'X-CSRFToken': csrftoken
          },
      };

      const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
          config
      );
      if (res.data.status == 1) {
          set_loading(false)
          set_something(res.data.response);
      } else {
          set_loading(false)
          set_alert(res.data.response)
      }
  }
  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])
  return (
    <Box>
      
      <TableContainer >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'10px' }} >

                  <Box sx={{  margin: '15px'}} >

  <Button variant="outlined" >
  <FilterListRoundedIcon/>
  </Button>
              
  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={select}
          onChange={handleChange}
          label="Age"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Title</MenuItem>
          <MenuItem value={20}>Assigned to</MenuItem>
          <MenuItem value={30}>Label</MenuItem>
        </Select>
      </FormControl>
                  </Box>
      <Box>
<TextField id="standard-basic" label="search" variant="standard" />
<Button
            variant="contained"
            
            sx={{ mx: "10px" }}
          >
            <IosShareIcon /> Export
          </Button>
        
      </Box>
          </Box>
  
  <Divider sx={{ height: 2}} />
      <Box sx={{marginTop:'30px', marginBottom:'30px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
        <Box>
          The view contains 
          <Button sx={{marginRight:'10px', marginLeft:'10px'}} variant='outlined' >1 task in total</Button>
          <Button sx={{marginRight:'10px'}} variant='contained' >1 open task</Button>
          <Button variant='outlined' >0 done tasks</Button>
        </Box>
          <Button sx={{marginRight:'10px'}} variant='contained' >Overdue Tasks</Button>
                              
      </Box>
      
      </TableContainer>

<Taskdrawer state={drawer} set_state={set_drawer} toggleDrawer={toggleDrawer}  />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(TaskArchived);