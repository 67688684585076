import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import CustomModal from '../../../../components/CustomModal';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';


const Qrcode = ({ user, toggleDrawer }) => {

	var csrftoken = csrf_grabber('csrftoken');
	
  const [something, set_something] = useState(false);
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

	const navigate = useNavigate()

	const get_something = async () => {
		set_loading(true)
		const config = {
			params: {},
			headers: {
				Authorization: `Bearer ${localStorage.getItem('access')}`,
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'X-CSRFToken': csrftoken
			},
		};

		const res = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
			config
		);
		if (res.data.status == 1) {
			set_loading(false)
			set_something(res.data.response);
		} else {
			set_loading(false)
			set_alert(res.data.response)
		}
	}
	useEffect(() => {
		if (!(localStorage.getItem('access'))) {
			navigate('/login')
		}
	}, [])
	return (
		<>
			<Box sx={{ display: 'flex', alignItems:'center' }}>
				<Box >
					<Box sx={{ cursor:"pointer"}}>
						<CloseIcon onClick={toggleDrawer("qr_code_drawer", false)} sx={{ color: 'black', m:2, mb:1}} />
					</Box>
				</Box>
				<Box>
					<Typography variant="h2">QR code</Typography>
				</Box>
			</Box>

			<Divider sx={{ height: 2,  }} />
			<Box sx={{ my: '50px' }}>
				<Typography sx={{ textAlign: 'center' }}>
					Print your QR code for quick scan & download of the app! </Typography>
				<Typography sx={{ mx: '48px' }}>
					Download your QR code and share it anyway you'd like! You can share it over email, messages and even print it on a paper. Once scanned by your employee, they'll be quickly prompted to download app.
				</Typography>
			</Box>
			<Box
				sx={{
					width: '90%',
					height: '80vh',
					backgroundColor: 'whitesmoke',
					margin: 'auto',
					borderRadius: 3,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Paper sx={{ width: '55%', height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 4, }}>
					QR code
				</Paper>
			</Box>
		</>

	)
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, {})(Qrcode);