import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/auth";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  LinearProgress,
  Typography,
  InputBase,
  Menu,
  Badge,
  MenuItem,
  Switch,
  Divider,
  Button,
  Tooltip,
  Avatar,
} from "@mui/material";

import PropTypes from "prop-types";

import site_logo from "../static/media/site logo.png";

import SmartToyIcon from "@mui/icons-material/SmartToy";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";

const Navbar = ({ logout, state, user, setDarkMode, darkMode }) => {
  // const store = useStore();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [is_login_page, set_is_login_page] = useState(false);
  const [is_settings_page, set_is_settings_page] = useState(false);

  const location = useLocation();

  const logout_user = () => {
    logout();
    setRedirect(true);
  };

  useEffect(() => {
    if (window.location.href.includes("/login")) {
      set_is_login_page(true);
    } else {
      set_is_login_page(false);
    }

    if (window.location.href.includes("/dashboard")) {
      set_is_settings_page(true);
    } else {
      set_is_settings_page(false);
    }
  }, [location]);

  const guestLinks = () => (
    <Fragment>
      <Link to={user ? `/` : ""} style={{ textDecoration: "none" }}>
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={handleMenuClose}
        >
          Home
        </MenuItem>
      </Link>
      <Divider />
      <Link to="/login" style={{ textDecoration: "none" }}>
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={handleMenuClose}
        >
          Login
        </MenuItem>
      </Link>
      <Link to="/signup" style={{ textDecoration: "none" }}>
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={handleMenuClose}
        >
          Register
        </MenuItem>
      </Link>
    </Fragment>
  );

  const authLinks = () => (
    <Fragment>
      <Link
        to={"/dashboard"}
        style={{ textDecoration: "none" }}
      >
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={handleMenuClose}
        >
          View my public page
        </MenuItem>
      </Link>
      <Link to="/dashboard" style={{ textDecoration: "none" }}>
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={handleMenuClose}
        >
          Dashboard
        </MenuItem>
      </Link>

      <Divider />
      {user ? user.is_superuser ?
        <Link
          onClick={() => window.open(`${process.env.REACT_APP_BACKEND_URL.split("/api")[0]}/theoffice/`, '_blank')}
          style={{ textDecoration: "none" }}
        >
          <MenuItem

            sx={{ color: "black", fontWeight: 500 }}
          >
            Admin panel
          </MenuItem>
        </Link> : "" : ""}
      <Divider />
      <Link
        to={"/dashboard"}
        style={{ textDecoration: "none" }}
      >
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={logout_user}
        >
          Logout
        </MenuItem>
      </Link>
    </Fragment>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ mt: "48px", color: "#F9F9F9" }}
    >
      {state.auth.isAuthenticated ? authLinks() : guestLinks()}

      <Divider />
      <Box
        sx={{
          p: 2,
        }}
      >
        {state.auth.user ? (
          <Typography sx={{ color: "lightgrey" }}>
            {" "}
            {state.auth.user.email}{" "}
          </Typography>
        ) : (
          ""
        )}
      </Box>
    </Menu>
  );

  const label = { inputProps: { "aria-label": "Light Mode" } };
  return (
    <Box sx={{ flexGrow: 1, py:2, bgcolor: is_settings_page ? "#F9F9F9" : "white" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "left",
          height: "64px",
          zIndex: 1,
        }}
      >
        {/* Logo part  */}
        <Link
          to={"/"}
          style={{ textDecoration: "none" }}
        >
          <Box
            sx={{
              width: { xs: "200px", sm: "300px" },
              margin: 1,
            }}
          >
            <img
              src={site_logo}
              width="100%"
              style={{ marginRight: "7px" }}
              loading="lazy"
            />
          </Box>
        </Link>

        <Box sx={{ flex: 3, display: "flex", justifyContent: "right", }}>
          {/* Links Part  */}
          {/* personal-email-finder */}
          {/* <Link style={{ color: "gray" }} to="/personal-email-finder"> */}
          {/* <Link style={{ color: "gray" }} to="/saas/csat-generator">
                <Box sx={{ display: "flex", mr: 2, alignItems: "center" }}>
                  <span
                    style={{
                      borderRadius: "50px",
                      padding: "3px",
                      paddingTop: "5px",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      marginRight: "4px",
                      border: "1px solid lightgray",
                    }}
                  >
                    <SmartToyIcon fontSize="small" />
                  </span>
                  <Typography fontSize="small">CSAT Generator</Typography>
                </Box>
              </Link> */}
          <Box
            sx={{
              display: {
                md: state.auth.isAuthenticated == false ? "none" : "flex",
                xs: "none",
              },
            }}
          >
            <Button
              onClick={() => navigate("/dashboard")}
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                borderRadius: 21, px: 3
              }}
            >
              Dashboard
            </Button>
          </Box>
          <Box
            sx={{
              display: {
                md: state.auth.isAuthenticated == false ? "flex" : "none",
              },
            }}
          >
            <Button
              onClick={() => navigate("/login")}
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                borderRadius: 21, px: 3
              }}
            >
              Log in
            </Button>
          </Box>
          <Box
            sx={{
              display: {
                md: state.auth.isAuthenticated == false ? "flex" : "none",
              },
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate("/signup")}
            >
              Sign up
            </Button>
          </Box>
        </Box>

      
       
      </Toolbar>


    </Box>
  );
};
// [End] main function to render navbar
const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(Navbar);
