import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Divider, TextField, Typography } from '@mui/material'
import EastSharpIcon from '@mui/icons-material/EastSharp';
import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import CustomModal from '../../../../components/CustomModal';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import Checkbox from '@mui/material/Checkbox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import CloseIcon from '@mui/icons-material/Close';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const InviteLink = ({ user, toggleDrawer }) => {

  var csrftoken = csrf_grabber('csrftoken');

  const [something, set_something] = useState(false);
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

  const navigate = useNavigate()

  const get_something = async () => {
    set_loading(true)
    const config = {
      params: {},
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': csrftoken
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
      config
    );
    if (res.data.status == 1) {
      set_loading(false)
      set_something(res.data.response);
    } else {
      set_loading(false)
      set_alert(res.data.response)
    }
  }
  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])
  return (
    <>
      <Box>

      
      <Box sx={{ display: 'flex', alignItems:'center' }}>
      <Box >
        <Box sx={{ cursor:"pointer"}}>
          <CloseIcon onClick={toggleDrawer("get_invite_link_drawer", false)} sx={{ color: 'black', m:2, mb:1}} />
        </Box>
      </Box>
      <Box>
        <Typography variant="h2">Share invite link</Typography>
      </Box>
    </Box>

    <Divider sx={{ height: 2,  }} />
        <Box sx={{ height: '45vh', backgroundColor: 'whitesmoke' }} >
          <Typography sx={{ paddingLeft: '20px', paddingTop: '30px' }}>
            Share this link with people so they can request to join the app. All requests will be pending an admin's approval
          </Typography>
          <Box
            sx={{
              width: 470,
              maxWidth: '100%',
              marginTop: '20px',
              marginLeft: '20px',
            }}
          >
            <TextField fullWidth label="Copy Link" id="fullWidth" InputProps={{
              endAdornment: <LinkOutlinedIcon />,
            }} />
          </Box>
          <Box sx={{ marginTop: '20px', marginLeft: '20px', }}>
            <Button variant='outlined' sx={{ marginRight: '10px' }} ><ContentCopyIcon /> Copy</Button>
            <Button variant='outlined'  ><SendToMobileIcon />  Send to my mobile</Button>
            <Button variant='outlined' sx={{ marginLeft: '10px', width: '7vw' }} >Preview</Button>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginTop: '20px', }} >
            <Checkbox {...label} />
            <Typography>
              Notify me when a user is requesting to join the app
            </Typography>
          </Box>

        </Box>

        <Box sx={{ marginTop: '20px', marginLeft: '20px', marginRight: '20px', }}>
          <Typography sx={{ mx: '20px' }}>
            Select the information you wish people to provide when requesting to join the app
          </Typography>

          {/* In this box we have checkbox along with the text and a button */}


          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
            <Box sx={{ display: 'flex' }}>
              <Checkbox disabled checked />
              <Typography sx={{ display: 'flex', alignItems: 'center', }}>First name </Typography>
            </Box>
            <Button sx={{ height: '6vh', mx: '30px' }} variant='contained'>Set as required</Button>
          </Box>


          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
            <Box sx={{ display: 'flex' }}>
              <Checkbox disabled checked />
              <Typography sx={{ display: 'flex', alignItems: 'center', }}>Last name </Typography>
            </Box>
            <Button sx={{ height: '6vh', mx: '30px' }} variant='contained'>Set as required</Button>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
            <Box sx={{ display: 'flex' }}>
              <Checkbox disabled checked />
              <Typography sx={{ display: 'flex', alignItems: 'center', }}>Email</Typography>
            </Box>
            <Button sx={{ height: '6vh', mx: '30px' }} variant='contained'>Set as required</Button>
          </Box>

        </Box>
      </Box>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(InviteLink);