import React, { useEffect, useState } from 'react'
import {  Box  } from '@mui/material'

import { connect } from 'react-redux';
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import TasksCreatedByMe from './TasksCreatedByMe';
import MyTasks from './MyTasks';
import AllTask from './AllTask';
import TaskArchived from './TaskArchived';

const MyTabs = () => {
	const [value, setValue] = useState('0');
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	
	
	
	return (

		<Box>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>
				<TabList onChange={handleChange} sx={{
					width: "90.65vw",
					margin: 'auto',
					marginTop: 4,
					bgcolor: "white",
					boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)",
				}}>
					<Tab label='Tasks Created By Me' value='0' ></Tab>
					<Tab label='My Tasks' value='1' ></Tab>
					<Tab label='All Tasks' value='2' ></Tab>
					<Tab label='Archived' value='3' ></Tab>
				</TabList>
				<TabPanel value='0'  ><TasksCreatedByMe/> </TabPanel>
				<TabPanel value='1'><MyTasks/> </TabPanel>
				<TabPanel value='2'> <AllTask/>  </TabPanel>
				<TabPanel value='3'><TaskArchived/> </TabPanel>
			</TabContext>

		</Box>
	)
}

export default MyTabs