import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
  Paper,
} from "@mui/material";
import axios from "axios";
import csrf_grabber from "../../../../components/csrf_grabber";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MembersIcon from "@mui/icons-material/Group";
import MemberOptionButtons from "./MemberOptionButtons";
import MemberTabs from "./MemberTabs";
import Container from "../../../../components/Container";
import LoadingScreen from "../../../../components/LoadingScreen";
const Members = ({ user }) => {
  var csrftoken = csrf_grabber("csrftoken");

  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("access")) {
      navigate("/login");
    }
  }, []);

  return (
    <Box>
      <LoadingScreen loading={loading} />
      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity="error"
          sx={{
            p: 2,
            width: 1,
            borderRadius: 2,
            zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ""
      )}
      <Box>
        <Container>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <MembersIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
              <Typography
                align="left"
                variant="h1"
                sx={{ fontWeight: "light" }}
              >
                Members
              </Typography>
            </Box>
            <MemberOptionButtons />
          </Box>
        </Container>
      </Box>
      <Box>
        <MemberTabs />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Members);
