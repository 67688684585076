import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Typography } from '@mui/material'

import { axios_get_call } from '../../../../components/AxiosCall';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Container from '../../../../components/Container';
import LoadingScreen from '../../../../components/LoadingScreen';
import ProjectsIcon from '@mui/icons-material/WorkHistory';

import moment from 'moment'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const Projects = ({ user }) => {

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [projects, set_projects] = useState([]);

    const navigate = useNavigate()

    const get_projects = async () => {
        let url = '/saas/job-scheduling/get-projects/';
        let res = await axios_get_call(url, {}, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_projects(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    useEffect(() => {
        get_projects()
    }, [])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])

    return (
        user ?
            user.is_company_owner || user.is_also_admin ?
                <Box>
                    <LoadingScreen loading={loading} />
                    {alert ? (
                        <Alert
                            onClose={() => {
                                set_alert(false);
                            }}
                            severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                            sx={{
                                p: 2,
                                mb: 2,
                                width: 1,
                                borderRadius:2,
zIndex: 9923213,
                            }}
                        >
                            {alert}
                        </Alert>
                    ) : (
                        ''
                    )}
                    <Container>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ProjectsIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
                                <Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
                                    Projects
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button variant='contained' sx={{ width: '100px' }} onClick={() => { navigate("/dashboard/projects/details?") }}>Create</Button>
                            </Box></Box>
                    </Container>
                    <Box sx={{ my: 4 }}>
                        <Container >

                            <Box sx={{ overflowX: "scroll" }}>
                                <Box sx={{
                                    display: "flex", justifyContent: "space-between", alignItems: "center",
                                    p: 2,
                                    bgcolor: "secondary.main",
                                    color: "White",
                                    borderRadius: 1

                                }}>
                                    <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>Created By</Typography></Box>
                                    <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>Project</Typography></Box>
                                    <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>Deadline</Typography></Box>
                                    <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>Stage</Typography></Box>
                                    <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>Type</Typography></Box>
                                    <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>Members</Typography></Box>
                                </Box>
                                {projects.length > 0 ? projects.map((project, index) => (
                                    <Box
                                        key={project.id}
                                        sx={{
                                            display: "flex", justifyContent: "space-between", alignItems: "center",
                                            flex: 1,
                                            p: 2,
                                            my: 2,
                                            bgcolor: index % 2 ? "primary.thin" : "inherit",
                                            "&:hover": {
                                                bgcolor: "#F2F2F2",
                                                cursor: "pointer"
                                            }
                                        }}
                                        onClick={() => {
                                            navigate(`/dashboard/projects/details?id=${project.id}`)
                                        }}

                                    >
                                        <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>{project.created_by.name}</Typography></Box>
                                        <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography>{project.name}</Typography></Box>
                                        <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography >{moment(project.date).format('MMM DD, YYYY (ddd)')}</Typography></Box>
                                        <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography >{project.type ? project.type : "None"}</Typography></Box>
                                        <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography >{project.stage ? project.stage : "None"}</Typography></Box>
                                        <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}>

                                            {project.members.length > 0 || project.client ? (
                                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', overflowX:"scroll", px:2 }}>
                                                    {
                                                        project.members.map((member) => {
                                                            return (
                                                                member.id == user.id ? <Typography>You,&nbsp;</Typography> :
                                                                    <Typography sx={{ whiteSpace: "nowrap" }}>{member.name},&nbsp;</Typography>
                                                            )
                                                        })
                                                    }
                                                    <Typography sx={{ whiteSpace: "nowrap" }}> {project.client}</Typography>
                                                </Box>

                                            ) : project.created_by.id == user.id ? "You" : project.created_by.name}
                                        </Box>
                                    </Box>
                                )) :
                                <Typography sx={{p:2}}   variant="subtitle2">No projects found</Typography>}
                            </Box>
                        </Container>


                    </Box >

                </Box > : '' : ''
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(Projects);