import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, TextField, Typography } from '@mui/material'


import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';


import { axios_get_call, axios_post_call } from "../../../../components/AxiosCall";
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Icons 
import GroupsIcon from '@mui/icons-material/Groups';
import LoadingScreen from '../../../../components/LoadingScreen';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, team_members, theme) {
    return {
        fontWeight:
            team_members.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const AddTeam = ({ user }) => {
    const theme = useTheme();
    const search = useLocation().pathname;

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);

    const [team_id, set_team_id] = useState(false);

    const [name, set_name] = useState("");
    const [team_profile_picture, set_team_profile_picture] = useState("");
    const [team_added, set_team_added] = useState(false);

    const [team_members, set_team_members] = React.useState([]);
    const [members, set_members] = useState([{
        "id": "loading",
        "first_name": "-",
        "last_name": "-",
    }]);



    const handleChange = (event) => {
        let {
            target: { value },
        } = event;

        let dataList = typeof value === 'string' ? value.split(',') : value

        const nameCount = {};
        dataList.forEach((item) => {
            if (nameCount[item.id]) {
                nameCount[item.id]++;
            } else {
                nameCount[item.id] = 1;
            }
        });

        const filteredData = dataList.filter((item) => nameCount[item.id] === 1);
        set_team_members(filteredData);
        
    };

    const navigate = useNavigate()

    const create_team = async () => {
        let url = "/saas/team/teams/";
        const formData = new FormData();
        formData.append('name', name);
        formData.append('team_profile_picture', team_profile_picture);
        formData.append('team_id', team_id);
        formData.append('team_members', JSON.stringify(team_members));

        let res = await axios_post_call(url, formData, set_loading, set_alert, "multipart/form-data");

        if (res.data.status == 1) {
            set_loading(false);
            set_alert(res.data.response);
            set_team_added(true)

        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    const get_team = async () => {
        let url = "/saas/team/teams/";
        let res = await axios_get_call(url, { team_id }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            let response = res.data.response;
            set_name(response.team_name)
            set_team_profile_picture(response.team_profile_picture)
            console.log('response.team_members')
            console.log(response.team_members)
            set_team_members(response.team_members)
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    const get_members = async () => {
        set_loading(true);
        let url = "/saas/user-management-system/get-all-members/";
        let res = await axios_get_call(url, { 'member_type': "Team Members" }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_members(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    useEffect(() => {
        get_members()
    }, [])

    useEffect(() => {
        if (team_id) {
            get_team()
        }
    }, [team_id])

    useEffect(() => {
        let id = search.split("/")
        id = id[id.length - 1]
        if (!(id === "add")) {
            set_team_id(id)
        }
    }, [])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])

    return (
        user ? <Box>

<LoadingScreen loading={loading} />

            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box>
                    <GroupsIcon sx={{ color: "primary.main", fontSize: "35px", mr: 1 }} />
                </Box>
                <Box>
                    <Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
                        Add team
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ bgcolor: "white", mt: 1, borderRadius: 3, py: 4, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ width: { md: 1 / 2, lg: 1 / 3, sm: '90%', xs: 1 }, }} component="form" onSubmit={(e) => { e.preventDefault(); create_team() }}>
                    {/* Name input  */}
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" align="left">
                            Name
                        </Typography>
                        <TextField placeholder='Team name or objective' required fullWidth size="small" value={name} onChange={(e) => set_name(e.target.value)} />
                    </Box>

                    {/* Team Profile Picture input  */}
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" align="left">
                            Team Profile Picture
                        </Typography>
                        {team_profile_picture ?
                            <Button
                                fullWidth
                                variant="gray_button"
                                component="label"
                                color="secondary"
                                sx={{ borderRadius: 1 }}
                                onClick={(e) => {
                                    set_team_profile_picture("")
                                }}
                            >
                                Picture selected. Click to deselect
                            </Button>
                            :
                            <Button
                                fullWidth
                                variant="outlined"
                                component="label"
                                color="secondary"
                                sx={{ borderRadius: 1 }}
                            >
                                Upload Picture
                                <input
                                    type="file"
                                    hidden
                                    required
                                    onChange={(e) => {
                                        set_team_profile_picture(e.target.files[0])
                                    }}
                                />
                            </Button>}
                    </Box>

                    {/* Team members input  */}
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" align="left">
                            Select Members of this team
                        </Typography>
                        <FormControl fullWidth sx={{ m: 1, }}>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={team_members}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" />}
                                renderValue={(members) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {members.map((member) => (
                                            <Chip key={member.id} label={member.first_name + " " + member.last_name} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {members.map((member) => (
                                    <MenuItem
                                        key={member.id}
                                        value={member}
                                        style={getStyles(member.first_name + " " + member.last_name, team_members, theme)}
                                    >
                                        {member.first_name + " " + member.last_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Box>

                    {/* Extra details input  */}
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" align="left">
                            Extra details
                        </Typography>
                        <TextField multiline rows={4} placeholder='Feature needs to be built e.g Date of creation of this team, purpose of this team, goals, anything that feels right' fullWidth size="small" />
                    </Box>


                    <Box>
                        <Button fullWidth disabled={team_added} type="submit" variant='contained'>{loading ? <CircularProgress color="secondary" /> : team_added ? team_id ? "Updated" : "Created" : team_id ? "Update" : "Create"}</Button>
                        {team_added ? <Typography align='center' variant='subtitle2'>Click here to view all the <Link to="/dashboard/teams">teams</Link></Typography>
                            : ""}
                    </Box>
                    <Box mt={2}>
                        {alert ? (
                            <Alert
                                onClose={() => {
                                    set_alert(false);
                                }}
                                size="small"
                                severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                                sx={{
                                    p: 2,
                                    mb: 2,
                                    width: 1,
                                    borderRadius:2,
zIndex: 9923213,
                                }}
                            >
                                {alert}
                            </Alert>
                        ) : (
                            ''
                        )}
                    </Box>
                </Box>
            </Box>
        </Box> : ""
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(AddTeam);