import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Tooltip, Typography } from '@mui/material'

import { axios_get_call } from '../../../../components/AxiosCall';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Container from '../../../../components/Container';
import LoadingScreen from '../../../../components/LoadingScreen';
import ClientsIcon from '@mui/icons-material/Handshake';
import CRUDClients from './CRUDClients';


const Clients = ({ user }) => {

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [clients, set_clients] = useState([]);
    const [edit_or_update_clients, set_edit_or_update_clients] = useState(false);

    const navigate = useNavigate()

    const get_clients = async () => {
        let url = '/saas/clients/get-clients/';
        let res = await axios_get_call(url, { with_extra_fields: true }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_clients(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    useEffect(() => {
        get_clients()
    }, [])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])

    return (
        user ?
            user.is_company_owner || user.is_also_admin ?
                <Box>
                    <LoadingScreen loading={loading} />
                    <CRUDClients edit_or_update_clients={edit_or_update_clients} set_edit_or_update_clients={set_edit_or_update_clients} get_clients={get_clients} />
                    {alert ? (
                        <Alert
                            onClose={() => {
                                set_alert(false);
                            }}
                            severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                            sx={{
                                p: 2,
                                mb: 2,
                                width: 1,
                                borderRadius: 2,
                                zIndex: 9923213,
                            }}
                        >
                            {alert}
                        </Alert>
                    ) : (
                        ''
                    )}
                    <Container>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ClientsIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
                                <Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
                                    Clients
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button variant='contained' sx={{ width: '100px' }} onClick={() => { set_edit_or_update_clients(true) }}>Create</Button>
                            </Box>
                        </Box>
                    </Container>
                    <Box sx={{ my: 4 }}>
                        <Container >

                            <Box sx={{ overflowX: "scroll" }}>
                                <Box sx={{
                                    display: "flex", justifyContent: "space-between", alignItems: "center",
                                    p: 2,
                                    bgcolor: "secondary.main",
                                    color: "White",
                                    borderRadius: 1

                                }}>
                                    <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>Client</Typography></Box>
                                    <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>Email</Typography></Box>
                                    <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>Phone no</Typography></Box>
                                    <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography fontWeight={"bold"}>Address</Typography></Box>

                                </Box>
                                {clients && clients.map((client) => (
                                    <Box
                                        key={client.id}
                                        sx={{
                                            display: "flex", justifyContent: "space-between", alignItems: "center",
                                            flex: 1,
                                            p: 2,
                                            my: 2,
                                            "&:hover": {
                                                bgcolor: "#F2F2F2",
                                                cursor: "pointer"
                                            }
                                        }}
                                        onClick={() => {
                                            set_edit_or_update_clients(client.id)
                                        }}

                                    >
                                        <Box sx={{ flex: 1, display: "flex", alignItems: "center", width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}>
                                            <Box sx={{
                                                width: "50px",
                                                height: "50px",
                                                overflow: "hidden",
                                                borderRadius: 15,
                                                mr: 1,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                {client.profile_pic != "/media/0" && <img src={`${process.env.REACT_APP_BACKEND_URL.split("/api")[0]}${client.profile_pic}`} height={'100%'} />}
                                            </Box>
                                            <Typography>{client.name}</Typography>
                                        </Box>

                                        <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography>{client.email}</Typography></Box>
                                        <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px" }}><Typography>{client.phone_number}</Typography></Box>
                                        <Tooltip placeholder='top' title={client.address}>
                                            <Box sx={{ flex: 1, width: "fit-content", whiteSpace: "nowrap", minWidth: "150px", overflow: "hidden", textOverflow: 'ellipsis' }}><Typography>{client.address}</Typography></Box>
                                        </Tooltip>

                                    </Box>
                                ))}
                            </Box>
                        </Container>


                    </Box >

                </Box > : '' : ''
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(Clients);