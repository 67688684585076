import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Chip, CircularProgress, Divider, FormControl, LinearProgress, MenuItem, OutlinedInput, Select, SwipeableDrawer, TextField, Typography } from '@mui/material'

import { useNavigate } from 'react-router-dom';
import { axios_delete_call, axios_get_call, axios_post_call } from '../../../../components/AxiosCall';
import CustomModal from '../../../../components/CustomModal';

import CrossIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { useTheme } from '@emotion/react';
import generate_local_timezone_based_iso_date from '../../../../components/generate_local_timezone_based_iso_date';
import LoadingScreen from '../../../../components/LoadingScreen';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, selected_members, theme) {
    return {
        fontWeight:
            selected_members.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const JobCRUD = ({ set_jobs, job_id, set_job_id, job_assign_start_date, set_job_assign_start_date, the_member_id }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [job_assign_end_date, set_job_assign_end_date] = React.useState()

    const [state, setState] = React.useState({
        right: false,
    });

    const [confirm_delete_job_dialog, set_confirm_delete_job_dialog] = useState(false);
    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [timezone, set_timezone] = useState("")
    const [total_shift_time, set_total_shift_time] = useState("")
    const [members, set_members] = React.useState([]);
    const [job_stage, set_job_stage] = React.useState(-1);
    const [all_job_stages, set_all_job_stages] = React.useState([]);
    const [job_project, set_job_project] = React.useState(-1);
    const [all_job_projects, set_all_job_projects] = React.useState([]);
    const [selected_members, set_selected_members] = React.useState([]);
    const [job, set_job] = useState({
        start_date: job_assign_start_date && generate_local_timezone_based_iso_date(job_assign_start_date),
        end_date: job_assign_end_date && generate_local_timezone_based_iso_date(job_assign_end_date),
        start_datetime: "",
        end_datetime: "",
        total_shift_time: "",
        timezone: timezone,
        job_title: "",
        job_project: job_project,
        job_stage: job_stage,
        members: [],
        location: "",
        instructions: "",
        color: "",
    });




    const get_job = async () => {
        let url = '/saas/job-scheduling/get-job/';
        let res = await axios_get_call(url, { job_id }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_job(res.data.response);
            set_job_assign_start_date(new Date(res.data.response['start_date']))
            set_job_assign_end_date(new Date(res.data.response['end_date']))
            set_selected_members(res.data.response['members'])
            set_job_project(res.data.response['project']['id'])
            set_job_stage(res.data.response['job_stage'])

        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }
    const delete_job = async (e) => {
        let url = '/saas/job-scheduling/delete-job/';
        let res = await axios_delete_call(url, { "job_id": confirm_delete_job_dialog }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            const all_jobs = res.data.response.map(job => ({
                ...job,
                start_datetime: moment(job.start_datetime),
                end_datetime: moment(job.end_datetime),
                start_time: moment(new Date(new Date(new Date(job.start_datetime).getFullYear(), new Date(job.start_datetime).getMonth(), new Date(job.start_datetime).getDate()).setHours(1, 0, 0))),
                end_time: moment(new Date(new Date(new Date(job.end_datetime).getFullYear(), new Date(job.end_datetime).getMonth(), new Date(job.end_datetime).getDate()).setHours(20, 3, 0)))
            }));
            set_jobs(all_jobs)
            const myToggleDrawer = toggleDrawer('right', false);
            myToggleDrawer(e);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }
    const get_all_members = async () => {
        set_loading(true);
        let url = "/saas/user-management-system/get-all-members/";
        let res = await axios_get_call(url, { 'member_type': "Team Members" }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_members(res.data.response);
            if (typeof the_member_id == "number") {
                let member = res.data.response.find(item => item.id === the_member_id);
                set_selected_members([member])
            }
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }



    const get_all_job_projects = async () => {
        set_loading(true);
        let url = "/saas/job-scheduling/get-all-job-projects/";
        let res = await axios_get_call(url, {}, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_all_job_projects(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }
    const get_all_job_stages = async () => {
        set_loading(true);
        let url = "/saas/job-scheduling/get-all-job-stages/";
        let res = await axios_get_call(url, {}, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_all_job_stages(res.data.response);
            set_job_stage(res.data.response[0].id);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }


    const create_or_update_job = async (e) => {
        e.preventDefault();
        let url = '/saas/job-scheduling/create-or-update-job/';
        let job_object = JSON.parse(JSON.stringify(job))
        job_object['job_id'] = job_id

        let res = await axios_post_call(url, job_object, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            const all_jobs = res.data.response.map(job => ({
                ...job,
                start_datetime: moment(job.start_datetime),
                end_datetime: moment(job.end_datetime),
                start_time: moment(new Date(new Date(new Date(job.start_datetime).getFullYear(), new Date(job.start_datetime).getMonth(), new Date(job.start_datetime).getDate()).setHours(1, 0, 0))),
                end_time: moment(new Date(new Date(new Date(job.end_datetime).getFullYear(), new Date(job.end_datetime).getMonth(), new Date(job.end_datetime).getDate()).setHours(20, 3, 0)))
            }));
            set_jobs(all_jobs)
            const myToggleDrawer = toggleDrawer('right', false);
            myToggleDrawer(e);

        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }


    const handleChange = (event) => {
        let {
            target: { value },
        } = event;

        let dataList = typeof value === 'string' ? value.split(',') : value

        const nameCount = {};
        dataList.forEach((item) => {
            if (nameCount[item.id]) {
                nameCount[item.id]++;
            } else {
                nameCount[item.id] = 1;
            }
        });

        const filteredData = dataList.filter((item) => nameCount[item.id] === 1);
        set_selected_members(filteredData);
    };

    const update_job_state = (key, value) => {
        let job_state_copy = JSON.parse(JSON.stringify(job))
        job_state_copy[key] = value
        set_job(job_state_copy)
    }



    const total_job_time = (start_time, end_time) => {
        if (start_time.includes(":") && end_time.includes(":")) {
            // Split the time strings into hours and minutes
            const [hours1, minutes1] = start_time.split(':').map(Number);
            const [hours2, minutes2] = end_time.split(':').map(Number);

            // Create Date objects for each time (assuming today's date)
            const date1 = new Date(0, 0, 0, hours1, minutes1);
            const date2 = new Date(0, 0, 0, hours2, minutes2);

            // Calculate the time difference in milliseconds
            const timeDifferenceMillis = date2 - date1;

            // Convert milliseconds to hours and minutes
            const hoursDifference = Math.floor(timeDifferenceMillis / 3600000); // 3600000 milliseconds in an hour
            const minutesDifference = Math.floor((timeDifferenceMillis % 3600000) / 60000); // 60000 milliseconds in a minute
            const formattedHours = String(hoursDifference).padStart(2, '0');
            const formattedMinutes = String(minutesDifference).padStart(2, '0');
            
            let total_shift_time = `Time: ${formattedHours}:${formattedMinutes}hr/day for ${((new Date(job['end_date']) - new Date(job['start_date'])) / (1000 * 60 * 60 * 24)) == 1 ? "Today" : `${(new Date(job['end_date']) - new Date(job['start_date'])) / (1000 * 60 * 60 * 24)} Days`}`
            update_job_state("total_shift_time", total_shift_time)
            set_total_shift_time(total_shift_time)
            return total_shift_time
        } else {
            return ""
        }

    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (open == false) {
            set_job_id(false)
            set_job_assign_start_date(false)
            set_job_assign_end_date(false)
            set_selected_members([])
            set_total_shift_time("")
            set_job({
                start_date: job_assign_start_date && generate_local_timezone_based_iso_date(job_assign_start_date),
                end_date: job_assign_end_date && generate_local_timezone_based_iso_date(job_assign_end_date),
                start_datetime: "",
                end_datetime: "",
                total_shift_time: "",
                timezone: timezone,
                job_title: "",
                job_project: job_project,
                job_stage: job_stage,
                members: [],
                location: "",
                instructions: "",
                color: "",
            });
        }
        setState({ ...state, [anchor]: open });
    };


    useEffect(() => {
        if (typeof job_id != "boolean") {
            get_job()
        }
    }, [job_id])

    useEffect(() => {
        get_all_members()
    }, [])

    useEffect(() => {
        if (typeof the_member_id == "number")
            get_all_members()
    }, [the_member_id])

    useEffect(() => {
        get_all_job_projects()
    }, [])

    useEffect(() => {
        get_all_job_stages()
    }, [])



    useEffect(() => {
        update_job_state("members", selected_members);
    }, [selected_members])

    useEffect(() => {
        update_job_state("job_project", job_project);
    }, [job_project])

    useEffect(() => {
        update_job_state("job_stage", job_stage);
    }, [job_stage])

    useEffect(() => {
        update_job_state("start_date", generate_local_timezone_based_iso_date(job_assign_start_date));
    }, [job_assign_start_date])

    useEffect(() => {
        update_job_state("end_date", generate_local_timezone_based_iso_date(job_assign_end_date));
    }, [job_assign_end_date])

    useEffect(() => {
        if (job['start_datetime'] && job['end_datetime'])
            total_job_time(job['start_datetime'], job['end_datetime'])
    }, [job['start_datetime'], job['end_datetime'], job['start_date'], job['end_date']])

    useEffect(() => {
        if (job_id === 0 || job_id || job_assign_start_date || job_assign_end_date) {
            const newState = JSON.parse(JSON.stringify(state))
            newState['right'] = true
            setState(newState)
        }
    }, [job_id, job_assign_start_date, job_assign_end_date])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])

    useEffect(() => {
        const getUserTimezone = () => {
            let current_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            set_timezone(current_timezone);
            update_job_state("timezone", current_timezone);
        };
        getUserTimezone();
        set_alert(false)
    }, []);


    const job_form = (anchor) => (
        <Box
            sx={{ width: { xs: "100vw", sm: 450, md: 450, lg: 450, xl: 450 }, mt: { xs: "48px", sm: "64px" }, }}

        >

            <LoadingScreen loading={loading} />
            {confirm_delete_job_dialog || confirm_delete_job_dialog === 0 ?
                <CustomModal set_preview={set_confirm_delete_job_dialog}>
                    <Box
                        sx={{
                            width: { xl: "35%", lg: "50%", md: "60%", sm: "80%", xs: "90%" },
                            h: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            bgcolor: "white",
                            cursor: "auto",
                            borderRadius: 3,
                            boxShadow: 24,
                            p: { xl: 4, lg: 4, md: 4, sm: 4, xs: 2 },
                        }}
                    >
                        {alert ? (
                            <Alert
                                onClose={() => {
                                    set_alert(false);
                                }}
                                severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                                sx={{
                                    p: 2,
                                    width: '80%',
                                    zIndex: 9992000,

                                }}
                            >
                                {alert}
                            </Alert>
                        ) : (
                            ""
                        )}

                        <style
                            dangerouslySetInnerHTML={{
                                __html: "\n#menu-{\nz-index:9992000}\n",
                            }}
                        />
                        <Typography variant="h2" my={2}>
                            Are you sure you want to delete this job?
                        </Typography>

                        <Box sx={{ width: 1 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    p: 2,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{ minHeight: '43px', }}
                                    onClick={(e) => {
                                        delete_job(e)
                                        set_alert(false)
                                        set_confirm_delete_job_dialog(false);
                                    }}
                                >
                                    {loading ? <CircularProgress color="secondary" /> : "Yes, Delete"}
                                </Button>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    sx={{ minHeight: '43px', }}
                                    onClick={() => {
                                        set_alert(false)
                                        set_confirm_delete_job_dialog(false);
                                    }}
                                >
                                    Cancel
                                </Button>

                            </Box>


                        </Box>
                    </Box>
                </CustomModal>
                : ""}
            <Box sx={{ borderBottom: "1px solid #eaeaea", p: 1, pt: 2, height: '60px', display: "flex", alignItems: "center" }}>
                <Box sx={{ flex: 1 }}>

                    <CrossIcon sx={{ cursor: "pointer", fontSize: 35 }} onClick={toggleDrawer(anchor, false)} />
                </Box>
                <Box>

                    <Typography variant="h1" align='center'>{typeof job_id == "boolean" ? "Assign a job" : "Update the job"}</Typography>
                </Box>
                <Box sx={{ flex: 1 }}></Box>

            </Box>
            {loading ? <LinearProgress color='secondary' /> : ""}

            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                    sx={{
                        p: 2,
                        mb: 2,
                        width: 1,
                        borderRadius: 2,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}
            <Box component={"form"} onSubmit={(e) => { create_or_update_job(e) }} >
                {/* form inputs  */}
                <Box sx={{ m: 5 }}>
                    <Box>
                        {/* job start date input */}
                        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
                            <Typography sx={{ pr: 1, whiteSpace: "nowrap" }} variant='subtitle2'>Start Date</Typography>
                            <TextField required value={job['start_date'] ? job['start_date'] : generate_local_timezone_based_iso_date(job_assign_start_date)} onChange={(e) => { update_job_state("start_date", generate_local_timezone_based_iso_date(e.target.value)) }} fullWidth size="small" type="date" variant="outlined" />
                        </Box>
                        {/* job end date input */}
                        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
                            <Typography sx={{ pr: 1, whiteSpace: "nowrap" }} variant='subtitle2'>End Date</Typography>
                            <TextField required value={job['end_date'] ? job['end_date'] : generate_local_timezone_based_iso_date(job_assign_end_date)} onChange={(e) => { update_job_state("end_date", generate_local_timezone_based_iso_date(e.target.value)) }} fullWidth size="small" type="date" variant="outlined" />
                        </Box>
                        {/* job start time input and end time input */}
                        <Box sx={{ my: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ flex: 1, mr: 1, display: "flex", alignItems: "center" }}>
                                    <Typography sx={{ pr: 1 }} variant='subtitle2'>Start</Typography>
                                    <TextField required value={job['start_datetime']} onChange={(e) => { update_job_state("start_datetime", e.target.value) }} fullWidth size="small" type="time" variant="outlined" />
                                </Box>
                                <Box sx={{ flex: 1, ml: 1, display: "flex", alignItems: "center" }}>
                                    <Typography sx={{ pr: 1 }} variant='subtitle2'>End</Typography>
                                    <TextField required value={job['end_datetime']} onChange={(e) => { update_job_state("end_datetime", e.target.value) }} fullWidth size="small" type="time" variant="outlined" />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant='subtitle2' sx={{ ml: 5, color: total_shift_time.includes('-') ? "red" : "green" }}>
                                    {total_shift_time}
                                </Typography>
                            </Box>

                        </Box>
                        {/* job timezone input */}
                        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
                            <Typography sx={{ pr: 1 }} variant='subtitle2'>Timezone</Typography>
                            <TextField required value={job['timezone'] ? job['timezone'] : timezone} onChange={(e) => { update_job_state("timezone", e.target.value) }} fullWidth size="small" variant="outlined" />
                        </Box>
                        <br />
                        <Divider />
                        <br />
                    </Box>

                    <Box>
                        {/* job title input  */}
                        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
                            <Typography sx={{ pr: 1, whiteSpace: "nowrap" }} variant='subtitle2'>Job Title</Typography>
                            <TextField required value={job['job_title']} onChange={(e) => { update_job_state("job_title", e.target.value) }} fullWidth size="small" placeholder='Type here' variant="outlined" />
                        </Box>
                        {/* job project input  */}
                        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
                            <Typography sx={{ pr: 1, whiteSpace: "nowrap" }} variant='subtitle2'>Assign Project </Typography>


                            <FormControl sx={{ m: 1, minWidth: 80 }} fullWidth size="small">
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    value={job_project}
                                    onChange={(e) => { set_job_project(e.target.value) }}
                                    size="small"
                                >
                                    <MenuItem value={-1}>
                                        <em>None</em>
                                    </MenuItem>
                                    {all_job_projects.map((project) => {
                                        return (
                                            <MenuItem value={project.id}>{project.name}</MenuItem>
                                        )
                                    })}
                                    <Divider />
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Button fullWidth variant="outlined" color="secondary" onClick={() => {
                                            window.open("/dashboard/projects/details", '_blank')
                                        }} sx={{ width: "93%", my: 0.5, height: '40px' }}>
                                            Create Project
                                        </Button>
                                    </Box>
                                </Select>
                            </FormControl>
                        </Box>
                        {/* job location  */}
                        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
                            <Typography sx={{ pr: 1, whiteSpace: "nowrap" }} variant='subtitle2'>Location</Typography>
                            <TextField value={job['location']} onChange={(e) => { update_job_state("location", e.target.value) }} fullWidth size="small" placeholder='Type here' variant="outlined" />
                        </Box>
                        {/* job instructions input  */}
                        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
                            <Typography sx={{ pr: 1, whiteSpace: "nowrap" }} variant='subtitle2'>Instructions</Typography>
                            <TextField value={job['instructions']} onChange={(e) => { update_job_state("instructions", e.target.value) }} fullWidth size="small" placeholder='Type here' variant="outlined" />
                        </Box>
                        {/* Members input  */}
                        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
                            <Typography sx={{ pr: 1, whiteSpace: "nowrap" }} variant='subtitle2'>Assign to</Typography>

                            <FormControl fullWidth >
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    required
                                    size="small"
                                    value={selected_members}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" />}
                                    renderValue={(members) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {members.map((member) => (
                                                <Chip key={member.id} label={member.first_name + " " + member.last_name} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {members.map((member) => (
                                        <MenuItem
                                            key={member.id}
                                            value={member}
                                            style={getStyles(member.first_name + " " + member.last_name, selected_members, theme)}
                                        >
                                            {member.first_name + " " + member.last_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        {/* job stage input  */}
                        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
                            <Typography sx={{ pr: 1, whiteSpace: "nowrap" }} variant='subtitle2'>Job Stage</Typography>


                            <FormControl sx={{ m: 1, minWidth: 80 }} fullWidth size="small">
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    value={job_stage}
                                    onChange={(e) => { set_job_stage(e.target.value) }}
                                    size="small"
                                >

                                    {all_job_stages.map((stage) => {
                                        return (
                                            <MenuItem value={stage.id}>{stage.name}</MenuItem>
                                        )
                                    })}
                                    <Divider />
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Button fullWidth variant="outlined" color="secondary" onClick={() => { window.open("/dashboard/settings?tab=inputs", '_blank') }} sx={{ width: "93%", my: 0.5, height: '40px' }}>
                                            Create Job Stage
                                        </Button>
                                    </Box>
                                </Select>
                            </FormControl>
                        </Box>
                        <br />
                        <Divider />
                        <br />
                    </Box>

                </Box>
                {alert ? (
                    <Alert
                        onClose={() => {
                            set_alert(false);
                        }}
                        severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                        sx={{
                            p: 2,
                            mb: '60px',
                            borderRadius: 2,
                            zIndex: 9923213,
                        }}
                    >
                        {alert}
                    </Alert>
                ) : (
                    ''
                )}
                {/* form CTAs  */}
                <Box sx={{ position: "fixed", bottom: 0, width: 1, bgcolor: "white", boxShadow: 5 }}>


                    {loading ? <LinearProgress color='secondary' /> : ""}

                    <Divider fullWidth />

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 1 }}>
                        <Box sx={{ flex: 1 }}>
                            <Button type='submit' variant='contained'>{typeof job_id == "boolean" ? "Assign" : "Update"}</Button>
                            <Button onClick={toggleDrawer(anchor, false)} variant='gray_button'>Cancel</Button>
                            {typeof job_id != "boolean" &&
                                <Button onClick={() => set_confirm_delete_job_dialog(job_id)} variant='outlined' color='error'>Delete</Button>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box

        >
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {job_form(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </Box>
    );
}


export default JobCRUD