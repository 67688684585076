      import React, { useEffect, useState } from 'react'
      import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'
      import EastSharpIcon from '@mui/icons-material/EastSharp';      
      import axios from 'axios';
      import csrf_grabber from '../../../../components/csrf_grabber';
      import CustomModal from '../../../../components/CustomModal';
      import { connect } from 'react-redux';
      import { useNavigate } from 'react-router-dom';
      import { Divider } from '@mui/material'      
      const TaskActivity = ({user, set_alert, set_loading}) => {
      
      var csrftoken = csrf_grabber('csrftoken');
     
        const [something, set_something] = useState(false);
      
        const navigate = useNavigate()
      
          const get_something = async () => {
            set_loading(true)
            const config = {
                params: { },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRFToken': csrftoken
                },
            };
    
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
                config
            );
            if (res.data.status == 1) {
                set_loading(false)
                set_something(res.data.response);
            } else {
                set_loading(false)
                set_alert(res.data.response)
            }
        }
        useEffect(() => {
          if (!(localStorage.getItem('access'))) {
            navigate('/login')
          }
        }, [])
        return (
          <>
          <Box sx={{ width:'50%', display:'flex', justifyContent:'space-between', alignItems:'center',height:'10vh' }} >
          <Box>
            <Button>
             <EastSharpIcon sx={{color:'black'}} />
            </Button>
          </Box>
          <Box>
          <Typography>Activity</Typography>
          </Box>
          </Box>
          <Divider sx={{ height: 2}} />
          </>
        )
      }
      
      const mapStateToProps = (state) => ({
        user: state.auth.user,
      });
      
      export default connect(mapStateToProps, {})(TaskActivity);