import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Chip, Divider, OutlinedInput, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, selected_members, theme) {
    return {
        fontWeight:
            selected_members.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export function DropdownInput({ state, set_state, dropdown_options, create_url, title }) {
    const navigate = useNavigate()
    var inputRef = React.useRef(undefined);

    const [options_state_copy, set_options_state_copy] = React.useState([])
    const [search_term, set_search_term] = React.useState("")

    const handleChange = (event) => {
        set_state(event.target.value);
    };

    React.useEffect(() => {
        set_options_state_copy(JSON.parse(JSON.stringify(dropdown_options)))
    }, [dropdown_options])


    return (
        <Box sx={{ minWidth: 120 }}>

            <FormControl fullWidth>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state}
                    onChange={handleChange}

                    size='small'
                    autocomplete="off"
                    disableportal  // Disable automatic focusing on search terms
                    MenuProps={{ autoFocus: false }}
                    onAnimationEnd={() => inputRef && inputRef.current && inputRef.current.focus()}
                >


                    <Box sx={{ display: "flex", justifyContent: "center", my: 1, mx: 1 }}>
                        <TextField ref={inputRef} autoFocus value={search_term} size="small" id="name" fullWidth placeholder='Search by name' variant="outlined" sx={{ bgcolor: "#F9F9F9" }}
                            onChange={(e) => {
                                const value = e.target.value;
                                set_search_term(value);
                                // Filter the data based on the search term
                                const filteredData = dropdown_options.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
                                set_options_state_copy(filteredData);

                            }}
                            autocomplete="off"
                        />
                    </Box>
                    <Divider />
                    <Box sx={{ mb: 0.8 }}></Box>
                    <MenuItem key={-1} value={-1}><Typography variant='subtitle2'>None</Typography></MenuItem>

                    {options_state_copy.map((option) => {
                        return (
                            // Don't delete the invisible character it helps disable the auto focus of mui select while using the search bar
                            <MenuItem key={option.id} value={option.id}><Typography variant='subtitle2'>{option.name}</Typography></MenuItem>
                        )
                    })}
                    <Divider />
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button fullWidth variant="outlined" color="secondary" onClick={() => { window.open(create_url, "_blank") }} sx={{ width: "93%", my: 0.5, height: '40px' }}>
                            Create {title}
                        </Button>
                    </Box>
                </Select>
            </FormControl>
        </Box>
    );
}
export function MultiSelectDropdownInput({ state, set_state, dropdown_options, create_url, title }) {
    const navigate = useNavigate()
    const theme = useTheme()
    const handleChange = (event) => {
        let {
            target: { value },
        } = event;

        let dataList = typeof value === 'string' ? value.split(',') : value

        const nameCount = {};
        dataList.forEach((item) => {
            if (nameCount[item.id]) {
                nameCount[item.id]++;
            } else {
                nameCount[item.id] = 1;
            }
        });

        const filteredData = dataList.filter((item) => nameCount[item.id] === 1);
        set_state(filteredData);
    };


    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth >
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    placeholder={`Select ${title}`}
                    size="small"
                    value={state}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" />}
                    renderValue={(dropdown_options) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {dropdown_options.map((option) => (
                                <Chip key={option.id} label={option.name} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >

                    {dropdown_options.map((option) => (
                        <MenuItem
                            key={option.id}
                            value={option}
                            style={getStyles(option.name, state, theme)}
                        >
                            {option.name} 
                        </MenuItem>
                    ))}
                    <Divider />
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button fullWidth variant="outlined" color="secondary" onClick={() => { window.open(create_url, '_blank') }} sx={{ width: "93%", my: 0.5, height: '40px' }}>
                            Create {title}
                        </Button>
                    </Box>
                </Select>
            </FormControl>
        </Box>
    );
}