import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Divider, Typography } from '@mui/material'

import axios from 'axios';
import csrf_grabber from '../../../components/csrf_grabber';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import RecentActivityGraph from './RecentActivityGraph';
import RecentActivityList from './RecentActivityList';
import Container from '../../../components/Container';
import LoadingScreen from '../../../components/LoadingScreen';

import MembersIcon from '@mui/icons-material/PersonAddAltOutlined';
import JobsIcon from '@mui/icons-material/WorkOutline';
import ChatIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FormsIcon from '@mui/icons-material/DocumentScannerOutlined';


const AdminDashboard = ({ user }) => {
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

  const navigate = useNavigate()
  
  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])

  return (
    <Box >
      <LoadingScreen loading={loading} />

      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity='error'
          sx={{
            p: 2,
            width: 1,
            borderRadius:2,
zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ''
      )}

      <Typography variant='h1' sx={{ fontWeight: "light", mb: 2, mt: 1 }}>

        Welcome {user ? user.username : ""}
      </Typography>
      {/* Quick actions div  */}
      <Container>
        <Typography variant='subtitle2'>Quick Actions</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 4, mb: 1, flexWrap: "wrap", overflowX: "scroll" }}>
          {/* Action  1  */}
          <Box   onClick={() => { navigate("/dashboard/members") }} sx={{ flex: 1, m: 2, display: "flex", flexDirection: "column", minWidth: '200px', maxWidth: '300px', alignItems: "center", padding: '20px', bgcolor: "white", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)", cursor: "pointer", "&:hover": { border: "1px solid", borderColor: "primary.main", color: "primary.main" } }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 2, bgcolor: "#F6F6F6", p: 2, borderRadius: 3, height: '80px', width: '70px' }}>
            <MembersIcon sx={{fontSize:36, color:"grey"}} />
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Members
              </Typography>
            </Box>
          </Box>
          {/* Action 2  */}
          <Box   onClick={() => { navigate("/dashboard/job-scheduling  ") }} sx={{ flex: 1, m: 2, display: "flex", flexDirection: "column", minWidth: '200px', maxWidth: '300px', alignItems: "center", padding: '20px', bgcolor: "white", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)", cursor: "pointer", "&:hover": { border: "1px solid", borderColor: "primary.main", color: "primary.main" } }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 2, bgcolor: "#F6F6F6", p: 2, borderRadius: 3, height: '80px', width: '70px' }}>
            <JobsIcon sx={{fontSize:36, color:"grey"}} />

            </Box>
            <Box>
              <Typography variant="subtitle2">
                Job Scheduler
              </Typography>
            </Box>
          </Box>
          {/* Action 3  */}
          <Box   onClick={() => { navigate("/dashboard/chat") }} sx={{ flex: 1, m: 2, display: "flex", flexDirection: "column", minWidth: '200px', maxWidth: '300px', alignItems: "center", padding: '20px', bgcolor: "white", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)", cursor: "pointer", "&:hover": { border: "1px solid", borderColor: "primary.main", color: "primary.main" } }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 2, bgcolor: "#F6F6F6", p: 2, borderRadius: 3, height: '80px', width: '80px' }}>
            <ChatIcon sx={{fontSize:36, color:"grey"}} />

            </Box>
            <Box>
              <Typography variant="subtitle2">
                Chat
              </Typography>
            </Box>
          </Box>
          {/* Action 4  */}
          <Box   onClick={() => { navigate("/dashboard/forms") }} sx={{ flex: 1, m: 2, display: "flex", flexDirection: "column", minWidth: '200px', maxWidth: '300px', alignItems: "center", padding: '20px', bgcolor: "white", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)", cursor: "pointer", "&:hover": { border: "1px solid", borderColor: "primary.main", color: "primary.main" } }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 2, bgcolor: "#F6F6F6", p: 2, borderRadius: 3, height: '80px', width: '80px' }}>
            <FormsIcon sx={{fontSize:36, color:"grey"}} />

            </Box>
            <Box>
              <Typography variant="subtitle2">
                Forms
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: { md: "row", sm: "column", xs: "column" } }}>
        {/* Mini Schedular div  */}
        <Box sx={{ flex: 1, my: 4, mr: 2, }}><RecentActivityGraph /></Box>
        {/* Recent Activity div  */}
        <Box sx={{ flex: 1, my: 4, ml: 2, }}><RecentActivityList /></Box>
      </Box>



    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(AdminDashboard);