import { Box } from '@mui/system'
import React, { useState } from 'react'
import CustomModal from '../../../../components/CustomModal'
import { useNavigate } from 'react-router-dom';
import { Alert, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { axios_get_call, axios_post_call, axios_delete_call } from '../../../../components/AxiosCall';

function CRUDClients({ edit_or_update_clients, set_edit_or_update_clients, get_clients }) {

    const navigate = useNavigate();
    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [client, set_client] = useState(false);
    const [preview_image, set_preview_image] = useState(false);
    const [delete_the_client, set_delete_the_client] = useState(false);

    const [name, set_name] = useState("")
    const [email, set_email] = useState("")
    const [phone_number, set_phone_number] = useState("")
    const [address, set_address] = useState("")
    const [profile_pic, set_profile_pic] = useState("")

    const get_customer = async () => {
        if (typeof edit_or_update_clients != "boolean") {
            let url = '/saas/clients/get-client/';
            let res = await axios_get_call(url, { client_id: edit_or_update_clients }, set_loading, set_alert);

            if (res.data.status == 1) {
                set_loading(false);
                set_client(res.data.response);
                set_name(res.data.response.name)
                set_email(res.data.response.email)
                set_phone_number(res.data.response.phone_number)
                set_address(res.data.response.address)
                set_profile_pic(res.data.response.profile_pic)
            } else if (res.data.status == 0) {
                set_alert(res.data.response);
                set_loading(false);
            }
        } else {
            set_name("")
            set_email("")
            set_phone_number("")
            set_address("")
            set_profile_pic("")
            set_preview_image(false)
        }
    }


    const create_or_update_customer = async (e) => {
        e.preventDefault();
        let url = '/saas/clients/create-or-update-client/';
        const formData = new FormData();
        formData.append('client_id', edit_or_update_clients);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone_number', phone_number);
        formData.append('address', address);
        formData.append('profile_pic', profile_pic);


        let res = await axios_post_call(url, formData, set_loading, set_alert, "multipart/form-data");

        if (res.data.status == 1) {
            set_loading(false);
            get_clients()
            set_edit_or_update_clients(false)
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }

    }

    const delete_client = async () => {
        let url = '/saas/clients/delete-client/';
        let res = await axios_delete_call(url, { "client_id": client.id }, set_loading);

        if (res.data.status == 1) {
            set_loading(false);
            set_edit_or_update_clients(false)
            get_clients()
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }

    }

    useEffect(() => {
        get_customer()
    }, [edit_or_update_clients])


    return (
        edit_or_update_clients || edit_or_update_clients === 0 ?
            <CustomModal set_preview={set_edit_or_update_clients}>
                <Box
                    sx={{
                        width: { xl: "35%", lg: "50%", md: "60%", sm: "80%", xs: "90%" },
                        h: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        bgcolor: "white",
                        cursor: "auto",
                        borderRadius: 3,
                        boxShadow: 24,
                        p: { xl: 4, lg: 4, md: 4, sm: 4, xs: 2 },
                    }}
                >
                    {alert ? (
                        <Alert
                            onClose={() => {
                                set_alert(false);
                            }}
                            severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                            sx={{
                                p: 2,
                                width: '80%',
                                zIndex: 9992000,

                            }}
                        >
                            {alert}
                        </Alert>
                    ) : (
                        ""
                    )}

                    <style
                        dangerouslySetInnerHTML={{
                            __html: "\n#menu-{\nz-index:9992000}\n",
                        }}
                    />
                    <Typography variant="h2" my={2}>
                        {typeof edit_or_update_clients == "boolean"
                            ? "Create a client"
                            : "Update the client"}
                    </Typography>

                    <Box sx={{ width: 1 }} component="form" enctype="multipart/form-data" onSubmit={(e) => { create_or_update_customer(e) }} >
                        {/* name input  */}
                        <Box sx={{ my: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ width: '100px' }}><Typography variant='subtitle2' align='left'>Name</Typography></Box>
                            <Box sx={{ flex: 3 }}><TextField required value={name} size="small" onChange={(e) => { set_name(e.target.value) }} id="name" fullWidth variant="outlined" /></Box>
                        </Box>
                        {/* email input  */}
                        <Box sx={{ my: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ width: '100px' }}><Typography variant='subtitle2' align='left'>Email</Typography></Box>
                            <Box sx={{ flex: 3 }}><TextField type="email" required value={email} size="small" onChange={(e) => { set_email(e.target.value) }} id="email" fullWidth variant="outlined" /></Box>
                        </Box>
                        {/* phone no input  */}
                        <Box sx={{ my: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ width: '100px' }}><Typography variant='subtitle2' align='left'>Phone no</Typography></Box>
                            <Box sx={{ flex: 3 }}><TextField type="tel" required value={phone_number} size="small" onChange={(e) => { set_phone_number(e.target.value) }} id="phone_number" fullWidth variant="outlined" /></Box>
                        </Box>
                        {/* address input  */}
                        <Box sx={{ my: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ width: '100px' }}><Typography variant='subtitle2' align='left'>Address</Typography></Box>
                            <Box sx={{ flex: 3 }}><TextField required value={address} size="small" onChange={(e) => { set_address(e.target.value) }} id="address" fullWidth variant="outlined" /></Box>
                        </Box>
                        {/* Profile pic input  */}
                        <Box sx={{ my: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ width: '100px' }}><Typography variant='subtitle2' align='left'>Profile pic</Typography></Box>
                            <Box sx={{ flex: 3, bgcolor: "#F9F9F9", p: 1, textAlign: "left", justiyContent: "left" }}>{profile_pic ?
                                (<Box>
                                    {preview_image ? <img src={preview_image} width="250px" alt="Selected Image" /> :

                                        <img src={process.env.REACT_APP_BACKEND_URL.split("/api")[0] + profile_pic} width="250px" />}
                                    <Typography align="left" variant='subtitle1'>Selected, <span onClick={() => { set_profile_pic("") }} style={{ textDecoration: "underline", cursor: "pointer", color: "#EA2456" }}>deselect</span></Typography>

                                </Box>)
                                : <TextField  type="file" accept="image/*" value={profile_pic} size="small" onChange={(e) => {
                                    const file = e.target.files[0];
                                    set_profile_pic(file);

                                    const reader = new FileReader();

                                    reader.onload = () => {
                                        set_preview_image(reader.result);
                                    };

                                    if (file) {
                                        reader.readAsDataURL(file);
                                    }

                                }} id="profile_pic" fullWidth variant="outlined" />}
                            </Box>
                        </Box>
                        {/* submit btn  */}
                        <Box sx={{ mt: 4, mb: 2, display: 'flex' }}>
                            <Box sx={{ flex: 1, mx: 1 }}>
                                <Button variant='contained' color="secondary" type="submit" fullWidth > {loading ? <CircularProgress color="secondary" /> : typeof edit_or_update_clients == "boolean"
                                    ? "Create"
                                    : "Update"}
                                </Button>
                            </Box>
                            <Box sx={{ flex: 1, mx: 1 }}>
                                <Button variant='outlined' color="error" type="button" fullWidth onClick={() => { set_delete_the_client(true) }}> Delete client
                                </Button>
                            </Box>
                            <Box sx={{ flex: 1, mx: 1 }}><Button variant='gray_button' fullWidth onClick={() => { set_edit_or_update_clients(false) }}>Cancel</Button></Box>
                        </Box>


                        {delete_the_client &&
                            <CustomModal set_preview={set_delete_the_client}>
                                <Box
                                    sx={{
                                        width: { xl: "35%", lg: "50%", md: "60%", sm: "80%", xs: "90%" },
                                        h: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        bgcolor: "white",
                                        cursor: "auto",
                                        borderRadius: 3,
                                        boxShadow: 24,
                                        p: { xl: 4, lg: 4, md: 4, sm: 4, xs: 2 },
                                    }}
                                >
                                    {alert ? (
                                        <Alert
                                            onClose={() => {
                                                set_alert(false);
                                            }}
                                            severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                                            sx={{
                                                p: 2,
                                                width: '80%',
                                                zIndex: 9992000,

                                            }}
                                        >
                                            {alert}
                                        </Alert>
                                    ) : (
                                        ""
                                    )}

                                    <style
                                        dangerouslySetInnerHTML={{
                                            __html: "\n#menu-{\nz-index:9992000}\n",
                                        }}
                                    />
                                    <Typography variant="h2" my={2}>
                                        Are you sure you want to delete this client?
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Box sx={{ flex: 1 }}><Button variant='contained' onClick={() => { delete_client() }}>Yes, Delete</Button></Box>
                                        <Box sx={{ flex: 1 }}><Button variant='outlined' onClick={() => { set_delete_the_client(false) }}>Cancel</Button></Box>
                                    </Box>
                                </Box>
                            </CustomModal>
                        }



                    </Box>
                </Box>
            </CustomModal> : ""
    )
}

export default CRUDClients