import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";

import axios from "axios";
import csrf_grabber from "../../../../components/csrf_grabber";
import LoadingScreen from "../../../../components/LoadingScreen";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../../../../components/Container";
import TimeClockIcon from "@mui/icons-material/ScheduleOutlined";
import { axios_get_call } from "../../../../components/AxiosCall";
import AdminTimeClock from "./AdminTimeClock";
import UserTimeClock from "./UserTimeClock";

const TimeClock = ({ user }) => {
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  return (
    user && (
    <Box sx={{mx:2}}>

        <LoadingScreen loading={loading} />
        {alert ? (
          <Alert
            onClose={() => {
              set_alert(false);
            }}
            severity="error"
            sx={{
              p: 2,
              width: 1,
              borderRadius: 2,
              zIndex: 9923213,
            }}
          >
            {alert}
          </Alert>
        ) : (
          ""
        )}
        <Box sx={{ my: 2, width: "98.1%" }}>
          <Container>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TimeClockIcon
                  sx={{ color: "primary.main", fontSize: 36, m: 1 }}
                />
                <Typography
                  align="left"
                  variant="h1"
                  sx={{ fontWeight: "light" }}
                >
                  Time Clock
                </Typography>
              </Box>
              
            </Box>
          </Container>
          <Box sx={{ mt: 4 }}>
            {user.is_admin_view ? (
              <AdminTimeClock
                loading={loading}
                set_loading={set_loading}
                alert={alert}
                set_alert={set_alert}
              />
            ) : (
              <UserTimeClock
                loading={loading}
                set_loading={set_loading}
                alert={alert}
                set_alert={set_alert}
              />
            )}
          </Box>
        </Box>
      </Box>
    )
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(TimeClock);
