      import React, { useEffect, useState } from 'react'
      import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'
      import EastSharpIcon from '@mui/icons-material/EastSharp';      
      import axios from 'axios';
      import csrf_grabber from '../../../../components/csrf_grabber';
      import CustomModal from '../../../../components/CustomModal';
      import { connect } from 'react-redux';
      import { useNavigate } from 'react-router-dom';
      import { Divider } from '@mui/material'  
       import { Tab } from '@mui/material';
      import { TabContext, TabList, TabPanel } from '@mui/lab';   
import TaskDetails from './TaskDetails';

      
      const UserTaskDetails = ({user, set_alert, set_loading}) => {
        const [value, setValue] = useState('0');
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };
      var csrftoken = csrf_grabber('csrftoken');
        const [something, set_something] = useState(false);
      
        const navigate = useNavigate()
      
          const get_something = async () => {
            set_loading(true)
            const config = {
                params: { },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRFToken': csrftoken
                },
            };
    
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
                config
            );
            if (res.data.status == 1) {
                set_loading(false)
                set_something(res.data.response);
            } else {
                set_loading(false)
                set_alert(res.data.response)
            }
        }
        useEffect(() => {
          if (!(localStorage.getItem('access'))) {
            navigate('/login')
          }
        }, [])
        return (
             <>
          <Box>
          <Box sx={{ width:'60%', display:'flex', justifyContent:'space-between', alignItems:'center',height:'10vh' }} >
          <Box>
            <Button>
             <EastSharpIcon sx={{color:'black'}} />
            </Button>
          </Box>
          <Box>
          <Typography>User Tasks Details</Typography>
          </Box>
          </Box>
          <Divider sx={{ height: 2}} />
          </Box>
          
		<Box>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>
				<TabList onChange={handleChange} sx={{
					margin: 'auto',
					marginTop: 2,
					bgcolor: "white",
                    
				}}>
					<Tab label='Tasks Details' value='0' ></Tab>
					<Tab label='Sub Tasks' value='1' ></Tab>
					<Tab label='Comments' value='2' ></Tab>
				</TabList>
				<TabPanel value='0'  ><TaskDetails/> </TabPanel>
				<TabPanel value='1'>SubTasks</TabPanel>
				<TabPanel value='2'>Comments</TabPanel>
			</TabContext>

		</Box>
          </>
          
        )
      }
      
      const mapStateToProps = (state) => ({
        user: state.auth.user,
      });
      
      export default connect(mapStateToProps, {})(UserTaskDetails);