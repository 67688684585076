      import React, { useEffect, useState } from 'react'
      import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'
      import axios from 'axios';
      import csrf_grabber from '../../../../components/csrf_grabber';
      import CustomModal from '../../../../components/CustomModal';
      import { connect } from 'react-redux';
      import { useNavigate } from 'react-router-dom';
      import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
      import { Divider } from '@mui/material'      


      
      const EditAssignment = ({user, set_alert, set_loading}) => {
      
      var csrftoken = csrf_grabber('csrftoken');
        const [something, set_something] = useState(false);
      
        const navigate = useNavigate()
      
          const get_something = async () => {
            set_loading(true)
            const config = {
                params: { },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRFToken': csrftoken
                },
            };
    
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
                config
            );
            if (res.data.status == 1) {
                set_loading(false)
                set_something(res.data.response);
            } else {
                set_loading(false)
                set_alert(res.data.response)
            }
        }
        useEffect(() => {
          if (!(localStorage.getItem('access'))) {
            navigate('/login')
          }
        }, [])
        return (
          <Box>
            
            <Box sx={{display:'flex',justifyContent:'flex-end',height:'10vh' }}>
                <Box sx={{width:'70%', display:'flex',justifyContent:'space-around', marginTop:'15px' , alignItems:'flex-start' }}  >
                    <Typography>Publish By</Typography>
                    <Typography sx={{display:'flex', alignItems:'center' }} > <HelpOutlineSharpIcon sx={{height:'3vh',marginRight:'10px'}} /> Needhelp?</Typography>
                </Box>
            </Box>
            <Divider sx={{ height: 2}} />
          </Box>
        )
      }
      
      const mapStateToProps = (state) => ({
        user: state.auth.user,
      });
      
      export default connect(mapStateToProps, {})(EditAssignment);