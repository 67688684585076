
import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Typography ,Paper } from '@mui/material'
import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow } from '@mui/material';      
import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import CustomModal from '../../../../components/CustomModal';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';    
import Divider from '@mui/material/Divider';
import IosShareIcon from "@mui/icons-material/IosShare";
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { styled, alpha } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import Taskdrawer from './Taskdrawer';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  height:400,
  bgcolor: 'background.paper',
  borderRadius:5,
  boxShadow: 24,
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
const tableData = [
  {
      id: 1,
      generalTasks:['muhammad' ,<CommentRoundedIcon/>],
      // status: <Button variant='contained'  > open</Button>,
      subTasks:'',
      startDate:' 1/2/23',
      dueDate: '1/2/23',
      assignedTo: '3/5/2023',
  }
  
]      


const MyTasks = ({user, set_alert, set_loading}) => {
  const [select, setSelect] = React.useState('');

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  const [openTask, setOpenTask] = React.useState(false);
  const handleOpenTask = () => setOpenTask(true);
  const handleCloseTask = () => setOpenTask(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
const open = Boolean(anchorEl);
const handleClick = (event) => {
setAnchorEl(event.currentTarget);
};
const handleClose = () => {
setAnchorEl(null);
};
const [drawer, set_drawer] = React.useState({
  right: false,
  taskDetails:false,
  taskSetting:false,
  addMultipleTasks:false,
});
const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
  }
  set_drawer({ ...drawer, [anchor]: open });
};   
var csrftoken = csrf_grabber('csrftoken');
  const [something, set_something] = useState(false);

  const navigate = useNavigate()

    const get_something = async () => {
      set_loading(true)
      const config = {
          params: { },
          headers: {
              Authorization: `Bearer ${localStorage.getItem('access')}`,
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'X-CSRFToken': csrftoken
          },
      };

      const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
          config
      );
      if (res.data.status == 1) {
          set_loading(false)
          set_something(res.data.response);
      } else {
          set_loading(false)
          set_alert(res.data.response)
      }
  }
  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])
  return (
    <Box>
      
<TableContainer >
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginBottom:'10px' }} >

      <Box sx={{  margin: '15px'}} >

  <Button variant="outlined" sx={{marginRight:'15px'}} >
  <FilterListRoundedIcon />
  </Button>
  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={select}
          onChange={handleChange}
          label="Age"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Title</MenuItem>
          <MenuItem value={20}>Assigned to</MenuItem>
          <MenuItem value={30}>Label</MenuItem>
        </Select>
      </FormControl>
      
    
      </Box>
      <Box>
<TextField id="standard-basic" label="search" variant="standard" />
<Button
            variant="contained"
            
            sx={{ mx: "10px" }}
          >
            <IosShareIcon /> Export
          </Button>
         
          <Button 
        
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<AddCircleOutlineRoundedIcon />}
      >
        Add Tasks 
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenTask}>
          Add single task
        </MenuItem>
        <MenuItem  onClick={toggleDrawer("addMultipleTasks", true)}>
        Add multiple tasks
        </MenuItem>
      </StyledMenu>
      </Box>
      <Modal
        open={openTask}
        onClose={handleCloseTask}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <Box sx={style}>
          <Box sx={{ width:'50%', display:'flex', alignItems:'center',height:'10vh',
          justifyContent:'space-between'}} >
          <Box>
            <Button>
             <EastSharpIcon onClick={handleCloseTask} sx={{color:'black'}} />
            </Button>
          </Box>
          <Box>
          <Typography>New Task</Typography>
          </Box>
          </Box>
          <Divider sx={{ height: 2}} />
          <Box sx={{width:'90%' , margin:'auto' , marginTop:3, display:'flex',alignItems:'center'}}>
            <Typography>Task Title</Typography>
            <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '40ch', },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="outlined-basic"  variant="outlined" />
    </Box>
  

          </Box>
          <Box sx={{width:'90%' , margin:'auto' , marginTop:3, display:'flex',alignItems:'center'}}>
            <Typography>Assign To</Typography>
            <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '40ch', },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="outlined-basic"  variant="outlined" />
    </Box>
  

          </Box>
          <Button variant='outlined' sx={{margin:'10px',}} > <AddCircleOutlineRoundedIcon sx={{marginRight:'4px'}} /> Add Details</Button>
          
        <Divider sx={{ height: 2}} />
          <Button variant='contained' sx={{margin:'10px'}} >Publish Task</Button>
          <Button variant='contained' sx={{margin:'10px'}} >Save draft</Button>
        </Box>
      </Modal> 
  </Box>
  
  <Divider sx={{ height: 2}} />
      <Box sx={{marginTop:'30px', marginBottom:'30px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
        <Box>
          The view contains 
          <Button sx={{marginRight:'10px', marginLeft:'10px'}} variant='outlined' >1 task in total</Button>
          <Button sx={{marginRight:'10px'}} variant='contained' >1 open task</Button>
          <Button variant='outlined' >0 done tasks</Button>
        </Box>
          <Button sx={{marginRight:'10px'}} variant='contained' >Overdue Tasks</Button>
                              
      </Box>
  <Table  aria-label="simple table">
    <TableHead sx={{ backgroundColor: 'whitesmoke' }}>
      <TableRow>
        <TableCell align='center' sx={{ fontWeight: 'bold' }}>General Tasks</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }} >Status</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }} >Sub Tasks</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }} >Start Date</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Due Date</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Assigned to</TableCell>
      </TableRow>
    </TableHead>
    <TableBody >
      {
        tableData.map(row => (
          <TableRow  onClick={toggleDrawer("taskDetails", true)} key={row.id} sx={{ '&last-child td,&:last-child th': { border: 0, } ,cursor: 'pointer' }}>
            <TableCell sx={{display:'flex' , alignItems:'center', justifyContent:'space-between'}} align='center'>{row.generalTasks}</TableCell>
            <TableCell align='center'>{row.status}</TableCell>
            <TableCell align='center'>{row.subTasks}</TableCell>
            <TableCell align='center'>{row.startDate}</TableCell>
            <TableCell align='center'>{row.dueDate}</TableCell>
            <TableCell align='center'>{row.assignedTo}</TableCell>
          </TableRow>

        ))
      }

    </TableBody>
  </Table>
</TableContainer>

<Taskdrawer state={drawer} set_state={set_drawer} toggleDrawer={toggleDrawer}  />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(MyTasks);