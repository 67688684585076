import React from 'react'
import { Box } from '@mui/material'
import { connect } from 'react-redux';
import AdminDashboard from './AdminDashboard';
import EmployeeDashboard from './EmployeeDashboard';

const Overview = ({ user }) => {
  return user && (
    <Box sx={{mx:2}}>

     {user.is_superuser ? <AdminDashboard /> : <EmployeeDashboard />}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Overview);