import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";

const Footer = () => {
  const [current_year, set_current_year] = useState("Current Year")

  useEffect(() => {
    set_current_year(new Date().getFullYear())
  }, [])
  

  return (
    <Box
      bgcolor="customBox.themeAdaptive"
      color="white"
      sx={{
        width: "100%",
        height: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Typography variant="body1" align="center">
        Copyright ©{current_year} | crm.net
      </Typography>
    
    </Box>
  );
};

export default Footer;
