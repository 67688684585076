import React, { useEffect, useState } from 'react';
import { Box, Button, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InviteLink from './InviteLink';
import Qrcode from './Qrcode';
import ImportUsers from './ImportUsers';
import MemberDetails from './MemberDetails';
import AddUsers from './AddUsers';
import Addadmins from './Addadmins';


const DrawerLinks = ({ user, toggleDrawer, setState, state }) => {
  var csrftoken = csrf_grabber('csrftoken');

  const [something, set_something] = useState(false);
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

  const navigate = useNavigate();

  const get_something = async () => {
    set_loading(true);
    const config = {
      params: {},
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': csrftoken,
      },
    };

    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`, config);
    if (res.data.status == 1) {
      set_loading(false);
      set_something(res.data.response);
    } else {
      set_loading(false);
      set_alert(res.data.response);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('access')) {
      navigate('/login');
    }
  }, []);

  const list = (anchor) => {
    if (anchor === 'qr_code_drawer') {
      return (
        <Box
          sx={{ width: { xs: "100%", sm: "80%", md: '37vw' } }}
          role="presentation"
        >
          <Qrcode toggleDrawer={toggleDrawer} />
        </Box>
      );
    } else if (anchor === 'get_invite_link_drawer') {
      return (
        <Box
          sx={{ width: { xs: "100%", sm: "80%", md: '37vw' } }}
          role="presentation"
        >
          <InviteLink toggleDrawer={toggleDrawer} />

        </Box>
      );
    } else if (anchor === 'member_details_drawer') {
      return (
        <Box
          sx={{ width: '100vw ', height: { xs: "100vh%", sm: "85vh" } }}
          role="presentation"
        >

          <MemberDetails toggleDrawer={toggleDrawer} />
        </Box>
      );
    }
    else if (anchor === 'import_members_drawer') {
      return (
        <Box
          sx={{ width: ' 100vw ', height: { xs: "100vh%", sm: "85vh" } }}
          role="presentation"
        >
          <ImportUsers toggleDrawer={toggleDrawer} />
        </Box>
      );
    }
    else if (anchor === 'add_users_drawer') {
      return (
        <Box
          sx={{ width: ' 100vw ', height: { xs: "100vh%", sm: "85vh" } }}
          role="presentation"
        >
          <AddUsers toggleDrawer={toggleDrawer} />
        </Box>
      );
    }
    else if (anchor === 'bottom4') {
      return (
        <Box
          sx={{ width: ' 100vw ', height: { xs: "100vh%", sm: "85vh" } }}
          role="presentation"
        >
          <Addadmins toggleDrawer={toggleDrawer} />
        </Box>
      );
    }

  };

  return (
    <Box>
      {['qr_code_drawer', 'get_invite_link_drawer', 'member_details_drawer', 'import_members_drawer', 'add_users_drawer', 'bottom4'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer sx={{ zIndex: 9923215 }}
            anchor={anchor == 'get_invite_link_drawer' ? 'right' : anchor === 'import_members_drawer' ? 'bottom' : anchor == 'add_users_drawer' ? 'bottom' : anchor === 'bottom4' ? 'bottom' : anchor == "member_details_drawer" ? "bottom" : anchor == "qr_code_drawer" ? "right" : "bottom"}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(DrawerLinks);
