import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import { logout } from "../../actions/auth";

// Icons 
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";

import OverviewIcon from '@mui/icons-material/GridViewOutlined';
import ActivityIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import MembersIcon from '@mui/icons-material/PersonOutline';
// import TeamsIcon from '@mui/icons-material/PeopleAltOutlined';
import TeamsIcon from '@mui/icons-material/Groups2Outlined';

import ChatIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import JobsIcon from '@mui/icons-material/WorkOutline';
import ProjectsIcon from '@mui/icons-material/WorkHistoryOutlined';
import TimeClockIcon from '@mui/icons-material/ScheduleOutlined';
import TasksIcon from '@mui/icons-material/HorizontalSplitOutlined';
import FormsIcon from '@mui/icons-material/DocumentScannerOutlined';
import FormEntriesIcon from '@mui/icons-material/Notes';

import AdminIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ClientsIcon from '@mui/icons-material/HandshakeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';


import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Menu, MenuItem, Tooltip } from '@mui/material';
import { connect } from 'react-redux';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const AppSidebarDesktopVersion = ({ children, logout, state, user }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [links, set_links] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout_user = () => {
    logout();
  };
  const handle_shortcuts = () => {
    if (JSON.parse(window.localStorage.getItem("sidebar_opened")) == open)
      window.localStorage.setItem("sidebar_opened", !open)
    else {
      window.localStorage.setItem("sidebar_opened", open)

    }
    setOpen(!JSON.parse(window.localStorage.getItem("sidebar_opened")))
  }
  React.useEffect(() => {
    // Function to be executed when Ctrl+B is pressed
    function handleCtrlB(e) {
      const ctrlKey = e.ctrlKey || e.metaKey; // For Mac users
      // Check if the "Space" key is pressed
      const isSpaceKey = e.key === ' ' || e.keyCode === 32;


      if (ctrlKey && isSpaceKey) {
        e.preventDefault(); // Prevent the default browser behavior (e.g., opening bookmarks)

        // Call your custom function here
        handle_shortcuts()
      }
    }

    // Attach the event listener when the component mounts
    window.addEventListener('keydown', handleCtrlB);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleCtrlB);
    };
  }, []);

  const menuId = "primary-search-account-menu";
  const AllLinks = () => (
    <React.Fragment>
      <Link
        to={"/dashboard"}
        style={{ textDecoration: "none" }}
      >
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={handleMenuClose}
        >
          View my public page
        </MenuItem>
      </Link>
      <Link to="/dashboard" style={{ textDecoration: "none" }}>
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={handleMenuClose}
        >
          Dashboard
        </MenuItem>
      </Link>

      <Divider />
      {user ? user.is_superuser ?
        <Link
          onClick={() => window.open(`${process.env.REACT_APP_BACKEND_URL.split("/api")[0]}/theoffice/`, '_blank')}
          style={{ textDecoration: "none" }}
        >
          <MenuItem

            sx={{ color: "black", fontWeight: 500 }}
          >
            Admin panel
          </MenuItem>
        </Link> : "" : ""}
      <Divider />
      <Link
        to={"/dashboard"}
        style={{ textDecoration: "none" }}
      >
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={logout_user}
        >
          Logout
        </MenuItem>
      </Link>
    </React.Fragment>
  );
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ mt: "48px", color: "#F9F9F9" }}
    >
      <allLinks />

      <Divider />
      <Box
        sx={{
          p: 2,
        }}
      >
        {state.auth.user ? (
          <Typography sx={{ color: "lightgrey" }}>
            {" "}
            {state.auth.user.email}{" "}
          </Typography>
        ) : (
          ""
        )}
      </Box>
    </Menu>
  );

  React.useEffect(() => {
    if (user) {
      if (user.is_superuser) {
        set_links(['Overview', "Divider", 'Members', 'Teams', 'Chat', 'Divider', 'Clients', 'Divider', "Job Scheduling", "Projects", "Time Clock", "Tasks", 'Divider', "Forms", "Form Entries", 'Divider', "Settings", "Admin", 'Divider', "Logout"])
      } else {
        set_links(['Overview', "Divider", 'Chat', 'Divider', "Time Clock", 'Divider', "Settings", 'Divider', "Logout"])
      }
    }

  }, [user])
  React.useEffect(() => {
    if (window.localStorage.getItem("sidebar_opened") != null) {
      setOpen(JSON.parse(window.localStorage.getItem("sidebar_opened")))
    }

  }, [])

  const handleDrawerOpen = () => {
    setOpen(true);
    window.localStorage.setItem("sidebar_opened", true)
  };

  const handleDrawerClose = () => {
    setOpen(false);
    window.localStorage.setItem("sidebar_opened", false)
  };


  const return_icon = (text) => {
    switch (text) {
      case "Overview":
        return <OverviewIcon sx={{ color: "#8c8c8c" }} />
        break;

      case "Activity":
        return <ActivityIcon sx={{ color: "#8c8c8c" }} />
        break;
      case "Members":
        return <MembersIcon sx={{ color: "#8c8c8c" }} />
        break;

      case "Teams":
        return <TeamsIcon sx={{ color: "#8c8c8c" }} />
        break;

      case "Chat":
        return <ChatIcon sx={{ color: "#8c8c8c" }} />
        break;

      case "Job Scheduling":
        return <JobsIcon sx={{ color: "#8c8c8c" }} />
        break;
      case "Projects":
        return <ProjectsIcon sx={{ color: "#8c8c8c" }} />
        break;
      case "Time Clock":
        return <TimeClockIcon sx={{ color: "#8c8c8c" }} />
        break;
      case "Tasks":
        return <TasksIcon sx={{ color: "#8c8c8c" }} />
        break;
      case "Forms":
        return <FormsIcon sx={{ color: "#8c8c8c" }} />
        break;
      case "Form Entries":
        return <FormEntriesIcon sx={{ color: "#8c8c8c" }} />
        break;
      case "Clients":
        return <ClientsIcon sx={{ color: "#8c8c8c" }} />
        break;
      case "Settings":
        return <SettingsIcon sx={{ color: "#8c8c8c" }} />
        break;
      case "Logout":
        return <LogoutIcon sx={{ color: "#8c8c8c" }} />
        break;

      default:
        return <ProjectsIcon sx={{ color: "#8c8c8c" }} />
        break;
    }
  };


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ boxShadow: "none", zIndex: (theme) => theme.zIndex.drawer + 1 }} >
        <Toolbar>
          <Box sx={{ width: 1, display: "flex", justifyContent: "space-between" }}>

            <Box tabIndex={0} sx={{ flex: 1, display: "flex", alignItems: 'center' }}>

              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"

                sx={{
                  marginRight: 1,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <IconButton onClick={handleDrawerClose} color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{
                  marginRight: 1,
                  ...(!open && { display: 'none' }),
                }}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                CRM
              </Typography>
            </Box>
            <Box sx={{ flex: 1, display: "flex", alignItems: 'center', justifyContent: "right" }}>
              {/* Profile Part  */}
              {/* <Box sx={{
                flexGrow: 0, display: {
                  md: state.auth.isAuthenticated == false ? "none" : "initial",
                  xs: "initial",
                }
              }}>
                <Tooltip title="Open settings">
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    sx={{
                      border: "1px solid lightgrey",
                      borderRadius: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 0,
                      px: 2,
                      pt: 0.8,
                    }}
                  >
                    {state.auth.isAuthenticated ? (
                      user ? (
                        user.profile_picture ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ flex: 1 }}>
                              <MenuIcon />
                            </Box>
                            {user.profile_picture ?
                              <Box sx={{ flex: 1 }}>
                                <img
                                  width={30}
                                  height={30}
                                  style={{ borderRadius: 30 }}
                                  src={`${process.env.REACT_APP_BACKEND_URL.split("/api")[0]
                                    }${user.profile_picture}`}
                                />
                              </Box> : ""}
                          </Box>
                        ) : (
                          <AccountCircle sx={{ width: 35, height: 35 }} />
                        )
                      ) : (
                        <AccountCircle />
                      )
                    ) : (
                      <MoreIcon />
                    )}
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {['menu'].map((setting) => (
                    <MenuItem key={setting} onClick={handleMenuClose}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box> */}

            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <List>

          {links.map((text, index) => (
            text == "Divider" ? <Divider /> :
              <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                <Tooltip title={!open ? text : ""} placement='right'>
                  <ListItemButton
                    size="small"

                    onClick={() => {
                      if (text == "Admin") {
                        window.open(process.env.REACT_APP_BACKEND_URL.split("/api")[0] + "/admin/", "_blank")

                      } else if (text == "Logout") {
                        logout_user()
                        navigate("/login")
                      } else {
                        navigate("/dashboard/" + text.toLowerCase().replaceAll(" ", "-"))
                      }
                    }}
                    sx={{
                      height: 40,
                      justifyContent: open ? 'initial' : 'center',
                      m: 1,
                      borderRadius: 1,
                      bgcolor: location.pathname.includes(text.toLowerCase().replaceAll(" ", "-")) ? "rgba(234, 36, 86,0.1)" : ""
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {return_icon(text)}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0, }} />
                  </ListItemButton>
                </Tooltip>

              </ListItem>
          ))}
        </List>


      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, minHeight: "100vh", maxWidth:1920, m:"auto"}}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}




const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(AppSidebarDesktopVersion);