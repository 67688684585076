import React, { useEffect, useState } from 'react';
import { Box, Button, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import TaskActivity from './TaskActivity';
import UserTaskDetails from './UserTaskDetails';
import TaskSetting from './TaskSetting';
import EditAssignment from './EditAssignment'
import AddMultipleTasks from './AddMultipleTasks'
import Label from './Label';
const Taskdrawer = ({ user, toggleDrawer, setState, state }) => {
    
  var csrftoken = csrf_grabber('csrftoken');
  
  const [something, set_something] = useState(false);
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

  const navigate = useNavigate();

  const get_something = async () => {
    set_loading(true);
    const config = {
      params: {},
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': csrftoken,
      },
    };

    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`, config);
    if (res.data.status == 1) {
      set_loading(false);
      set_something(res.data.response);
    } else {
      set_loading(false);
      set_alert(res.data.response);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('access')) {
      navigate('/login');
    }
  }, []);

  const list = (anchor) => {
    if (anchor === 'right') {
      return (
        <Box
          sx={{ width: { xs: "100%", sm: "80%", md: '37vw' } }}
          role="presentation"
        >
            <TaskActivity toggleDrawer={toggleDrawer}/>
        </Box>
      );
    }
    else if (anchor === 'taskDetails') {
    return (
      <Box
      sx={{ width: { xs: "100%", sm: "80%", md: '37vw' } }}
      
        role="presentation"
      >

        <UserTaskDetails toggleDrawer={toggleDrawer} />
      </Box>
      
    );
  }
  else if (anchor === 'taskSetting') {
    return (
      <Box
        sx={{ width: '100vw ', height: { xs: "100vh%", sm: "85vh" } }}
        role="presentation"
      >

        <TaskSetting toggleDrawer={toggleDrawer} />
      </Box>
    );
  }
  else if (anchor === 'editAssignment') {
    return (
      <Box
        sx={{ width: '100vw ', height: { xs: "100vh%", sm: "85vh" } }}
        role="presentation"
      >

        <EditAssignment/>
      </Box>
    );
  }
  else if (anchor === 'addMultipleTasks') {
    return (
      <Box
        sx={{ width: '100vw ', height: { xs: "100vh%", sm: "85vh" } }}
        role="presentation"
      >
   <AddMultipleTasks  toggleDrawer={toggleDrawer} />

      </Box>
    );
  }
  else if (anchor === 'label') {
    return (
      <Box
        sx={{ width: { xs: "100%", sm: "80%", md: '37vw' } }}
        role="presentation"
      >
          <Label  toggleDrawer={toggleDrawer}/>
      </Box>
    );
  }
  
    

  };

  return (
    
    <Box>
      {['right', 'taskDetails', 'taskSetting','editAssignment','addMultipleTasks','label'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer sx={{ zIndex: 9923215 }}
            anchor={anchor === 'taskDetails' ? 'right' : anchor === 'label' ? 'right' : anchor === 'taskSetting' ? 'bottom' : anchor === 'editAssignment' ? 'bottom' : anchor === 'addMultipleTasks' ? 'bottom' : anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Taskdrawer);


