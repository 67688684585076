// https://blog.logrocket.com/how-to-build-chatroom-app-react-firebase/

import React, { useEffect, useState } from "react";
import { Alert, Box, Typography } from "@mui/material";

import { axios_get_call } from "../../../../components/AxiosCall";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "../../../../components/Container";
import ChatIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";

import ChatRoom from "./ChatRoom";
import ChatRooms from "./ChatRooms";

const ChatSetup = ({ user }) => {
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  const [chat_rooms, set_chat_rooms] = useState(false);
  const [chat_room, set_chat_room] = useState(false);

  const navigate = useNavigate();

  const get_chat_rooms = async () => {
    let url = "/saas/chat/get-chat-rooms/";
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      set_chat_rooms(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  useEffect(() => {
    get_chat_rooms();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("access")) {
      navigate("/login");
    }
  }, []);

  return user ? (
    <Box sx={{ minHeight: "500px", height: "78vh" }}>
      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity={alert.toLowerCase().includes("[error]") ? "error" : "info"}
          sx={{
            p: 2,
            mb: 2,
            width: 1,
            zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ""
      )}
      <Container>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ChatIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
          <Typography align="left" variant="h1" sx={{ fontWeight: "light" }}>
            Chat
          </Typography>
        </Box>
      </Container>
      <Box sx={{ my: 4 }}>
        {chat_rooms ? (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {/* chat rooms  */}
              {!chat_room && chat_room !== 0 && (
                <Box sx={{ flex: 1 }}>
                  <ChatRooms
                    chat_rooms={chat_rooms}
                    chat_room={chat_room}
                    set_chat_room={set_chat_room}
                  />
                </Box>
              )}
              {/* chat room  */}
              {(chat_room || chat_room === 0) && (
                <Box sx={{ flex: 3 }}>
                  <ChatRoom chat_rooms={chat_rooms} chat_room={chat_room} set_chat_room={set_chat_room} />
                </Box>
              )}
            </Box>
        ) : (
          "Loading"
        )}
      </Box>
    </Box>
  ) : (
    ""
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(ChatSetup);
