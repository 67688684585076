import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Divider, Tooltip, Typography } from '@mui/material'

import axios from 'axios';
import csrf_grabber from '../../../components/csrf_grabber';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Container from '../../../components/Container';
import LoadingScreen from '../../../components/LoadingScreen';

import ChatIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import TimeClockIcon from '@mui/icons-material/ScheduleOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';







import moment from 'moment'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { axios_get_call } from '../../../components/AxiosCall';

const EmployeeDashboard = ({ user }) => {
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  const [jobs, set_jobs] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])


  const get_jobs = async () => {
    let url = '/saas/job-scheduling/get-current-user-jobs/';
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      const all_jobs = res.data.response.map(job => ({
        ...job,
        start_datetime: moment(job.start_datetime),
        end_datetime: moment(job.end_datetime),
        start_time: moment(new Date(new Date(new Date(job.start_datetime).getFullYear(), new Date(job.start_datetime).getMonth(), new Date(job.start_datetime).getDate()).setHours(1, 0, 0))),
        end_time: moment(new Date(new Date(new Date(job.end_datetime).getFullYear(), new Date(job.end_datetime).getMonth(), new Date(job.end_datetime).getDate()).setHours(20, 3, 0)))
      }));
      set_jobs(all_jobs);

    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  }

  useEffect(() => {
    get_jobs()
  }, [])

  return (
    <Box >
      <LoadingScreen loading={loading} />

      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity='error'
          sx={{
            p: 2,
            width: 1,
            borderRadius: 2,
            zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ''
      )}

      <Typography variant='h1' sx={{ fontWeight: "light", mb: 2, mt: 1 }}>
        Welcome {user ? user.username : ""}
      </Typography>
      {/* Quick actions div  */}
      <Container>
        <Typography variant='subtitle2'>Quick Actions</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 4, mb: 1, flexWrap: "wrap", overflowX: "scroll" }}>
          {/* Action  1  */}
          <Box onClick={() => { navigate("/dashboard/chat") }} sx={{ flex: 1, m: 2, display: "flex", flexDirection: "column", minWidth: '200px', maxWidth: '300px', alignItems: "center", padding: '20px', bgcolor: "white", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)", cursor: "pointer", "&:hover": { border: "1px solid", borderColor: "primary.main", color: "primary.main" } }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 2, bgcolor: "#F6F6F6", p: 2, borderRadius: 3, height: '80px', width: '70px' }}>
              <ChatIcon sx={{ fontSize: 36, color: "grey" }} />
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Chat
              </Typography>
            </Box>
          </Box>
        
          {/* Action 3  */}
          <Box onClick={() => { navigate("/dashboard/time-clock") }} sx={{ flex: 1, m: 2, display: "flex", flexDirection: "column", minWidth: '200px', maxWidth: '300px', alignItems: "center", padding: '20px', bgcolor: "white", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)", cursor: "pointer", "&:hover": { border: "1px solid", borderColor: "primary.main", color: "primary.main" } }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 2, bgcolor: "#F6F6F6", p: 2, borderRadius: 3, height: '80px', width: '80px' }}>
              <TimeClockIcon sx={{ fontSize: 36, color: "grey" }} />

            </Box>
            <Box>
              <Typography variant="subtitle2">
                Time Clock
              </Typography>
            </Box>
          </Box>
          {/* Action 4  */}
          <Box onClick={() => { navigate("/dashboard/settings?tab_index=0") }} sx={{ flex: 1, m: 2, display: "flex", flexDirection: "column", minWidth: '200px', maxWidth: '300px', alignItems: "center", padding: '20px', bgcolor: "white", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)", cursor: "pointer", "&:hover": { border: "1px solid", borderColor: "primary.main", color: "primary.main" } }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 2, bgcolor: "#F6F6F6", p: 2, borderRadius: 3, height: '80px', width: '80px' }}>
              <SettingsIcon sx={{ fontSize: 36, color: "grey" }} />

            </Box>
            <Box>
              <Typography variant="subtitle2">
                Settings
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
      {/* Tasks  */}
      <br />
      <Container>
        <Typography variant='subtitle2'>Assigned Jobs</Typography>
        <br />
        <Box sx={{ minHeight: '300px', width: 1 }}>
          <Table fullWidth sx={{ bgcolor: "white", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)" }}>
            <TableHead>
              <TableRow>
                <TableCell><Typography fontWeight={"bold"}>Job</Typography></TableCell>
                <TableCell><Typography fontWeight={"bold"}>Deadline</Typography></TableCell>
                <TableCell><Typography fontWeight={"bold"}>Stage</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.length > 0 ? jobs.map((job) => (
                <Tooltip title="Double click to work">

                  <TableRow
                    key={job.job_id}
                    sx={{
                      ':nth-child(odd)': { bgcolor: "#F2F2F2", }, "&:hover": {
                        bgcolor: "#eaeaea",
                        cursor: "pointer"
                      }
                    }}
                    onDoubleClick={() => {
                      navigate("/dashboard/time-clock")
                    }}

                  >
                    <TableCell>
                      <Typography>{job.title}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography >{moment(job.end_datetime).format('MMM DD, YYYY (ddd)')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography >{job.job_stage}</Typography>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              )) :
                <Typography sx={{ p: 2 }} variant="subtitle2">No jobs found</Typography>
              }
            </TableBody>
          </Table>
        </Box>
      </Container>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(EmployeeDashboard);