import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import { Divider } from '@mui/material'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow } from '@mui/material'
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { axios_post_call } from '../../../../components/AxiosCall';

const registration_fields = [
  {
    id: 1,
    name: "Title/Designation",
    data_type: "text",
    value: ""
  }, 
  {
    id: 2,
    name: "First Name",
    data_type: "text",
    value: ""
  },
  {
    id: 3,
    name: "Last Name",
    data_type: "text",
    value: ""
  },
  {
    id: 4,
    name: "Mobile Number",
    data_type: "tel",
    value: ""
  },
  {
    id: 5,
    name: "Email",
    data_type: "email",
    value: ""
  }]

const AddUsers = ({ user, toggleDrawer }) => {



  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  const [user_registration_fields, set_user_registration_fields] = useState([registration_fields])

  const navigate = useNavigate()

  const create_member_accounts_in_bulk = async () => {
    let url = "/saas/user-management-system/create-member-accounts-in-bulk/"
    let body = {"user_registration_data": user_registration_fields}
    const res = await axios_post_call(url, body, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false)
      set_alert(res.data.response)
    } else {
      set_loading(false)
      set_alert(res.data.response)
    }
  }

  const handleInputChange = (event, row_index, item_index) => {
    const fields = [...user_registration_fields];
    fields[row_index][item_index]['value'] = event.target.value;
    set_user_registration_fields(fields);
  };


  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])
  return (
    <Box >

      <Box sx={{ width: 1, position: "fixed", display: 'flex', justifyContent:"space-between", alignItems: 'center', bgcolor: "white", boxShadow: "0px 0px 13px -2px rgba(0,0,0,0.1)" }}>
        <Box sx={{flex:1, ml:2}}>
          <Typography align='left' variant="h2">Register Members</Typography>
        </Box>
        <Box >
          <Box sx={{ cursor: "pointer" }}>
            <CloseIcon onClick={toggleDrawer("add_users_drawer", false)} sx={{ color: 'black', m: 2, mb: 1 }} />
          </Box>
        </Box>

      </Box>
      <Divider sx={{ height: 2, }} />
      <Box sx={{ mt: '70px' }}>


        <Box sx={{
          display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", my: 4,
        }}>
          <Box sx={{ width: { xs: "95%", sm: "90%", md: "70%", lg: "60%" } }}>
            <Box sx={{ width: 1, flex: 1 }}>
              <Typography align="left" variant="h3" fontWeight="bold">Add New Members</Typography>
              <Typography align="left" variant="subtitle2">
                Their passwords will be emailed to them.
              </Typography>
            </Box>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>


              <TableContainer sx={{ bgcolor: "white", boxShadow: "0px 0px 13px -2px rgba(0,0,0,0.1)", borderRadius: 3 }} >
                <Table aria-label="simple table">
                  <TableHead sx={{ backgroundColor: 'whitesmoke' }}>
                    <TableRow>
                      <TableCell align="center" sx={{ fontWeight: 'bold' }}>Title/Designation</TableCell>
                      <TableCell align='center' sx={{ fontWeight: 'bold' }}>First name</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold' }}>Last name</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold' }}>Phone number</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold' }}>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user_registration_fields.map((fields, row_index) => {
                      return (
                        <TableRow sx={{ border: 'none', boxShadow: 'none', borderColor: "white" }}>
                          {fields.map((field, item_index) => (
                            <TableCell align='center' >
                              <TextField
                                label={field.name == "Email" ? `${field.name}*` : field.name} type={field.data_type} variant='outlined' 
                                value={field.value}
                                inputMode={
                                  field.data_type == "text"
                                    ? "text"
                                    : field.data_type == "email"
                                      ? "email"
                                      : field.data_type == "tel"
                                        ? "tel"
                                        : "text"
                                }
                                onChange={(event) => handleInputChange(event, row_index, item_index)}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <Box >

                  <Divider sx={{ my: 2, display: "flex" }}>
                    <Box sx={{ mx: 2, flex: 1 }}>
                      <AddIcon onClick={() => {
                        let updated_number_of_user_registration_fields = JSON.parse(JSON.stringify(user_registration_fields))

                        updated_number_of_user_registration_fields.push(registration_fields)
                        set_user_registration_fields(updated_number_of_user_registration_fields)

                      }} sx={{ color: "red", cursor: "pointer", border: "1px solid red", borderRadius: 14, }} />
                    </Box>
                    <Box sx={{ mx: 2, flex: 1 }}>
                      <RemoveIcon onClick={() => {
                        if (user_registration_fields.length > 1) {
                          let updated_number_of_user_registration_fields = JSON.parse(JSON.stringify(user_registration_fields))
                          updated_number_of_user_registration_fields.splice(updated_number_of_user_registration_fields.length - 1, 1)
                          set_user_registration_fields(updated_number_of_user_registration_fields)
                        }
                      }} sx={{ color: "grey", cursor: "pointer", border: "1px solid grey", borderRadius: 14, }} />
                    </Box>

                  </Divider>
                </Box>
              </TableContainer>
              {alert ? (
                <Alert
                  onClose={() => {
                    set_alert(false);
                  }}
                  severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}

                  sx={{
                    p: 2,
                    mb: 2,
                    width: "99.5%",
                    borderRadius:2,
zIndex: 9923213,
                  }}
                >
                  {alert}
                </Alert>
              ) : (
                ''
              )}
            </Box>
            <Button variant='contained' fullWidth sx={{ mt: 2 }} onClick={() => { create_member_accounts_in_bulk() }}>{loading ? <CircularProgress color="secondary" /> : "Create accounts"}</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(AddUsers);