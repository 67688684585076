      import React, { useEffect, useState } from 'react'
      import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'

      import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow } from '@mui/material';            
      import axios from 'axios';
      import csrf_grabber from '../../../../components/csrf_grabber';
      import CustomModal from '../../../../components/CustomModal';
      import { connect } from 'react-redux';
      import { useNavigate } from 'react-router-dom';
      import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
      import EditIcon from '@mui/icons-material/Edit';
      import { Divider } from '@mui/material'  
import { display } from '@mui/system';
      const tableData = [
        {
            id: 1,
            dueDate: '1/2/23',
            assignedTo: '3/5/2023',
        }
    ]
              
      const TaskDetails = ({user, set_alert, set_loading}) => {
      
      var csrftoken = csrf_grabber('csrftoken');
        const [something, set_something] = useState(false);
      
        const navigate = useNavigate()
      
          const get_something = async () => {
            set_loading(true)
            const config = {
                params: { },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRFToken': csrftoken
                },
            };
    
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
                config
            );
            if (res.data.status == 1) {
                set_loading(false)
                set_something(res.data.response);
            } else {
                set_loading(false)
                set_alert(res.data.response)
            }
        }
        useEffect(() => {
          if (!(localStorage.getItem('access'))) {
            navigate('/login')
          }
        }, [])
        return (
          <Box sx={{width:'90%'}}>
            <Typography>Example Task : First Task</Typography>
            <Typography sx={{marginTop:'20px',marginBottom:'20px'}} > You can add your task here</Typography>
          <Divider sx={{ height: 2}} />
          <Typography sx={{marginTop:'20px',marginBottom:'20px'}}>Assigned to Admin</Typography>
          <Divider sx={{ height: 2}} />
          <Box sx={{height:'10vh',width:'15vw'  ,marginTop:'20px', marginBottom:'20px', display:'flex', justifyContent:'space-between'}}>
            <Box>
              <Typography>Frequency</Typography>
              <Typography>Start Date</Typography>
              <Typography>Due Date</Typography>
            </Box>
            <Box>
              <Typography>on off task</Typography>
              <Typography>1/2/23</Typography>
              <Typography>1/2/23</Typography>
            </Box>
          </Box>
          <Divider sx={{ height: 2}} />
          <Typography sx={{marginTop:'20px', marginBottom:'20px'}} >Labels</Typography>
          <Divider sx={{ heixght: 2}} />
          <Box sx={{height:'12vh'}}></Box>
          <Divider sx={{ height: 2}} />
          <Box sx={{marginTop:'10px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <Box>
            <Button sx={{marginRight:'4px'}} variant="contained" >Mark Task as done</Button>
            <Button variant="outlined" ><EditIcon/>  Edit</Button>
            </Box>
             <Box>
                <Button><DeleteOutlineIcon/></Button>
            </Box>
          </Box>
          </Box>
        )
      }
      
      const mapStateToProps = (state) => ({
        user: state.auth.user,
      });
      
      export default connect(mapStateToProps, {})(TaskDetails);