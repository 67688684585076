import { Button, TextField, Typography, Alert } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import LoadingScreen from '../../../../components/LoadingScreen';
import { axios_post_call } from '../../../../components/AxiosCall';

import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close'; 

import { connect } from 'react-redux';
import { initializeApp } from "firebase/app";
import {
	getFirestore,
	collection,
	addDoc,
	serverTimestamp,
	onSnapshot,
	query,
	orderBy,
} from 'firebase/firestore';

const firebaseConfig = {
	apiKey: "AIzaSyCTqMspfJra9533qnpQFKAHllQQJX4KIpA",
	authDomain: "custom-cmr.firebaseapp.com",
	projectId: "custom-cmr",
	storageBucket: "custom-cmr.appspot.com",
	messagingSenderId: "598908153730",
	appId: "1:598908153730:web:3c98c17f7c547840f5299b"
};
const firebase = initializeApp(firebaseConfig);
const database = getFirestore(firebase);

function RenderMessages({ uid, messages, user }) {

	function time_ago(timestamp) {
		if (timestamp) {
			const currentTimestamp = Math.floor(Date.now() / 1000);
			const seconds = currentTimestamp - timestamp;

			// Define time intervals in seconds
			const minute = 60;
			const hour = 60 * minute;
			const day = 24 * hour;
			const week = 7 * day;
			const month = 30 * day;
			const year = 365 * day;

			if (seconds < week) {
				const date = new Date(timestamp * 1000);
				const today = new Date();
				const isSameDay = date.toDateString() === today.toDateString();

				const isSameWeek =
					date.getWeek() === today.getWeek() && date.getYear() === today.getYear();


				if (isSameDay) {
					const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
					return `Today ${timeString}`;
				} else if (isSameWeek) {
					const dayName = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date);
					const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

					return `${dayName} ${timeString}`;
				} else {
					const day = date.getDate();
					const month = date.getMonth() + 1;
					const year = date.getFullYear().toString().slice(2);
					return `${day}/${month}/${year}`;
				}
			} else if (seconds < month) {
				const days = Math.floor(seconds / day);
				return days + (days === 1 ? ' day ago' : ' days ago');
			} else if (seconds < year) {
				const months = Math.floor(seconds / month);
				return months + (months === 1 ? ' month ago' : ' months ago');
			} else {
				const years = Math.floor(seconds / year);
				return years + (years === 1 ? ' year ago' : ' years ago');
			}
		}
	}

	// Helper function to get the week number
	Date.prototype.getWeek = function () {
		var date = new Date(this.getTime());
		date.setHours(0, 0, 0, 0);
		date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
		var week1 = new Date(date.getFullYear(), 0, 4);
		return 1 + Math.round(((date - week1) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
	};


	return (
		<Box >
			{messages.length > 0 ? Object.keys(messages).map((message_key) => {
				return (
					<Box key={messages[message_key].uid} sx={{
						maxWidth: '300px',
						borderRadius: '5px',
						my: 1,
						px: 2,
						py: 1,
						bgcolor: messages[message_key].uid == uid ? "primary.main" : "secondary.main",
						ml: messages[message_key].uid == uid ? "auto" : "inherit",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "flex-end"
					}}>
						<Typography sx={{ color: "white" }} variant='subtitle2'>{messages[message_key].message}

						</Typography>
						<Typography sx={{ color: "#f2f2f2", fontSize: 12, whiteSpace: "noWrap" }} align="right" variant='span'>{messages[message_key].timestamp && time_ago(messages[message_key].timestamp.seconds)}</Typography>

					</Box>

				)
			}
			)
			:
			<Typography align="center" mt={2} variant='subtitle2'>Use the input box below to send a message</Typography>
			}

		</Box>
	)
}
function ChatRoom({ chat_rooms, chat_room, set_chat_room, user }) {
	const chatContainerRef = useRef(null);
	const [message, set_message] = useState("")
	const [messages, set_messages] = useState(false)
	const [room_id, set_room_id] = useState(false)
	const [loading, set_loading] = useState(false)
	const [alert, set_alert] = useState(false)

	async function send_message_to_the_chat() {
		try {
			let messages_state = JSON.parse(JSON.stringify(messages))
			messages_state.push({
				uid: user.eid,
				message: message.trim(),
				timestamp: Math.floor(new Date().getTime() / 1000),
			})
			set_messages(messages_state)

			await addDoc(collection(database, "chat-rooms", room_id, "messages"), {
				uid: user.eid,
				message: message.trim(),
				timestamp: serverTimestamp(),
			});


		} catch (error) {
			console.error(error);
		}
	}


	function get_chats_from_the_chat_room() {
		return onSnapshot(
			query(
				collection(database, "chat-rooms", room_id, "messages"),
				orderBy('timestamp', 'asc')
			),
			(querySnapshot) => {
				const messages = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				set_messages(messages);
			}
		);

	}


	const get_or_create_chat_room_id = async () => {
		let url = '/saas/chat/get-or-create-chat-room-id/';
		let res = await axios_post_call(url, { "employee_id": chat_rooms[chat_room].uid }, set_loading, set_alert);

		if (res.data.status == 1) {
			set_loading(false);
			set_room_id(res.data.response);

		} else if (res.data.status == 0) {
			set_alert(res.data.response);
			set_loading(false);
		}
	}



	function scrollToLastMessage() {
		const chatContainer = chatContainerRef.current;
		// Set the scroll position to the height of the container, scrolling to the last message
		if (chatContainer) {
			setTimeout(() => {
				if (chatContainer) {
					console.log(chatContainer);
					console.log(chatContainer.scrollHeight);
					chatContainer.scrollBy(0, chatContainer.scrollHeight);
				}
			}, 1000);

		}
	}

	useEffect(() => {
		if ((chat_room === 0 || chat_room) && chat_rooms) {
			get_or_create_chat_room_id()
		}
	}, [chat_rooms, chat_room])

	useEffect(() => {
		if (room_id) {
			get_chats_from_the_chat_room()
		}
	}, [room_id])


	useEffect(() => {
		scrollToLastMessage()
	}, [messages])

	return (
		user &&
			chat_room || chat_room === 0 ?
			<Box sx={{ mb:10,  border: "1px solid #EAEAEA", width: 1, borderRadius: 3, display: "flex", flexDirection: "column", justifyContent: "space-between", }}>
				{alert ? (
					<Alert
						onClose={() => {
							set_alert(false);
						}}
						severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
						sx={{
							p: 2,
							mb: 2,
							width: 1,
							borderRadius: 2,
							zIndex: 9923213,
						}}
					>
						{alert}
					</Alert>
				) : (
					''
				)}
				<Box sx={{ flex: 1 }}>
					<LoadingScreen loading={loading} />

					<Box sx={{
						p: 2,
						borderBottom: "1px solid #EAEAEA", cursor: "pointer",
						display:'flex', alignItems:'center'
					}}>
						<CloseIcon onClick={()=>{set_chat_room(false)}} sx={{fontSize:35, p:0.2, borderRadius:15, mr:2, "&:hover":{bgcolor:"#efeaea", cursor:"pointer"}}} /> <Typography align="left" variant='h1' sx={{ fontWeight: "bold" }}>{chat_rooms[chat_room].name}</Typography>
					</Box>
				</Box>
				
				<Box sx={{}}>

					<Box ref={chatContainerRef} sx={{ flex: 3, borderRadius: 3, height: 1,  height: '60vh', overflowY: "scroll" }}>
						{room_id == 0 || room_id ?
							<Box sx={{ px: 4 }}>

								<RenderMessages uid={user.eid} messages={messages} user={user} />
							</Box>
							:
							<Typography p={4} variant="subtitle2" align="center">
								Loading the chat
							</Typography>
						}
					</Box>

					<Box component="form"

						onSubmit={(e) => {
							e.preventDefault();
							send_message_to_the_chat();
							set_message("")
						}}>
						<Box sx={{ px: 4, py: 2, display: "flex", alignItems: "center", bgcolor: "#FAFAFA", borderRadius: "0px 0px 9px 9px" }}>
							<Box sx={{ flex: 1 }}>
								<TextField 
								multiline 
								focused 
								size="small"
								fullWidth
								variant='outlined'
								value={message}
								placeholder="Enter to send message, shift + enter to add new line"
								onChange={(e) => { set_message(e.target.value) }} 
								onKeyPress={(event)=>{
									if (event.key === "Enter" && !event.shiftKey) {
										event.preventDefault(); // Prevent form submission on Enter press
										if (event.ctrlKey) {
										  // Ctrl+Enter: Add new line
										  set_message((prevMessage) => prevMessage + "\n");
										} else {
										  // Enter: Send message
										  send_message_to_the_chat();
										  set_message(""); // Clear the message after submission
										}
									  }
								}} 

								/>
							</Box>
							<Box sx={{ ml: 2 }}>
								<Button disabled={message.length == 0} type='submit' variant='contained' sx={{ minWidth: "0px !important", width: "50px", height: "50px", borderRadius: 15, }}>
									<SendIcon sx={{ color: "white" }} />
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>

			</Box>
			:

			<Box sx={{ border: "1px solid #EAEAEA", width: 1, minHeight: '400px', maxHeight: '95%', height: '70vh', borderRadius: 3, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

				<Typography variant="subtitle2" align="left">No chat selected</Typography>
			</Box>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, {})(ChatRoom);



