import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import { logout } from "../../actions/auth";

// Icons
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";

import OverviewIcon from "@mui/icons-material/GridViewOutlined";
import ActivityIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import MembersIcon from "@mui/icons-material/PersonOutline";
// import TeamsIcon from '@mui/icons-material/PeopleAltOutlined';
import TeamsIcon from "@mui/icons-material/Groups2Outlined";

import ChatIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import JobsIcon from "@mui/icons-material/WorkOutline";
import ProjectsIcon from "@mui/icons-material/WorkHistoryOutlined";
import TimeClockIcon from "@mui/icons-material/ScheduleOutlined";
import TasksIcon from "@mui/icons-material/HorizontalSplitOutlined";
import FormsIcon from "@mui/icons-material/DocumentScannerOutlined";
import FormEntriesIcon from "@mui/icons-material/Notes";

import AdminIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ClientsIcon from "@mui/icons-material/HandshakeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem, Tooltip } from "@mui/material";
import { connect } from "react-redux";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppSidebarMobileVersion = ({ children, logout, state, user }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [links, set_links] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout_user = () => {
    logout();
  };
  const handle_shortcuts = () => {
    if (JSON.parse(window.localStorage.getItem("sidebar_opened")) == open)
      window.localStorage.setItem("sidebar_opened", !open);
    else {
      window.localStorage.setItem("sidebar_opened", open);
    }
    setOpen(!JSON.parse(window.localStorage.getItem("sidebar_opened")));
  };
  React.useEffect(() => {
    // Function to be executed when Ctrl+B is pressed
    function handleCtrlB(e) {
      const ctrlKey = e.ctrlKey || e.metaKey; // For Mac users
      // Check if the "Space" key is pressed
      const isSpaceKey = e.key === " " || e.keyCode === 32;

      if (ctrlKey && isSpaceKey) {
        e.preventDefault(); // Prevent the default browser behavior (e.g., opening bookmarks)

        // Call your custom function here
        handle_shortcuts();
      }
    }

    // Attach the event listener when the component mounts
    window.addEventListener("keydown", handleCtrlB);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleCtrlB);
    };
  }, []);

  const menuId = "primary-search-account-menu";
  const AllLinks = () => (
    <React.Fragment>
      <Link to={"/dashboard"} style={{ textDecoration: "none" }}>
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={handleMenuClose}
        >
          View my public page
        </MenuItem>
      </Link>
      <Link to="/dashboard" style={{ textDecoration: "none" }}>
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={handleMenuClose}
        >
          Dashboard
        </MenuItem>
      </Link>

      <Divider />
      {user ? (
        user.is_superuser ? (
          <Link
            onClick={() =>
              window.open(
                `${
                  process.env.REACT_APP_BACKEND_URL.split("/api")[0]
                }/theoffice/`,
                "_blank"
              )
            }
            style={{ textDecoration: "none" }}
          >
            <MenuItem sx={{ color: "black", fontWeight: 500 }}>
              Admin panel
            </MenuItem>
          </Link>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <Divider />
      <Link to={"/dashboard"} style={{ textDecoration: "none" }}>
        <MenuItem
          sx={{ color: "black", fontWeight: 500 }}
          onClick={logout_user}
        >
          Logout
        </MenuItem>
      </Link>
    </React.Fragment>
  );
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ mt: "48px", color: "#F9F9F9" }}
    >
      <allLinks />

      <Divider />
      <Box
        sx={{
          p: 2,
        }}
      >
        {state.auth.user ? (
          <Typography sx={{ color: "lightgrey" }}>
            {" "}
            {state.auth.user.email}{" "}
          </Typography>
        ) : (
          ""
        )}
      </Box>
    </Menu>
  );

  React.useEffect(() => {
    if (user) {
      if (user.is_superuser) {
        set_links([
          "Overview",
          "Members",
          "Teams",
          "Chat",
          "Clients",
          "Job Scheduling",
          "Projects",
          "Time Clock",
          "Tasks",
          "Forms",
          "Form Entries",
          "Settings",
          "Admin",
          "Logout",
        ]);
      } else {
        set_links(["Overview", "Chat", "Time Clock", "Settings", "Logout"]);
      }
    }
  }, [user]);
  React.useEffect(() => {
    if (window.localStorage.getItem("sidebar_opened") != null) {
      setOpen(JSON.parse(window.localStorage.getItem("sidebar_opened")));
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
    window.localStorage.setItem("sidebar_opened", true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    window.localStorage.setItem("sidebar_opened", false);
  };

  const return_icon = (text) => {
    switch (text) {
      case "Overview":
        return <OverviewIcon sx={{ fontSize:18, color: "white" }} />;
        break;

      case "Activity":
        return <ActivityIcon sx={{ fontSize:18, color: "white" }} />;
        break;
      case "Members":
        return <MembersIcon sx={{ fontSize:18, color: "white" }} />;
        break;

      case "Teams":
        return <TeamsIcon sx={{ fontSize:18, color: "white" }} />;
        break;

      case "Chat":
        return <ChatIcon sx={{ fontSize:18, color: "white" }} />;
        break;

      case "Job Scheduling":
        return <JobsIcon sx={{ fontSize:18, color: "white" }} />;
        break;
      case "Projects":
        return <ProjectsIcon sx={{ fontSize:18, color: "white" }} />;
        break;
      case "Time Clock":
        return <TimeClockIcon sx={{ fontSize:18, color: "white" }} />;
        break;
      case "Tasks":
        return <TasksIcon sx={{ fontSize:18, color: "white" }} />;
        break;
      case "Forms":
        return <FormsIcon sx={{ fontSize:18, color: "white" }} />;
        break;
      case "Form Entries":
        return <FormEntriesIcon sx={{ fontSize:18, color: "white" }} />;
        break;
      case "Clients":
        return <ClientsIcon sx={{ fontSize:18, color: "white" }} />;
        break;
      case "Settings":
        return <SettingsIcon sx={{ fontSize:18, color: "white" }} />;
        break;
      case "Logout":
        return <LogoutIcon sx={{ fontSize:18, color: "white" }} />;
        break;

      default:
        return <ProjectsIcon sx={{ fontSize:18, color: "white" }} />;
        break;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Box sx={{ mt: 2 }}></Box>
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
      <Box sx={{ mt: 10 }}></Box>
      <Toolbar
        sx={{
          position: "fixed",
          bottom: 0,
          width: 1,
          borderTop: "1px solid #e0e0e0",
          backgroundColor: "primary.main",
          zIndex: 999999999,
          overflowX:'scroll'
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent:"space-between" }}>
          {links.map((text, index) => (
            <Box
              sx={{ flex: 1, p:1,   m:0.25, minWidth:80, display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center", "&:hover":{ bgcolor: "primary.light"} }}
              onClick={() => {
                if (text === "Admin") {
                  window.open(
                    process.env.REACT_APP_BACKEND_URL.split("/api")[0] +
                      "/admin/",
                    "_blank"
                  );
                } else if (text === "Logout") {
                  logout_user();
                  navigate("/login");
                } else {
                  navigate(
                    "/dashboard/" + text.toLowerCase().replaceAll(" ", "-")
                  );
                }
              }}
            >
              <Box>
              {return_icon(text)}
                <Divider orientation="vertical" flexItem />
                </Box>
              <Box>

                <Typography sx={{fontSize:12, color:"white", whiteSpace:"nowrap"}} align="center" variant="subtitle2">{text}</Typography>
                </Box>
              
            </Box>
          ))}
        </Box>
      </Toolbar>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(AppSidebarMobileVersion);
