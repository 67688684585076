import React, { useEffect, useState } from 'react'
import { Alert, Box, Typography } from '@mui/material'

import { axios_get_call } from '../../../../components/AxiosCall';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Container from '../../../../components/Container';

const AllJobsStageWise = ({ user }) => {

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [all_jobs_stats, set_all_jobs_stats] = useState(false);

    const navigate = useNavigate()

    const get_all_jobs_stats = async () => {
        let url = '/saas/job-scheduling/get-all-jobs-stats/';
        let res = await axios_get_call(url, {}, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_all_jobs_stats(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }
    function make_title(str) {
        // Split the string into an array of words using underscores as the separator
        const words = str.split('_');

        // Capitalize the starting letter of each word and join them with spaces
        const result = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return result;
    }


    useEffect(() => {
        get_all_jobs_stats()
    }, [])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])

    return (
        user ? <Box>

            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                    sx={{
                        p: 2,
                        mb: 2,
                        width: 1,
                        borderRadius:2,
zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}
            <Container>
            <Typography variant='h2' align='left' mb={2}>Total Jobs: {all_jobs_stats.total_jobs}</Typography>
                <Box sx={{minHeight:'300px'}}>
                    {all_jobs_stats.all_jobs_stage_wise && Object.keys(all_jobs_stats.all_jobs_stage_wise).map((key, index) => {
                        return (
                            <Box sx={{ display: "flex", justifyContent: "space-between", bgcolor: "pink",  p: 1, bgcolor: index % 2 == 0 ? "#F6F6F6" : "initial" }}>
                                <Typography variant='h3'>{make_title(key)}: </Typography>
                                <Typography variant='h3' style={{ fontWeight: 400 }}>{all_jobs_stats.all_jobs_stage_wise[key]}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Container>
        </Box> : ''
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(AllJobsStageWise);