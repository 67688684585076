import React, { useEffect, useState } from 'react'
import { FormControl, Alert, Box, Button, CircularProgress,Paper,  Typography } from '@mui/material'
import { Divider } from '@mui/material'
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import CustomModal from '../../../../components/CustomModal';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow}  from'@mui/material'
import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';;






const tableData=[
  {
   id:1,
   firstName:<TextField  label='First name' type='text' autoComplete='tel' variant='outlined' />,
   lastName:<TextField  label='Last name' type='text' autoComplete='tel' variant='outlined' />,
   mobile:<TextField label='Phone no' type='tel' autoComplete='tel' variant='outlined' />,
   email:<TextField label='Email' type='email' autoComplete='email' variant='outlined' />,
   assignedGroups:<FormControl sx={{width:'8vw'}}>
      
   <NativeSelect
     defaultValue={30}
     inputProps={{
       name: 'age',
       id: 'uncontrolled-native',
     }}
   >
     <option value={10}>Ten</option>
     <option value={20}>Twenty</option>
     <option value={30}>Thirty</option>
   </NativeSelect>
 </FormControl> ,
 assignedFeatures:<FormControl sx={{width:'8vw'}}>
      
 <NativeSelect
   defaultValue={30}
   inputProps={{
     name: 'age',
     id: 'uncontrolled-native',
   }}
 >
   <option value={10}>Ten</option>
   <option value={20}>Twenty</option>
   <option value={30}>Thirty</option>
 </NativeSelect>
</FormControl> ,
   
  }
  ,
  {
   id:2,
   
   firstName:<TextField  label='First name' type='text' autoComplete='tel' variant='outlined' />,
   lastName:<TextField  label='Last name' type='text' autoComplete='tel' variant='outlined' />,
   mobile:<TextField label='Phone no' type='tel' autoComplete='tel' variant='outlined' />,
   email:<TextField label='Email' type='email' autoComplete='email' variant='outlined' />,
   assignedGroups:<FormControl sx={{width:'8vw'}}>
      
   <NativeSelect
     defaultValue={30}
     inputProps={{
       name: 'age',
       id: 'uncontrolled-native',
     }}
   >
     <option value={10}>Ten</option>
     <option value={20}>Twenty</option>
     <option value={30}>Thirty</option>
   </NativeSelect>
 </FormControl> ,
 assignedFeatures:<FormControl sx={{width:'8vw'}}>
      
 <NativeSelect
   defaultValue={30}
   inputProps={{
     name: 'age',
     id: 'uncontrolled-native',
   }}
 >
   <option value={10}>Ten</option>
   <option value={20}>Twenty</option>
   <option value={30}>Thirty</option>
 </NativeSelect>
</FormControl> ,
  }
  ,
  {
   id:3,
   
   firstName:<TextField  label='First name' type='text' autoComplete='tel' variant='outlined' />,
   lastName:<TextField  label='Last name' type='text' autoComplete='tel' variant='outlined' />,
   mobile:<TextField label='Phone no' type='tel' autoComplete='tel' variant='outlined' />,
   email:<TextField label='Email' type='email' autoComplete='email' variant='outlined' />,
   assignedGroups:<FormControl sx={{width:'8vw'}}>
      
   <NativeSelect
     defaultValue={30}
     inputProps={{
       name: 'age',
       id: 'uncontrolled-native',
     }}
   >
     <option value={10}>Ten</option>
     <option value={20}>Twenty</option>
     <option value={30}>Thirty</option>
   </NativeSelect>
 </FormControl> ,
 assignedFeatures:<FormControl sx={{width:'8vw'}}>
      
 <NativeSelect
   defaultValue={30}
   inputProps={{
     name: 'age',
     id: 'uncontrolled-native',
   }}
 >
   <option value={10}>Ten</option>
   <option value={20}>Twenty</option>
   <option value={30}>Thirty</option>
 </NativeSelect>
</FormControl> ,
  }
  ,
  {
    id:4,
    
   firstName:<TextField  label='First name' type='text' autoComplete='tel' variant='outlined' />,
   lastName:<TextField  label='Last name' type='text' autoComplete='tel' variant='outlined' />,
   mobile:<TextField label='Phone no' type='tel' autoComplete='tel' variant='outlined' />,
   email:<TextField label='Email' type='email' autoComplete='email' variant='outlined' />,
   assignedGroups:<FormControl sx={{width:'8vw'}}>
      
   <NativeSelect
     defaultValue={30}
     inputProps={{
       name: 'age',
       id: 'uncontrolled-native',
     }}
   >
     <option value={10}>Ten</option>
     <option value={20}>Twenty</option>
     <option value={30}>Thirty</option>
   </NativeSelect>
 </FormControl> ,
 assignedFeatures:<FormControl sx={{width:'8vw'}}>
      
 <NativeSelect
   defaultValue={30}
   inputProps={{
     name: 'age',
     id: 'uncontrolled-native',
   }}
 >
   <option value={10}>Ten</option>
   <option value={20}>Twenty</option>
   <option value={30}>Thirty</option>
 </NativeSelect>
</FormControl> ,
  }
]
const Addadmins = ({user}) => {

var csrftoken = csrf_grabber('csrftoken');
  
  const [something, set_something] = useState(false);
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

  const navigate = useNavigate()

    const get_something = async () => {
      set_loading(true)
      const config = {
          params: { },
          headers: {
              Authorization: `Bearer ${localStorage.getItem('access')}`,
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'X-CSRFToken': csrftoken
          },
      };

      const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
          config
      );
      if (res.data.status == 1) {
          set_loading(false)
          set_something(res.data.response);
      } else {
          set_loading(false)
          set_alert(res.data.response)
      }
  }
  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])
  return (
    <Box >
      
      <Box sx={{display:'flex',justifyContent:'flex-end',height:'10vh' }}>
          <Box sx={{width:'70%', display:'flex',justifyContent:'space-around', marginTop:'15px' , alignItems:'flex-start' }}  >
              <Typography>Add Admins</Typography>
              <Typography sx={{display:'flex', alignItems:'center' }} > <HelpOutlineSharpIcon sx={{height:'3vh',marginRight:'10px'}} /> Needhelp?</Typography>
          </Box>
      </Box>
      <Divider sx={{ height: 2}} />
      
      <Box>

      <Box>
      <Box  sx={{display:'flex', justifyContent:'center', flexDirection:'column',alignItems:'center'}}>
          <Box sx={{my:'30px'}} >
          <Typography>Add more admins to your launch pad</Typography>
          <Typography>Only admins can login to the Launch Pad using a desktop or a laptop.
</Typography>
          </Box>
      
    <TableContainer component={Paper} >
  
<Table sx={{  minHeight:'60vh', }} aria-label="simple table">
  <TableHead sx={{backgroundColor:'whitesmoke'}}>
    <TableRow>
      <TableCell align='center' sx={{fontWeight:'bold'}}>First name</TableCell>
      <TableCell align="center" sx={{fontWeight:'bold'}} >Last name</TableCell>
      <TableCell align="center" sx={{fontWeight:'bold'}} >Mobile number</TableCell>
      <TableCell align="center" sx={{fontWeight:'bold'}} >Email</TableCell>
      <TableCell align="center" sx={{fontWeight:'bold'}} >Assigned groups</TableCell>
      <TableCell align="center" sx={{fontWeight:'bold'}} >Assigned features</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
   {
      tableData.map(row =>(
          <TableRow key={row.id} sx={{'&last-child td,&:last-child th':{border:0}}}>
           <TableCell align='center'>{row.firstName}</TableCell>
           <TableCell align='center'>{row.lastName}</TableCell>
           <TableCell align='center'>{row.mobile}</TableCell>
           <TableCell align='center'>{row.email}</TableCell>
           <TableCell align='center'>{row.assignedGroups}</TableCell>
           <TableCell align='center'>{row.assignedFeatures}</TableCell>
          </TableRow>  

      ))
   }
   
  </TableBody>
</Table>
</TableContainer>
      </Box>
    </Box>
    </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Addadmins);