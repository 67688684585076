import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'

import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import JobScheduleCalendar from './JobScheduleCalendar';
import Container from '../../../../components/Container';

import JobScheduleCalendarIcon from '@mui/icons-material/CalendarMonth';
import LoadingScreen from '../../../../components/LoadingScreen';
import moment from 'moment';

const JobScheduling = ({ user }) => {

  var csrftoken = csrf_grabber('csrftoken');
  const [something, set_something] = useState(false);
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

  const navigate = useNavigate()
  const location = useLocation()

  const get_something = async () => {
    set_loading(true)
    const config = {
      params: {},
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': csrftoken
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
      config
    );
    if (res.data.status == 1) {
      set_loading(false)
      set_something(res.data.response);
    } else {
      set_loading(false)
      set_alert(res.data.response)
    }
  }
  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])
  return (
    <Box >
      <LoadingScreen loading={loading} />
      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity='error'
          sx={{
            p: 2,
            width: 1,
            borderRadius:2,
zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ''
      )}
      <Box>
        <Box sx={{ my: 2, width: '98.1%' }}>
          <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:"space-between",  }}>
              <Box sx={{display:"flex", alignItems:"center"}}>
                <JobScheduleCalendarIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
                <Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
                  Job Scheduling
                </Typography>
              </Box>
              <Box>
                <Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
                  {moment(new Date()).format("MMM DD, YYYY")}
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
        {/* Calendar */}
        <Box>
          <JobScheduleCalendar />
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(JobScheduling);