import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import axios from "axios";
import csrf_grabber from "../../../../components/csrf_grabber";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../../../../components/Container";
import TimeClockIcon from "@mui/icons-material/ScheduleOutlined";
import { axios_get_call } from "../../../../components/AxiosCall";
import UserTimeClock from "./UserTimeClock";

const TimeClock = ({ user, alert, set_alert, loading, set_loading }) => {
  const [
    employees_hourly_time_clock_activity_today,
    set_employees_hourly_time_clock_activity_today,
  ] = useState(false);
  const [edit_timezone, set_edit_timezone] = useState(false);
  const [timezone, set_timezone] = useState("");
  const [edited_timezone, set_edited_timezone] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const get_employees_hourly_time_clock_activity_today = async () => {
    let url =
      "/saas/job-scheduling/get-employees-hourly-time-clock-activity-today/";
    let res = await axios_get_call(
      url,
      { user_timezone: timezone },
      set_loading,
      set_alert
    );

    if (res.data.status == 1) {
      set_employees_hourly_time_clock_activity_today(res.data.response);
      set_loading(false);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };
  useEffect(() => {
    if (!localStorage.getItem("access")) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (timezone) get_employees_hourly_time_clock_activity_today();
  }, [timezone]);

  useEffect(() => {
    const getUserTimezone = () => {
      let current_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      set_timezone(current_timezone);
    };
    getUserTimezone();
  }, []);
  return (
    employees_hourly_time_clock_activity_today && (
      <Box>
        <Container>
          <Box>
            {edit_timezone ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  size="small"
                  placeholder={timezone}
                  variant="outlined"
                  value={edited_timezone}
                  onChange={(e) => {
                    set_edited_timezone(e.target.value);
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    if (edited_timezone != "") set_timezone(edited_timezone);
                    set_edit_timezone(false);
                  }}
                  sx={{
                    minWidth: "0px",
                    width: "50px",
                    height: "50px",
                    padding: 2,
                    borderRadius: 12,
                  }}
                >
                  ✔
                </Button>
              </Box>
            ) : (
              <Tooltip
                title="Double click to change timezone"
                placement="top-start"
              >
                <Typography
                  onDoubleClick={() => {
                    set_edit_timezone(true);
                  }}
                  variant="subtitle1"
                  sx={{ cursor: "pointer" }}
                >
                  {timezone} Timezone
                </Typography>
              </Tooltip>
            )}
          </Box>
          <Box mb={4}>
            <Typography variant="subtitle2">
              {
                employees_hourly_time_clock_activity_today.total_clocked_in_members
              }
              /{employees_hourly_time_clock_activity_today.total_members}{" "}
              members clocked in today
            </Typography>
          </Box>
        </Container>

        <Box my={4}>
          <Container>
            <Box
              sx={{
                maxHeight: 500,
                overflowY: "scroll",
              }}
            >
              {Object.keys(
                employees_hourly_time_clock_activity_today.employees_hourly_entries
              ).map((key, index) => {
                return (
                  <Box key={index} sx={{ my: 1, mb: 5 }}>
                    <Typography variant="subtitle2">{key} </Typography>
                    <Box sx={{ maxHeight: 300, overflowY: "scroll" }}>
                      {employees_hourly_time_clock_activity_today
                        .employees_hourly_entries[key].length > 0 ? (
                        employees_hourly_time_clock_activity_today.employees_hourly_entries[
                          key
                        ].map((entry, index) => {
                          const startDatetime = new Date(entry.start_datetime);
                          const endDatetime = new Date(entry.end_datetime);

                          const startDateString =
                            startDatetime.toLocaleDateString();
                          const startTimeString =
                            startDatetime.toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            });
                          const endTimeString = endDatetime.toLocaleTimeString(
                            [],
                            { hour: "2-digit", minute: "2-digit" }
                          );

                          let timeDifference;
                          if (
                            startDatetime.toDateString() ===
                            endDatetime.toDateString()
                          ) {
                            timeDifference = `${startTimeString} - ${endTimeString}`;
                          } else {
                            timeDifference = `${startDateString}, ${startTimeString} - ${endDatetime.toLocaleString()}`;
                          }

                          const timeDifferenceInMilliseconds =
                            endDatetime - startDatetime;
                          const totalHours = Math.floor(
                            timeDifferenceInMilliseconds / (1000 * 60 * 60)
                          ); // Convert milliseconds to hours
                          const totalMinutes = Math.floor(
                            (timeDifferenceInMilliseconds % (1000 * 60 * 60)) /
                              (1000 * 60)
                          );
                          const totalSeconds = Math.floor(
                            (timeDifferenceInMilliseconds % (1000 * 60)) / 1000
                          );

                          const formattedTotalTime = `${totalHours
                            .toString()
                            .padStart(2, "0")}:${totalMinutes
                            .toString()
                            .padStart(2, "0")}:${totalSeconds
                            .toString()
                            .padStart(2, "0")} hrs`;

                          return (
                            <Box
                              sx={{
                                ml: 4,
                                mb: 2,
                                p: 1,
                                borderLeft: "4px solid",
                                borderBottom: "1px solid",
                                borderColor: "primary.light",
                              }}
                            >
                              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ whiteSpace: "nowrap" }}
                                >
                                  {startTimeString}{" "}
                                  {startDateString !==
                                    endDatetime.toLocaleDateString() &&
                                    startDateString}
                                </Typography>
                                &nbsp;-&nbsp;
                                <Typography
                                  variant="subtitle2"
                                  sx={{ whiteSpace: "nowrap" }}
                                >
                                  {endTimeString}
                                </Typography>
                                &nbsp;
                                <Typography variant="subtitle2">
                                  ({formattedTotalTime})
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })
                      ) : (
                        <Box
                          sx={{
                            m: 1,
                            borderBottom: "1px solid",
                            borderColor: "#EAEAEA",
                          }}
                        >
                          <Typography variant="subtitle1">
                            No entry found
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Container>

          <Box sx={{ my: 4 }}>
            <UserTimeClock
              loading={loading}
              set_loading={set_loading}
              alert={alert}
              set_alert={set_alert}
            />
          </Box>
        </Box>
      </Box>
    )
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(TimeClock);
