import React, { useEffect, useState } from "react";
import { Alert, Box, Typography } from "@mui/material";

import { axios_get_call } from "../../../components/AxiosCall";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "../../../components/Container";

const RecentActivityList = ({ user }) => {
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  const [activity_list, set_activity_list] = useState(false);

  const navigate = useNavigate();

  const get_activity_list = async () => {
    let url = "/saas/analytics/get-activity-list/";
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      set_activity_list(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  useEffect(() => {
    get_activity_list();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("access")) {
      navigate("/login");
    }
  }, []);

  return user && activity_list ? (
    <Box>
      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity={alert.toLowerCase().includes("[error]") ? "error" : "info"}
          sx={{
            p: 2,
            mb: 2,
            width: 1,
            zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ""
      )}
      <Container>
        <Box sx={{ height:"285px", overflow:"scroll" }}>
          {activity_list.map((activity) => {
            return (
              <Box sx={{borderBottom:"1px solid #EAEAEA", p:1}}>
                <Typography variant="subtitle1">{activity}</Typography>
              </Box>
            );
          })}
        </Box>
      </Container>
    </Box>
  ) : (
    ""
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(RecentActivityList);
