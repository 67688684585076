      import React, { useEffect, useState } from 'react'
      import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'
      
      import axios from 'axios';
      import csrf_grabber from '../../../../components/csrf_grabber';
      import CustomModal from '../../../../components/CustomModal';
      import { connect } from 'react-redux';
      import { useNavigate } from 'react-router-dom';
      import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
      import { Divider } from '@mui/material'      
      import TextField from '@mui/material/TextField';
      import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow } from '@mui/material';      
      
      
      
const tableData = [
  {
      // id: 1,
      // title:'',
      // description:' 1/2/23',
      // startDate:' 1/2/23',
      // startTime: '1/2/23',
      // dueDate: '1/2/23',
      // dueTime: '1/2/23',
      // assignedTo: '3/5/2023',
      // location: '',
  }
  
]      
      const AddMultipleTasks = ({user, set_alert, set_loading}) => {
      
      var csrftoken = csrf_grabber('csrftoken');
        const [something, set_something] = useState(false);
      
        const navigate = useNavigate()
      
          const get_something = async () => {
            set_loading(true)
            const config = {
                params: { },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRFToken': csrftoken
                },
            };
    
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
                config
            );
            if (res.data.status == 1) {
                set_loading(false)
                set_something(res.data.response);
            } else {
                set_loading(false)
                set_alert(res.data.response)
            }
        }
        useEffect(() => {
          if (!(localStorage.getItem('access'))) {
            navigate('/login')
          }
        }, [])
        return (
          <Box sx={{width:'95%' , margin:'auto'}}>
            
          <Box sx={{display:'flex',justifyContent:'flex-end',height:'10vh' }}>
              <Box sx={{width:'70%', display:'flex',justifyContent:'space-around', marginTop:'15px' , alignItems:'flex-start' }}  >
                  <Typography>Add Mulitple Tasks</Typography>
                  <Typography sx={{display:'flex', alignItems:'center' }} > <HelpOutlineSharpIcon sx={{height:'3vh',marginRight:'10px'}} /> Needhelp?</Typography>
              </Box>
          </Box>
          <Divider sx={{ height: 2}} />
          <Box sx={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
            <Typography>1 New Task</Typography>
<TextField id="standard-basic" label="search" variant="standard" />
          </Box>
          <Box>
            <TableContainer sx={{marginTop:'20px'}}>
          <Table  aria-label="simple table">
    <TableHead sx={{ backgroundColor: 'whitesmoke' }}>
      <TableRow>
        <TableCell align='center' sx={{ fontWeight: 'bold' }}>Title</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }} >Description</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }} >Start Date</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }} >Start Time</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Due Date</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Due Time</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Assigned To</TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Location</TableCell>
      </TableRow>
    </TableHead>
    <TableBody >
      {
        tableData.map(row => (
          <TableRow   key={row.id} sx={{ '&last-child td,&:last-child th': { border: 0, } ,cursor: 'pointer' }}>
            <TableCell sx={{display:'flex' , alignItems:'center', justifyContent:'space-between'}} align='center'>{row.generalTasks}</TableCell>
            <TableCell align='center'>{row.title}</TableCell>
            <TableCell align='center'>{row.description}</TableCell>
            <TableCell align='center'>{row.startDate}</TableCell>
            <TableCell align='center'>{row.startTime}</TableCell>
            <TableCell align='center'>{row.dueDate}</TableCell>
            <TableCell align='center'>{row.dueTime}</TableCell>
            <TableCell align='center'>{row.assignedTo}</TableCell>
            <TableCell align='center'>{row.location}</TableCell>
          </TableRow>

        ))
      }
      

    </TableBody>
  </Table>
</TableContainer>

          </Box>
        </Box>
        )
      }
      
      const mapStateToProps = (state) => ({
        user: state.auth.user,
      });
      
      export default connect(mapStateToProps, {})(AddMultipleTasks);