import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { connect } from 'react-redux';
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Users from './Users';
import Container from '../../../../components/Container';

const MemberTabs = () => {
	const [tab_value, set_tab_value] = useState('Members');

	const handleChange = (event, tab_name) => {
		set_tab_value(tab_name);
	};


	return (
		<Box sx={{my:4}}>
			
			<Container>
			<TabContext value={tab_value} >
				<TabList onChange={handleChange} >
					<Tab label='Members' value='Members' sx={{
						fontSize:16, p:0, m:0
					}}></Tab>
					<Tab label='Admin' value='Admin' sx={{
						fontSize:16, p:0, m:0
					}}></Tab>
					<Tab label='Archived' value='Archived' sx={{
						fontSize:16, p:0, m:0
					}}></Tab>
				</TabList>
			</TabContext>
				</Container>
				<Box sx={{my:2}}>

			<Users tab_value={tab_value} />
				</Box>

		</Box>
	)
}

export default MemberTabs