import React, { useEffect, useState } from 'react'
import { Alert, Box, Typography } from '@mui/material'

import { axios_get_call } from '../../../components/AxiosCall';

import { connect } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';

const ViewForm = () => {

    const [alert, set_alert] = useState(false);
    const location = useLocation();
    const [loading, set_loading] = useState(false);
    const [form, set_form] = useState(false);
    const [form_id, set_form_id] = useState(false);

    const navigate = useNavigate()

    const get_form_details_for_public = async () => {
        let url = '/saas/forms/get-form-details-for-public/';
        let res = await axios_get_call(url, { form_id }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_form(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    useEffect(() => {
        if (form_id){
               get_form_details_for_public()
        } else {

            let form_id = new URLSearchParams(location.search)
            console.log('form_id')
            console.log(form_id.get("id"))
            set_form_id(form_id.get("id"))
        }
    }, [form_id])

    

    return (
        <Box sx={{bgcolor:"white", m:-5, p:5}}>
            <LoadingScreen loading={loading} />
            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                    sx={{
                        p: 2,
                        mb: 2,
                        width: 1,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}
            {form &&
                <Box>
                    <Box my={4}>
                        <Typography variant="h1" sx={{fontSize:45}} align='center'>{form.form_title}</Typography>
                    </Box>
                    <Box dangerouslySetInnerHTML={{ __html: form.form_html }} >
                        
                    </Box>
                </Box>
            }
        </Box>
    )
}


export default ViewForm