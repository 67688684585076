      import React, { useEffect, useState } from 'react'
      import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'
      import axios from 'axios';
      import csrf_grabber from '../../../../components/csrf_grabber';
      import CustomModal from '../../../../components/CustomModal';
      import { connect } from 'react-redux';
      import { useNavigate } from 'react-router-dom';
      import { Divider } from '@mui/material'   
      import EastSharpIcon from '@mui/icons-material/EastSharp';
      import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";      
      import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
      import TextField from '@mui/material/TextField';
      import ModeEditIcon from '@mui/icons-material/ModeEdit';
      import DeleteIcon from '@mui/icons-material/Delete';
      import InputAdornment from '@mui/material/InputAdornment';
      import Modal from '@mui/material/Modal';
      const style = {
        position: 'absolute',
        top: '37%',
        left: '86%',
        transform: 'translate(-50%, -50%)',
        width: 200,
        height:130,
        bgcolor: 'background.paper',
        boxShadow: 24,
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        p:2,
        borderRadius:2
      };


      
      const Label = ({user, set_alert, set_loading}) => {
        const [openDelete, setDelete] = React.useState(false);
        const handleOpenDelete = () => setDelete(true);
        const handleCloseDelete= () => setDelete(false);
      
      var csrftoken = csrf_grabber('csrftoken');
        const [something, set_something] = useState(false);
      
        const navigate = useNavigate()
      
          const get_something = async () => {
            set_loading(true)
            const config = {
                params: { },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRFToken': csrftoken
                },
            };
    
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
                config
            );
            if (res.data.status == 1) {
                set_loading(false)
                set_something(res.data.response);
            } else {
                set_loading(false)
                set_alert(res.data.response)
            }
        }
        useEffect(() => {
          if (!(localStorage.getItem('access'))) {
            navigate('/login')
          }
        }, [])
        return (
            <>
            <Box sx={{ width:'90%', display:'flex', justifyContent:'space-between', alignItems:'center',height:'10vh' }} >
            <Box sx={{display:'flex', alignItems:'center'}}>
              <Button>
               <EastSharpIcon sx={{color:'black'}} />
              </Button>
            <Typography >Label</Typography>
            </Box>
            <Box>
                <Button variant='contained'><AddCircleOutlineRoundedIcon sx={{marginRight:'10px'}}/> Add Tasks</Button>
            </Box>
            </Box>
            <Divider sx={{ height: 2}} />
            <Box sx={{width:'90%', margin:'auto' ,display:'flex', marginTop:'20px'}} >
                <TipsAndUpdatesIcon sx={{marginRight:'10px'}}/>
                <Typography> Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel voluptatum earum provident porro repudiandae iusto odit numquam  </Typography>
            </Box>
            <Box
      sx={{
        width: 450,
        maxWidth: '100%',
        margin:'auto',
        marginTop:3,
        

      }}
    >
      <TextField
        placeholder='General Tasks'
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button style={{ margin: '0' }}>
                <ModeEditIcon />
              </Button>
              <Button onClick={handleOpenDelete} style={{ margin: '0' }}>
                <DeleteIcon />
              </Button>
            </InputAdornment>
          ),
        }}
        fullWidth
        id="fullWidth"
      />
    </Box>
      <Modal
      sx={{ zIndex: 99232199 }}
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography >
            Are you sure you want to delete this ?
          </Typography>
            <Button variant='contained' sx={{marginTop:'10px'}} >Delete</Button>
        </Box>
      </Modal>
            </>
        )
      }
      
      const mapStateToProps = (state) => ({
        user: state.auth.user,
      });
      
      export default connect(mapStateToProps, {})(Label);