import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Paper, Typography } from '@mui/material'

import { axios_get_call } from "../../../../components/AxiosCall";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import GroupsIcon from '@mui/icons-material/Groups';
import Container from '../../../../components/Container';
import LoadingScreen from '../../../../components/LoadingScreen';

const GetTeams = ({ user }) => {

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [teams, set_teams] = useState(false);

    const navigate = useNavigate()

    const get_teams = async () => {
        let url = "/saas/team/teams/";
        let res = await axios_get_call(url, {}, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_teams(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    useEffect(() => {
        get_teams()
    }, [])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])

    return (
        user ? <Box>
<LoadingScreen loading={loading} />
            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                    sx={{
                        p: 2,
                        mb: 2,
                        width: 1,
                        borderRadius:2,
zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}
            <Container>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <GroupsIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
                        <Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
                            Teams
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant='contained' sx={{ width: '100px' }} onClick={() => { navigate("/dashboard/teams/add") }}>Create</Button>
                    </Box></Box>
            </Container>
            <Box sx={{my:4}}>
            <Container >
                <Box sx={{ display: 'flex', justifyContent: "space-evenly", flexWrap: "wrap", alignItems: "left", }}>
                    {teams ? teams.map((team) => {
                        return (
                            <>

                                <Box sx={{
                                    flex: 1, m: 2, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minWidth: '280px', width: '280px', maxWidth: '280px', borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)", cursor: "pointer", "&:hover": {
                                        boxShadow: "0px 6px 6px -4px rgba(0,0,0,0.06),0px 6px 8px 0px rgba(0,0,0,0.07),0px 2px 16px 0px rgba(0,0,0,0.06)",
                                    }
                                }}
                                    onClick={() => { navigate("/dashboard/teams/" + team.id) }}
                                >
                                    <Box sx={{ height: '200px', overflow: "hidden", m: 2, borderRadius: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img width={'100%'} src={`${process.env.REACT_APP_BACKEND_URL.split("/api")[0]}${team.profile_picture}`} />
                                        
                                    </Box>
                                    <Box sx={{ py: 1 }}>
                                        <Typography variant='h2' align="left">{team.name}</Typography>
                                    </Box>
                                </Box>
                            </>
                        )
                    }) : ""}
                </Box>
            </Container>
            </Box>
        </Box>
            : ""
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(GetTeams);