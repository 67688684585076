import { Divider, Typography } from '@mui/material'
import { Box, containerClasses } from '@mui/system'
import React from 'react'
import Container from '../../../../components/Container'
import { connect } from 'react-redux'

function ChatRooms({ user, chat_rooms, chat_room, set_chat_room }) {
  return (
    user &&
    chat_rooms && chat_rooms.length > 0 ?
      <Box sx={{ mr: 4, minHeight: '500px',maxHeight: '95%', height: '78vh', pb:4, border: "1px solid #EAEAEA", borderRadius: 3 }}>
        <Box sx={{
          p: 2,
          borderBottom: "1px solid #EAEAEA", cursor: "pointer",
        }}>
          <Typography align="left" variant='h1' sx={{ fontWeight: "bold" }}>Chats</Typography>
        </Box>
        <Box>
          {chat_rooms.map((chat, index) => {
            return (
              <Box key={chat.id} onClick={() => {
                if (chat_room === index) {
                  set_chat_room(false)
                } else {
                  set_chat_room(index)

                }
              }} sx={{
                p: 2,
                borderBottom: "1px solid #EAEAEA", cursor: "pointer", bgcolor: chat_room === index ? "#EAEAEA" : index % 2 ? "#FAFAFA" : "inherit", "&:hover": {
                  bgcolor: "#EAEAEA"
                }
              }}>
                <Typography variant='subtitle2' style={{ 'pointer-events': 'none' }}>{chat.uid == user.eid ? "You" : chat.name}</Typography>
                <Typography variant='subtitle1' style={{ 'pointer-events': 'none' }}>{chat.uid == user.eid ? "" : chat.email}</Typography>
              </Box>
            )
          })}
        </Box>

      </Box>
      :
      <Box sx={{ mx: 2 }}>
        <Typography variant='subtitle2'>No employees</Typography>
      </Box>

  )
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(ChatRooms);