import React from 'react'
import AppSidebarDesktopVersion from "./AppSidebarVariants/AppSidebarDesktopVersion"
import AppSidebarMobileVersion from "./AppSidebarVariants/AppSidebarMobileVersion"
import { Box } from '@mui/system'

function AppSidebar({children}) {
  return (
    <Box>
    <Box sx={{display:{xs:"none", sm:"none", md:"initial"}}}>
      <AppSidebarDesktopVersion children={children} />
    </Box>
    <Box sx={{display:{xs:"initial", sm:"initial", md:"none"}}}>
      <AppSidebarMobileVersion children={children} />
    </Box>
    </Box>
  )
}

export default AppSidebar