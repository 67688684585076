import React, { useEffect, useRef, useState } from 'react'
import { Alert, Box, Button, CircularProgress, TextField, Typography } from '@mui/material'

import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { axios_get_call, axios_post_call } from '../../../components/AxiosCall';
import Container from '../../../components/Container';

import EmailEditor from 'react-email-editor';
// Icons 
import FormsIcon from '@mui/icons-material/DocumentScannerOutlined';
import TickIcon from '@mui/icons-material/DoneOutlined';
import CustomModal from '../../../components/CustomModal';
import { MultiSelectDropdownInput } from '../../../components/DropdownInput';
import { spacing } from '@mui/system';


const CreateOrUpdateForms = ({ user }) => {
    const [form_id, set_form_id] = useState(false);
    const [is_preview_available, set_is_preview_available] = useState(true);
    const [enable_input_field_for_form_title, set_enable_input_field_for_form_title] = useState(false);
    const [form_title, set_form_title] = useState('Click here to add form name');
    const [email_to_client_form, set_email_to_client_form] = useState(false);
    const [clients, set_clients] = useState([]);
    const [client, set_client] = useState([]);
    const [email_subject, set_email_subject] = useState('');

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()

    const emailEditorRef = useRef(null);

    const save_form = () => {
        const unlayer = emailEditorRef.current.editor;

        unlayer.exportHtml(async (data) => {
            const { design, html } = data;
            let url = '/saas/forms/save-form/';
            let res = await axios_post_call(url, { form_html: html, form_design: design, form_title: form_title, form_id: form_id }, set_loading, set_alert);

            if (res.data.status == 1) {
                set_loading(false);
                if (typeof form_id == 'boolean')
                    window.location.href = `/dashboard/forms/id=${res.data.response}`
            } else if (res.data.status == 0) {
                set_alert(res.data.response);
                set_loading(false);
            }
        });
    };

    const get_form_details = async () => {
        console.log("emailEditorRef");
        console.log(emailEditorRef);
        if (emailEditorRef.current == null || emailEditorRef.current.editor == null) {
            setTimeout(get_form_details, 250)
        } else {
            let url = '/saas/forms/get-form-details/';
            let res = await axios_get_call(url, { form_id: form_id }, set_loading, set_alert);

            if (res.data.status == 1) {
                set_loading(false);
                let response = res.data.response
                set_form_title(response.form_title);
                emailEditorRef.current.editor.loadDesign(response.form_design)

                set_is_preview_available(true)

            } else if (res.data.status == 0) {
                set_alert(res.data.response);
                set_loading(false);
            }
        }
    }


    const onReady = (unlayer) => {
        // editor is ready
        // you can load your template here;
        // the design json can be obtained by calling
        // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)

        // const templateJson = { DESIGN JSON GOES HERE };
        // unlayer.loadDesign(templateJson);
    };




    // function exportHTML() {
    //     if (editorRef.current.editor) {
    //         let editor = editorRef.current.editor
    //         console.log("editorRef");
    //         console.log(editor);
    //         editor.exportHtml((data) => {
    //             const { design, html } = data;
    //             console.log('exportHtml', html);
    //         });

    //     }
    // }

    useEffect(() => {
        let path = location.pathname
        let id = path.split("id=")[1]

        if (id == "add") {
            set_is_preview_available(false)
        } else {
            set_form_id(parseInt(id))
            set_is_preview_available(true)
        }
    }, [])
    async function get_clients() {
        let url = '/saas/clients/get-clients/';
        let res = await axios_get_call(url, { form_id: form_id }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_clients(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    async function send_the_form_as_email_to_selected_clients() {
        let url = '/saas/forms/send-the-form-as-email-to-selected-clients/';
        let res = await axios_post_call(url, { form_id: form_id, email_subject, selected_clients: client }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_alert(res.data.response);

        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }
    useEffect(() => {
        if (email_to_client_form == true) {
            get_clients()
        }
    }, [email_to_client_form])
    useEffect(() => {
        if (typeof form_id == 'number') {
            get_form_details()
        }
    }, [form_id])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])
    return (
        user && <Box >

            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity='error'
                    sx={{
                        p: 2,
                        width: 1,
                        borderRadius: 2,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}


            {email_to_client_form ?
                <CustomModal set_preview={set_email_to_client_form}>
                    <Box
                        sx={{
                            width: { xl: "35%", lg: "50%", md: "60%", sm: "80%", xs: "90%" },
                            h: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            bgcolor: "white",
                            cursor: "auto",
                            borderRadius: 3,
                            boxShadow: 24,
                            p: { xl: 4, lg: 4, md: 4, sm: 4, xs: 2 },
                        }}
                    >
                        {alert ? (
                            <Alert
                                onClose={() => {
                                    set_alert(false);
                                }}
                                severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    zIndex: 9992000,

                                }}
                            >
                                {alert}
                            </Alert>
                        ) : (
                            ""
                        )}

                        <style
                            dangerouslySetInnerHTML={{
                                __html: "\n#menu-{\nz-index:9992000}\n",
                            }}
                        />
                        <Typography variant="h2" my={2}>
                            Select Clients
                        </Typography>
                        <Box sx={{ width: 1, my: 1 }}>
                            <MultiSelectDropdownInput state={client} set_state={set_client} dropdown_options={clients} create_url="/dashboard/clients" title="Client" />
                        </Box>
                        <Box sx={{ width: 1, my: 1 }}>
                            <TextField size="small" placeholder='Email subject (Optional)' fullWidth value={email_subject} onChange={(e) => { set_email_subject(e.target.value) }} variant='outlined' />
                        </Box>
                        {client.length > 0 &&
                            <Box sx={{ width: 1, my: 2 }}>
                                <Typography align='left' variant='subtitle2'>This email will be sent as an email to:
                                    {client.map((selected_client, index) => {
                                        return (
                                            <span>
                                                &nbsp;{selected_client.name} {index + 1 != client.length && ","}
                                            </span>
                                        )
                                    })}

                                </Typography>
                            </Box>
                        }
                        <Box sx={{ display: "flex", width: 1, my: 2 }}>
                            <Button fullWidth onClick={() => set_email_to_client_form(false)} variant='gray_button'>Cancel</Button>
                            <Button fullWidth onClick={() => {
                                send_the_form_as_email_to_selected_clients()
                            }} variant='contained'>{loading ? <CircularProgress color="secondary" /> : "Send"}</Button>
                        </Box>


                    </Box>
                </CustomModal>
                : ""}
            <Container>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
                        <FormsIcon sx={{ color: "primary.main", fontSize: 36, m: 1 }} />
                        {enable_input_field_for_form_title ?
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <TextField autoFocus autoCapitalize size="small" label="Add a title for this form" variant="outlined" value={form_title} onChange={(e) => { set_form_title(e.target.value) }} />
                                <Button
                                    onClick={() => { set_enable_input_field_for_form_title(false) }} variant='contained' sx={{ width: '50px !important' }}><TickIcon /></Button>
                            </Box>
                            :
                            <Box sx={{ display: 'flex', width: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ flex: 1, width: 1, }}>

                                    <Typography onClick={() => { set_enable_input_field_for_form_title(true) }} align='left' variant='h3' sx={{
                                        fontWeight: "light", "&:hover": {
                                            textDecoration: "underline",
                                            cursor: "pointer"
                                        }
                                    }}>
                                        {form_title}
                                    </Typography>
                                </Box>
                                <Box sx={{ flex: 1, width: 1, display: "flex", justifyContent: "right" }}>

                                    {is_preview_available &&
                                        <Box>
                                            <Button variant='outlined'
                                                sx={{ mr: 1 }} onClick={(e) => {
                                                    navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL.split("/api")[0]}/forms?id=${form_id}`)
                                                        .then(() => {
                                                            e.target.innerText = "Copied";
                                                        })
                                                        .catch((err) => {
                                                            console.error("Couldn't copy", err);
                                                        });
                                                }}>Copy link</ Button>
                                            <Button variant='outlined'
                                                sx={{ mr: 1 }} onClick={() => { set_email_to_client_form(true) }}>Email to client</ Button>
                                        </Box>
                                    }
                                    {is_preview_available ?
                                        <Button variant='contained' onClick={() => { save_form() }}>{loading ? <CircularProgress color="secondary" /> : "Save"}</Button>
                                        : <Button variant='outlined' onClick={() => { save_form() }}>{loading ? <CircularProgress color="secondary" /> : "Not Saved"}</Button>}

                                </Box>
                            </Box>

                        }
                    </Box>
                </Box>
            </Container>
            <Box sx={{ my: 4 }}>
                {user && user.hasOwnProperty('id') &&
                    <EmailEditor
                        editorId={90}
                        ref={emailEditorRef}
                        onReady={onReady}

                        options={{
                            displayMode: 'web',
                            width: '100vw',
                            height: '100vh',
                            user: {
                                id: user.id.toString() + "a",
                                name: user.username,
                                email: user.email
                            },

                            uploadMaxSize: 8000,
                            autoSelectOnDrop: true,
                            features: {
                                sendTestEmail: true,
                                smartMergeTags: true,
                                svgImageUpload: true,
                                preheaderText: true,
                                userUploads: true,
                                imageEditor: true,
                                preview: true,
                                textEditor: {
                                    inlineFontControls: true,
                                    emojis: true,
                                    tables: true,
                                    spellChecker: true,
                                    undoRedo: true,
                                    pageAnchors: true,
                                    audit: true,
                                }


                            },
                            tools: {
                                form: {
                                    properties: {
                                        action: {
                                            editor: {
                                                data: {
                                                    allowAddNewField: true,
                                                    actions: [
                                                        {
                                                            label: 'Capture form values',
                                                            method: 'POST',
                                                            onsubmit: "event.preventDefault();",
                                                            url: `${process.env.REACT_APP_BACKEND_URL}/saas/forms/handle-form-submissions/?form_id=${form_id}`,
                                                        },

                                                    ],
                                                    allowCustomUrl: true,
                                                }
                                            }
                                        }
                                    }
                                }
                            }


                        }}
                    />
                }
            </Box>
        </Box>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(CreateOrUpdateForms);