import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ContentSearchBar from "../../../../components/ContentSearchBar";
import DrawerLinks from "./DrawerLinks";
import { Menu } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import { axios_get_call } from "../../../../components/AxiosCall";

import convertToTitleCase from "../../utilities/StringParsing";
import Container from "../../../../components/Container";

const Users = ({ user, tab_value }) => {
  const [state, setState] = React.useState({
    bottom: false,
    right: false,
    bottom2: false,
    add_users_drawer: false,
    bottom4: false,
    right2: false,
  });

  const navigate = useNavigate();
  const [filtered_members, set_filtered_members] = useState([
    {
      id: "loading",
      title: "-",
      first_name: "-",
      last_name: "-",
      phone_no: "-",
      email: "-",
      teams: 0,
      jobs_assigned: 0,
    },
  ]);
  const [members, set_members] = useState([
    {
      id: "loading",
      title: "-",
      first_name: "-",
      last_name: "-",
      phone_no: "-",
      email: "-",
      teams: 0,
      jobs_assigned: 0,
    },
  ]);
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const get_members = async () => {
    set_loading(true);
    let url = "/saas/user-management-system/get-all-members/";
    let res = await axios_get_call(
      url,
      { member_type: tab_value },
      set_loading,
      set_alert
    );

    if (res.data.status == 1) {
      set_loading(false);
      set_members(res.data.response);
      set_filtered_members(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  useEffect(() => {
    get_members();
  }, [tab_value]);

  useEffect(() => {
    if (!localStorage.getItem("access")) {
      navigate("/login");
    }
  }, []);

  const user_rows_header = filtered_members[0]
    ? Object.keys(filtered_members[0]).map((key) => {
        return (
          <TableCell align="center" sx={{ fontWeight: "bold" }} key={key}>
            {convertToTitleCase(key)}
          </TableCell>
        );
      })
    : "";

  const user_rows_data = filtered_members.map((member, index) => {
    return (
      <TableRow
        key={index}
        sx={{
          "&last-child td,&:last-child th": { border: 0 },
          "&:hover": {
            bgcolor: "#efefef",
          },
        }}
      >
        {Object.keys(member).map((key) => {
          return (
            <TableCell align="center">
              {member[key] == 0 ? "None" : member[key]}
            </TableCell>
          );
        })}
      </TableRow>
    );
  });

  return (
    <Box>
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
           {/*<Box sx={{ margin: "15px" }}>
            <Button onClick={handleClick} variant="contained">
              <SortOutlinedIcon />
              Filter
            </Button> 
          </Box>*/}
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent:{xs:"center", sm:"center", md:"flex-end"},
                alignItems: "center",
                margin: "15px",
                flexDirection:{xs:"column", sm:"column", md:"row"}
              }}
            >
              <ContentSearchBar
                state={members}
                set_filtered_list={set_filtered_members}
              />
              <Box>
                <Button
                  onClick={toggleDrawer("add_users_drawer", true)}
                  variant="contained"
                  sx={{ mx: "10px" }}
                >
                  <AddCircleOutlineRoundedIcon />
                  Add Members
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClick}
                  sx={{ mr: "10px" }}
                >
                  <IosShareIcon /> Export
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>As CSV</MenuItem>
                  <MenuItem onClick={handleClose}>As XLS</MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Box>

        <Table aria-label="simple table">
          {loading ? (
            <Box
              sx={{
                width: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableHead sx={{ backgroundColor: "whitesmoke" }}>
                <TableRow>{user_rows_header}</TableRow>
              </TableHead>
              <TableBody>
                {filtered_members.length != 0 &&
                filtered_members[0].hasOwnProperty("id") ? (
                  user_rows_data
                ) : (
                  <Typography variant="subtitle2" align="center" my={4}>
                    No members found
                  </Typography>
                )}
              </TableBody>
            </>
          )}
        </Table>
      </Container>
      <DrawerLinks
        state={state}
        setState={setState}
        toggleDrawer={toggleDrawer}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Users);
