import React, { useEffect, useRef, useState } from 'react'
import Timeline, {
    TimelineHeaders,
    SidebarHeader,
    DateHeader,
    CursorMarker
} from 'react-calendar-timeline'

import 'react-calendar-timeline/lib/Timeline.css'
import './style.css'
import moment from 'moment'
import { Alert, Box, Button, LinearProgress, Toolbar, Typography } from '@mui/material'

import { axios_get_call, axios_post_call } from '../../../../components/AxiosCall';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LeftIcon from '@mui/icons-material/ChevronLeft';
import RightIcon from '@mui/icons-material/ChevronRight';
import JobCRUD from './JobCRUD'
import generate_local_timezone_based_iso_date from '../../../../components/generate_local_timezone_based_iso_date'
import Container from '../../../../components/Container'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AllJobsStageWise from './AllJobsStageWise'
import LoadingScreen from '../../../../components/LoadingScreen'


function JobScheduleCalendar({ user }) {
    const navigate = useNavigate()
    const timelineRef = useRef(null);

    const [currentDateTime, setCurrentDateTime] = useState(getMostRecentSunday());
    const [currentDate, setCurrentDate] = useState(new Date(new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate()).setHours(0, 0, 0)));
    const [currentNextWeek, setCurrentNextWeek] = useState(new Date(new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate() + 6).setHours(23, 59, 59)));

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [members, set_members] = useState([]);
    const [jobs, set_jobs] = useState([]);
    const [job_id, set_job_id] = useState(false);
    const [the_member_id, set_the_member_id] = useState(false);
    const [job_assign_start_date, set_job_assign_start_date] = useState(false);



    async function update_job_time_and_member(job_id, previous_member_id, new_date, new_member_id) {
        let url = '/saas/job-scheduling/update-job-time-and-member/';
        let res = await axios_post_call(url, { job_id, previous_member_id, new_date, new_member_id }, set_loading, set_alert);


        get_jobs()
        if (res.data.status == 1) {
            set_loading(false);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }


    const get_members = async () => {
        let url = '/saas/user-management-system/get-all-members/';
        let res = await axios_get_call(url, {}, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_members(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    const get_jobs = async () => {
        let url = '/saas/job-scheduling/get-jobs/';
        let res = await axios_get_call(url, {}, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            const all_jobs = res.data.response.map(job => ({
                ...job,
                start_datetime: moment(job.start_datetime),
                end_datetime: moment(job.end_datetime),
                start_time: moment(new Date(new Date(new Date(job.start_datetime).getFullYear(), new Date(job.start_datetime).getMonth(), new Date(job.start_datetime).getDate()).setHours(1, 0, 0))),
                end_time: moment(new Date(new Date(new Date(job.end_datetime).getFullYear(), new Date(job.end_datetime).getMonth(), new Date(job.end_datetime).getDate()).setHours(20, 3, 0)))
            }));
            set_jobs(all_jobs);

        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    useEffect(() => {
        get_members()
    }, [])

    useEffect(() => {
        get_jobs()
    }, [])
    useEffect(() => {
        resizeTimeline()
    }, [jobs])
    useEffect(() => {
        resizeTimeline()
    }, [members])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])


    function getMostRecentSunday() {
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.

        // Calculate the number of days to subtract to get to the most recent Sunday
        const daysToSunday = (dayOfWeek + 7) % 7;

        // Subtract the calculated days to get the most recent Sunday
        const mostRecentSunday = new Date(today);
        mostRecentSunday.setDate(today.getDate() - daysToSunday);

        return mostRecentSunday;
    }

    //   new Date(new Date(item_date).setHours(11, 59, 59)

    const handleNextWeek = () => {
        let nextWeek = new Date(currentDate);
        nextWeek.setDate(nextWeek.getDate() + 7);
        setCurrentDate(nextWeek);
        nextWeek = new Date(currentNextWeek);
        nextWeek.setDate(nextWeek.getDate() + 7);
        setCurrentNextWeek(nextWeek);
    };

    const handlePrevWeek = () => {
        let nextWeek = new Date(currentDate);
        nextWeek.setDate(nextWeek.getDate() - 7);
        setCurrentDate(nextWeek);
        nextWeek = new Date(currentNextWeek);
        nextWeek.setDate(nextWeek.getDate() - 7);
        setCurrentNextWeek(nextWeek);
    };


    const handleCanvasSpotDoubleClick = (groupId, time, e) => {
        set_job_id(true)
        set_the_member_id(groupId)
        set_job_assign_start_date(new Date(time))
    };
    const handleItemMove = (itemId, dragTime, newGroupOrder) => {
        const filteredData = jobs.filter(job => job.id === itemId)[0];

        const index = jobs.indexOf(filteredData);

        let job_id = filteredData.id.split("%20")[0].trim()
        let previous_member_id = filteredData.group
        let new_date = generate_local_timezone_based_iso_date(new Date(dragTime))
        let new_member_id = members[newGroupOrder]['id']

        let updated_jobs_state = JSON.parse(JSON.stringify(jobs))
        updated_jobs_state[index]['start_datetime'] = new_date
        updated_jobs_state[index]['end_datetime'] = new_date
        updated_jobs_state[index]['group'] = new_member_id

        const all_jobs = updated_jobs_state.map(job => ({
            ...job,
            start_datetime: moment(job.start_datetime),
            end_datetime: moment(job.end_datetime),
            start_time: moment(new Date(new Date(new Date(job.start_datetime).getFullYear(), new Date(job.start_datetime).getMonth(), new Date(job.start_datetime).getDate()).setHours(1, 0, 0))),
            end_time: moment(new Date(new Date(new Date(job.end_datetime).getFullYear(), new Date(job.end_datetime).getMonth(), new Date(job.end_datetime).getDate()).setHours(20, 3, 0)))
        }));
        set_jobs(all_jobs)
        update_job_time_and_member(job_id, previous_member_id, new_date, new_member_id)
    };

    const resizeTimeline = () => {
        if (timelineRef.current) {
            let nextDay = new Date(new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate() + 1).setHours(0, 0, 0))
            timelineRef.current.resize();
        }
    };


    function CustomHeader() {
        const next_current_week = new Date(currentDate);
        next_current_week.setDate(next_current_week.getDate() + 7);
        return (
            <Box sx={{
                width: '98.1%',
                bgcolor: "primary.main", display: 'flex', alignItems: "center", justifyContent: "center",
                borderRadius: "15px 15px 0 0", boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)"
            }}>
                <Box onClick={handlePrevWeek} align="right" sx={{

                    py: 1,
                    flex: 2, cursor: "pointer", transition: "0.25s all", "&:hover": {
                        bgcolor: "primary.light"
                    }
                }}>
                    <LeftIcon sx={{ color: "white", fontSize: 40 }} />
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: "column" }}>
                    <Box>
                        <Typography variant='h3' align="center" sx={{ color: "white" }}>
                            {moment(currentDate).format('YYYY')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='h3' align="center" sx={{ color: "white" }}>
                            {moment(currentDate).format('MMM D')} - {moment(next_current_week).format('MMM D')}
                        </Typography>
                    </Box>
                </Box>
                <Box onClick={handleNextWeek} align="left" sx={{
                    py: 1,
                    flex: 2, cursor: "pointer", transition: "0.25s all", "&:hover": {
                        bgcolor: "primary.light"
                    }
                }}>
                    <RightIcon sx={{ color: "white", fontSize: 40 }} />
                </Box>
            </Box>
        );
    }

    const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        // Define custom styles for items
        const itemStyle = {
            boxShadow: itemContext.selected ? "0px 0px 10px 0px rgba(234, 36, 86, 0.5)" : "0px 0px 10px 0px rgba(52, 92, 114, 0.5)",
            background: itemContext.selected ? "rgb(234, 36, 86)" : "rgb(52, 92, 114)",
            border: "none",
            margin: '10px',
            padding: '10px',
            borderRadius: '10px',
        };

        return (
            <Box   {...getItemProps({
                style: itemStyle,
                onDoubleClick: () => {

                    set_job_id(item.job_id)
                    set_job_assign_start_date(new Date(item.start_datetime))
                }
            })}
            >
                <Typography sx={{ color: "white", fontWeight: 800, fontSize: 12, whiteSpace: "nowrap" }}>{item.start_datetime.format('h:mm A')} - {item.end_datetime.format('h:mm A')}</Typography>
                <Typography variant="subtitle2" sx={{ color: 'white', fontSize: 16 }}>{item.job_type && item.job_type + ">>"}  {item.title.slice(0, 25).trim() + "..."}</Typography>
            </Box>
        );
    };

    const groupRenderer = ({ group }) => {

        return (
            <Box>
                <Typography sx={{ color: "white" }} variant='subtitle2'>{group.first_name} {group.last_name}</Typography>
            </Box>
        );
    };


    return (
        <Box >

            <LoadingScreen loading={loading} />
            <JobCRUD set_jobs={set_jobs} job_id={job_id} set_job_id={set_job_id} job_assign_start_date={job_assign_start_date} set_job_assign_start_date={set_job_assign_start_date} the_member_id={the_member_id} />

            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                    sx={{
                        p: 2,
                        mb: 2,
                        width: 1,
                        borderRadius: 2,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}
            <CustomHeader />
            {loading ? <LinearProgress sx={{ width: '98.1%' }} color='secondary' /> : ""}
            <Timeline
                style={{ width: '98.1%', }}
                ref={timelineRef}
                stackItems

                groups={members}
                items={jobs}
                sidebarWidth={200} // Adjust the sidebar width as needed
                lineHeight={120}
                canChangeGroup={true}
                visibleTimeStart={currentDate}
                visibleTimeEnd={currentNextWeek}
                getVisibleItems={() => { }}
                timelineUnit="day"
                timelineStyles={{ timeline: { height: 'auto' } }}
                canMove={true}
                canResize={false}
                traditionalZoom={true}
                itemTouchSendsClick={false}
                timeSteps={{ day: 1 }}
                timeStep={60 * 24} // Hide time by setting a large time step (24 hours)
                enableSticky
                minZoom={60 * 24} // Prevent zooming to hours and minutes
                itemRenderer={itemRenderer}
                groupRenderer={groupRenderer}

                onItemMove={handleItemMove}
                onCanvasDoubleClick={handleCanvasSpotDoubleClick}

            >
                <TimelineHeaders>
                    <SidebarHeader>
                        {({ getRootProps }) => {
                            return <Box  {...getRootProps()}
                                sx={{
                                    bgcolor: 'secondary.main', // Change the background color
                                    color: "white",
                                    fontSize: '18px',   // Adjust the font size
                                    fontWeight: 'bold', // Adjust the font weight
                                    borderRadius: 0,
                                    padding: '10px',    // Adjust the padding
                                    pt: 1.6,    // Adjust the padding
                                    // Add any other custom styles here
                                }}

                            ><Typography variant='h3'>Members</Typography></Box>
                        }}
                    </SidebarHeader>



                    <DateHeader style={{ backgroundColor: "white", maxWidth: '200px' }} height={50} unit='day' labelFormat={"ddd MM/DD"} />
                </TimelineHeaders>
                <CursorMarker>
                    {({ styles, date }) =>
                        // date is value of current date. Use this to render special styles for the marker
                        // or any other custom logic based on date:
                        // e.g. styles = {...styles, backgroundColor: isDateInAfternoon(date) ? 'red' : 'limegreen'}
                        <div style={{ ...styles, backgroundColor: 'pink' }} />
                    }
                </CursorMarker>
            </Timeline>
            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                    sx={{
                        p: 2,
                        mb: 2,
                        width: 1,
                        borderRadius: 2,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}
            <Box sx={{ width: '98.1%', my: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ flex: 1, mr: 2 }}><AllJobsStageWise /></Box>

                    <Box sx={{ flex: 3, ml: 2, }}>

                        <TableContainer component={Container} fullWidth >
                            <Typography variant='h2' align='left' mb={2}>Jobs</Typography>

                            <Toolbar title="Double click on an entry to edit it">
                                <Box sx={{ minHeight: '300px', width: 1 }}>
                                    <Table fullWidth sx={{ bgcolor: "white", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><Typography fontWeight={"bold"}>Assigned To</Typography></TableCell>
                                                <TableCell><Typography fontWeight={"bold"}>Job</Typography></TableCell>
                                                <TableCell><Typography fontWeight={"bold"}>Deadline</Typography></TableCell>
                                                <TableCell><Typography fontWeight={"bold"}>Stage</Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {jobs.length > 0 ? jobs.map((job) => (
                                                <TableRow
                                                    key={job.job_id}
                                                    sx={{
                                                        ':nth-child(odd)': { bgcolor: "#F2F2F2", }, "&:hover": {
                                                            bgcolor: "#eaeaea",
                                                            cursor: "pointer"
                                                        }
                                                    }}
                                                    onDoubleClick={() => {
                                                        set_job_id(job.job_id)
                                                        set_job_assign_start_date(new Date(job.start_datetime))
                                                    }}

                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Typography fontWeight={"bold"}>{job.employee_name}</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{job.title}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography >{moment(job.end_datetime).format('MMM DD, YYYY (ddd)')}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography >{job.job_stage}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )) :
                                                <Typography sx={{ p: 2 }} variant="subtitle2">No jobs found</Typography>
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Toolbar>
                        </TableContainer>
                    </Box>
                </Box>

            </Box>
        </Box >
    )
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
});
export default connect(mapStateToProps, {})(JobScheduleCalendar);;