import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Typography,Paper } from '@mui/material'

import axios from 'axios';
import csrf_grabber from '../../../../components/csrf_grabber';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskIcon from '@mui/icons-material/Task';
import LabelIcon from '@mui/icons-material/Label';
import Tasktab from './Tasktab';
import Tasksbutton from './Tasksbutton';
const Tasks = ({ user }) => {

  var csrftoken = csrf_grabber('csrftoken');
  const [something, set_something] = useState(false);
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

  const navigate = useNavigate()
  const location = useLocation()

  const get_something = async () => {
    set_loading(true)
    const config = {
      params: {},
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': csrftoken
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/auth/team/get-something/`,
      config
    );
    if (res.data.status == 1) {
      set_loading(false)
      set_something(res.data.response);
    } else {
      set_loading(false)
      set_alert(res.data.response)
    }
  }
  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])
  return (
    <Box>
     
     <Paper sx={{ display: 'flex', p: 4, justifyContent: 'space-between', height: '10vh' }} >
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TaskIcon sx={{ m: 1 }} />
						<Typography align='left' variant='h1' sx={{ fontWeight: "light" }}>
							Quick Tasks
						</Typography>
					</Box>
          <Tasksbutton/>
				</Paper>
      <Tasktab/>
          </Box>
          
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Tasks);