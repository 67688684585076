import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../../../../components/Container";
import {
  axios_get_call,
  axios_post_call,
} from "../../../../components/AxiosCall";
import moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import PauseIcon from "@mui/icons-material/Pause";
import CancelIcon from "@mui/icons-material/Clear";

import GoogleMapReact from "google-map-react";

const TimeClock = ({ user, alert, set_alert, loading, set_loading }) => {
  const [edit_timezone, set_edit_timezone] = useState(false);
  const [timezone, set_timezone] = useState("");
  const [edited_timezone, set_edited_timezone] = useState("");
  const [clocked_in, set_clocked_in] = useState(false);
  const [running_entry, set_running_entry] = useState(false);
  const [jobs, set_jobs] = useState([]);
  const [user_clock_logs, set_user_clock_logs] = useState(false);

  const [selected_job, set_selected_job] = useState(false);
  const [current_location, set_current_location] = useState(null);
  const [destination_location, set_destination_location] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [running_entry_elapsed_time, set_running_entry_elapsed_time] =
    useState(false);

  const get_jobs = async () => {
    let url = "/saas/job-scheduling/get-current-user-jobs/";
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      const all_jobs = res.data.response.map((job) => ({
        ...job,
        start_datetime: moment(job.start_datetime),
        end_datetime: moment(job.end_datetime),
        start_time: moment(
          new Date(
            new Date(
              new Date(job.start_datetime).getFullYear(),
              new Date(job.start_datetime).getMonth(),
              new Date(job.start_datetime).getDate()
            ).setHours(1, 0, 0)
          )
        ),
        end_time: moment(
          new Date(
            new Date(
              new Date(job.end_datetime).getFullYear(),
              new Date(job.end_datetime).getMonth(),
              new Date(job.end_datetime).getDate()
            ).setHours(20, 3, 0)
          )
        ),
      }));
      set_jobs(all_jobs);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  const check_if_user_is_clocked_in = async () => {
    let url = "/saas/job-scheduling/check-if-user-is-clocked-in/";
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_clocked_in(res.data.response.clocked_in);
      set_running_entry(res.data.response.running_entry);
      set_loading(false);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  const clock_out_the_user = async (stage) => {
    let url = "/saas/job-scheduling/clock-out-the-user/";
    let res = await axios_post_call(
      url,
      {
        entry_id: running_entry.id,
        status: stage,
        current_datetime: new Date(),
      },
      set_loading,
      set_alert
    );
    console.log(new Date());
    if (res.data.status == 1) {
      set_clocked_in(false);
      set_running_entry(false);
      set_loading(false);
      get_jobs();
      get_user_clock_logs();
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  const clock_in_the_user = async () => {
    let url = "/saas/job-scheduling/clock-in-the-user/";
    let res = await axios_post_call(
      url,
      {
        job_id:
          selected_job || typeof selected_job == "number"
            ? jobs[selected_job].job_id
            : false,
        timezone: timezone,
        current_datetime: new Date(),
      },
      set_loading,
      set_alert
    );

    if (res.data.status == 1) {
      set_clocked_in(res.data.response.clocked_in);
      set_running_entry(res.data.response.running_entry);
      set_loading(false);
      get_jobs();
      get_user_clock_logs();
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  function format_date(date_input) {
    const date = new Date(date_input);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const year = date.getFullYear() % 100;

    const formatted_date = `${day}/${month}/${year}`;

    return formatted_date;
  }

  function format_seconds(total_seconds) {
    // Calculate hours, minutes, and seconds
    let hours = Math.floor(total_seconds / 3600);
    let minutes = Math.floor((total_seconds % 3600) / 60);
    let seconds = Math.floor(total_seconds % 60);

    // Format into hh:mm:ss
    let formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    return formattedTime;
  }

  const get_user_clock_logs = async () => {
    let url = "/saas/job-scheduling/get-user-clock-logs/";
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_user_clock_logs(res.data.response);
      set_loading(false);
      get_jobs();
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  const Marker = ({ text }) => <div>{text}</div>;

  const getLocationLatLng = async (locationString) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${locationString}&key=YOUR_GOOGLE_MAPS_API_KEY`
    );
    const data = await response.json();
    if (data.results && data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      set_destination_location({ lat, lng });
    }
  };

  const get_current_location = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const current_location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        set_current_location(current_location);
      });
    }
  };

  useEffect(() => {
    if (jobs)
      if (jobs[selected_job])
        if (jobs[selected_job].location)
          getLocationLatLng(jobs[selected_job].location);
  }, [selected_job]);

  useEffect(() => {
    get_current_location();
  }, []);

  useEffect(() => {
    get_user_clock_logs();
  }, []);

  useEffect(() => {
    if (running_entry && clocked_in) {
      const intervalId = setInterval(() => {
        const secondsElapsed =
          (new Date() - new Date(running_entry.start_datetime)) / 1000;
        const formattedTime = new Date(secondsElapsed * 1000)
          .toISOString()
          .substr(11, 8);
        set_running_entry_elapsed_time(formattedTime);
      }, 1000); // Update every second

      return () => clearInterval(intervalId); // Cleanup on component unmount
    }
  }, [running_entry]);

  useEffect(() => {
    get_jobs();
  }, []);

  useEffect(() => {
    check_if_user_is_clocked_in();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("access")) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const getUserTimezone = () => {
      let current_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      set_timezone(current_timezone);
    };
    getUserTimezone();
  }, []);

  return (
    <Box>
      {/* jobs  */}
      <Box sx={{ mb:5, maxHeight: 500, overflowY:"scroll", width: 1 }}>
        <Table
          fullWidth
          sx={{
            bgcolor: "white",
            borderRadius: 3,
            boxShadow:
              "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight={"bold"}>Job</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={"bold"}>Stage</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={"bold"}>Deadline</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {jobs.length > 0 ? (
              jobs.map((job, index) => (
                <Tooltip title="Double click to work on this job">
                  <TableRow
                    key={index}
                    sx={{
                      ":nth-child(odd)": {
                        bgcolor:
                          selected_job == index ? "secondary.main" : "#F2F2F2",
                      },
                      ":nth-child(even)": {
                        bgcolor:
                          selected_job == index ? "secondary.main" : "white",
                      },
                      "&:hover": {
                        bgcolor:
                          selected_job == index ? "secondary.light" : "#eaeaea",
                        cursor: "pointer",
                      },
                    }}
                    onDoubleClick={() => {
                      set_selected_job(index);
                    }}
                  >
                    <TableCell>
                      <Typography
                        sx={{
                          color: selected_job == index ? "white" : "black",
                        }}
                      >
                        {job.title}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          color: selected_job == index ? "white" : "black",
                        }}
                      >
                        {job.job_stage}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          color: selected_job == index ? "white" : "black",
                        }}
                      >
                        {moment(job.end_datetime).format("MMM DD, YYYY (ddd)")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              ))
            ) : (
              <Typography sx={{ p: 2 }} variant="subtitle2">
                No jobs found
              </Typography>
            )}
          </TableBody>
        </Table>
      </Box>

      {/* clock in out  */}
      <Container>
        {selected_job || typeof selected_job == "number" ? (
          <Box>
            <Typography variant="subtitle2" sx={{ color: "#000" }}>
              {jobs[selected_job].title}
            </Typography>
            <Typography variant="subtitle1">
              {moment(jobs[selected_job].start_datetime).format(
                "MMM DD, YYYY (ddd)"
              )}
            </Typography>
          </Box>
        ) : (
          <Typography variant="subtitle1">
            Click any job below to work on it (Or clock in without
            documentation)
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            justifyContent: "center",
            minHeight: "260px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip
              title={running_entry ? "Mark the job as completed" : ""}
              placement="top"
            >
              <Box
                sx={{
                  width: "170px",
                  height: "170px",
                  borderRadius: "170px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: clocked_in ? "rgb(254,0,64)" : "rgb(52,92,114)",
                  background: clocked_in
                    ? "linear-gradient(145deg, rgba(254,0,64,1) 0%, rgba(234,36,86,1) 50%, rgba(255,198,117,1) 100%)"
                    : "linear-gradient(145deg, rgba(92,92,180,1) 0%, rgba(22,152,223,1) 50%, #75ceff 100%)",
                  fontSize: 26,
                  color: "white",
                  fontWeight: "bold",
                  cursor: "pointer",
                  transition: "all 0.25s",
                  "&:hover": {
                    width: "190px",
                    height: "190px",
                    fontSize: 28,
                  },
                }}
                onClick={() => {
                  if (clocked_in) {
                    clock_out_the_user("completed");
                  } else {
                    clock_in_the_user();
                  }
                }}
              >
                {clocked_in ? "Clock Out" : "Clock In"}
              </Box>
            </Tooltip>

            {clocked_in && running_entry.has_job && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                  width: "140px",
                  bgcolor: "#EAEAEA",
                  borderRadius: 15,
                  px: 2,
                  py: 1,
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Tooltip title="Put the job on 'hold' status">
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgb(52,92,114)",
                        background:
                          "linear-gradient(145deg, rgba(92,92,180,1) 0%, rgba(22,152,223,1) 50%, #75ceff 100%)",
                        color: "white",
                        fontWeight: "bold",
                        cursor: "pointer",
                        transition: "all 0.25s",
                        "&:hover": {
                          width: "45px",
                          height: "45px",
                          fontSize: 28,
                        },
                      }}
                      onClick={() => {
                        clock_out_the_user("on_hold");
                      }}
                    >
                      <PauseIcon sx={{ fontSize: 26 }} />
                    </Box>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title="Put the job under 'got cancelled' status">
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgb(192,3,30)",
                        background:
                          "linear-gradient(145deg, rgba(192,3,30,1) 0%, rgba(231,16,46,1) 50%, rgba(255,58,58,1) 100%)",
                        color: "white",
                        fontWeight: "bold",
                        cursor: "pointer",
                        transition: "all 0.25s",
                        "&:hover": {
                          width: "45px",
                          height: "45px",
                          fontSize: 28,
                        },
                      }}
                      onClick={() => {
                        clock_out_the_user("got_cancelled");
                      }}
                    >
                      <CancelIcon sx={{ fontSize: 26 }} />
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            )}
          </Box>

          <Divider orientation="vertical" flexItem />
          {/* Google maps section  */}
          <Box
            sx={{
              flex: 1,
              ml: 2,
            }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{ key: "YOUR_GOOGLE_MAPS_API_KEY" }}
              defaultCenter={current_location}
              defaultZoom={14}
            >
              {current_location && (
                <Marker
                  lat={current_location.lat}
                  lng={current_location.lng}
                  text="Current Location"
                />
              )}
              {destination_location && (
                <Marker
                  lat={destination_location.lat}
                  lng={destination_location.lng}
                  text="Destination"
                />
              )}
            </GoogleMapReact>
          </Box>
        </Box>

        {/* google map for the job location  */}
      </Container>
      <br />
      <Container>
        {/* job summary  */}
        {/* job log  */}

        <Box
          sx={{
            flex: 1,
            ml: 2,
            mt: { xs: 4, sm: 4, md: "initial" },
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height: 1,
            maxWidth: 1,
            minWidth: "280px",
            overflowX: "scroll",
          }}
        >
          <Box>
            {edit_timezone ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  size="small"
                  placeholder={timezone}
                  variant="outlined"
                  value={edited_timezone}
                  onChange={(e) => {
                    set_edited_timezone(e.target.value);
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    if (edited_timezone != "") set_timezone(edited_timezone);
                    set_edit_timezone(false);
                  }}
                  sx={{
                    minWidth: "0px",
                    width: "50px",
                    height: "50px",
                    padding: 2,
                    borderRadius: 12,
                  }}
                >
                  ✔
                </Button>
              </Box>
            ) : (
              <Tooltip
                title="Double click to change timezone"
                placement="top-start"
              >
                <Typography
                  onDoubleClick={() => {
                    set_edit_timezone(true);
                  }}
                  variant="subtitle2"
                  sx={{ cursor: "pointer", width: "fit-content", p: 0.5 }}
                >
                  {timezone} Timezone
                </Typography>
              </Tooltip>
            )}
          </Box>
          <Box sx={{ flex: 1, width: 1 }}>
            {clocked_in && running_entry && (
              <Box
                sx={{
                  p: 1,
                  border: "1px solid #EAEAEA",
                  width: 1,
                  mt: 1,
                  display: "flex",
                }}
              >
                <Typography variant="subtitle2" sx={{ color: "primary.main" }}>
                  {running_entry_elapsed_time}
                </Typography>
                <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />

                {running_entry.has_job ? (
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      width: 1,
                    }}
                  >
                    <Box sx={{ flex: 1, overflow: "hidden" }}>
                      <Typography variant="subtitle2">
                        {running_entry.job_title}
                      </Typography>
                    </Box>
                    <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />

                    <Box>
                      <Tooltip title="Deadline (dd/mm/yy)" placement="top">
                        <Typography
                          variant="subtitle2"
                          align="right"
                          sx={{ color: "secondary.main" }}
                        >
                          {format_date(running_entry.job_end_datetime)}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                ) : (
                  <Typography variant="subtitle2">Working...</Typography>
                )}
              </Box>
            )}
            {user_clock_logs && user_clock_logs.length > 0 ? (
              <Box
                sx={{
                  p: 2,
                  mt: 2,
                  border: "1px solid #EAEAEA",
                  borderRadius: 4,
                  bgcolor: "#fdfdfd",
                }}
              >
                {user_clock_logs.map((log) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        border: "1px solid #EAEAEA",
                        m: 0.5,
                        p: 1,
                      }}
                    >
                      <Box sx={{ overflow: "hidden" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "secondary.main" }}
                        >
                          {format_seconds(log.total_hrs_of_work)}
                        </Typography>
                      </Box>
                      <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />

                      {log.has_job ? (
                        <Box sx={{ flex: 1, display: "flex" }}>
                          <Box sx={{ flex: 1, overflow: "hidden" }}>
                            <Typography variant="subtitle2">
                              {log.job_title}
                            </Typography>
                          </Box>
                          <Divider
                            flexItem
                            orientation="vertical"
                            sx={{ mx: 1 }}
                          />

                          <Box>
                            <Typography
                              variant="subtitle2"
                              align="right"
                              sx={{ color: "secondary.main" }}
                            >
                              {log.job_stage}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box>
                          <Typography variant="subtitle2">Working</Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(TimeClock);
