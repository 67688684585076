import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./hocs/Layout";
import './App.css';

// Base containers
import LandingPage from "./containers/base/LandingPage";
import TermsAndConditions from "./containers/base/TermsAndConditions";
import PrivacyPolicy from "./containers/base/PrivacyPolicy";
import CookiePolicy from "./containers/base/CookiePolicy";
import PageNotFound from "./containers/base/PageNotFound";

// Authentication containers
import Login from "./containers/authentication/Login";
import Signup from "./containers/authentication/Signup";
import Activate from "./containers/authentication/Activate";
import ResetPassword from "./containers/authentication/ResetPassword";
import ResetPasswordConfirm from "./containers/authentication/ResetPasswordConfirm";

// SaaS containers
import Overview from "./containers/saas/Overview/Overview";
import Activity from "./containers/saas/user/activity/Activity";
import Members from "./containers/saas/Members/members/Members";
import TimeClock from "./containers/saas/Jobs Tasks and Projects/time_clock/TimeClock";
import Tasks from "./containers/saas/user/tasks/Tasks";
import TestAPI from "./containers/TestAPI";

// Clients
import Clients from "./containers/saas/Clients/client/Clients";
// Teams 
import AddTeam from "./containers/saas/Members/teams/AddTeam";
import GetTeams from "./containers/saas/Members/teams/GetTeams";
import ChatSetup from "./containers/saas/Members/chat/ChatSetup";

// Job Scheduling 
import JobScheduling from "./containers/saas/Jobs Tasks and Projects/job_scheduler/JobScheduling";
import Projects from "./containers/saas/Jobs Tasks and Projects/projects/Projects";
import ProjectDetails from "./containers/saas/Jobs Tasks and Projects/projects/ProjectDetails";

// Forms
import Forms from "./containers/saas/Forms/Forms";
import CreateOrUpdateForms from "./containers/saas/Forms/CreateOrUpdateForms";
import ViewForm from "./containers/saas/Forms/ViewForm";
import FormEntries from "./containers/saas/Forms/FormEntries";

import Settings from "./containers/saas/Settings";

// import UserDashboard from "./containers/saas/user/UserDashboard";
// import DownloadPurchasedAsset from "./containers/saas/user/DownloadPurchasedAsset";
// import CompleteYourProfilePage from "./containers/saas/user/CompleteYourProfilePage";



// Redux Store
import { Provider } from "react-redux";
import store from "./store";

const App = () => {
  const mounted = useRef();

  // useEffect(() => {
  //   if (!mounted.current) {
  //     window.gtag("config", "UA-216385747-1", {
  //     page_title: window.location.pathname,
  //     page_path: window.location.pathname,
  //     })
  //     mounted.current = true;
  //   } else {
  //     // do elementDi<dUpdate /> logic
  //   }
  // });


  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Routes>
            {/* // Base Routes */}
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="*" element={<PageNotFound />} />

            {/* // Authentication routes */}
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/facebook" element={<LandingPage />} />
            <Route exact path="/google" element={<LandingPage />} />

            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route
              exact
              path="/account-activation-email-sent"
              element={<ResetPassword />}
            />
            <Route
              exact
              path="/password/reset/confirm"
              element={<ResetPasswordConfirm />}
            />
            <Route
              exact
              path="/password/reset/confirm/:uid/:token"
              element={<ResetPasswordConfirm />}
            />
            <Route exact path="/activate" element={<Activate />} />


            {/* {/* User Routes */}
            <Route exact path="/dashboard" element={<Overview />} />
            <Route exact path="/dashboard/overview" element={<Overview />} />
            <Route exact path="/dashboard/activity" element={<Activity />} />
            <Route exact path="/dashboard/test-api/" element={<TestAPI />} />
            {/* Members  */}
            <Route exact path="/dashboard/members" element={<Members />} />
            <Route exact path="/dashboard/teams" element={<GetTeams />} />
            <Route exact path="/dashboard/teams/:id" element={<AddTeam />} />
            <Route exact path="/dashboard/chat" element={<ChatSetup />} />
            {/* Job Scheduling   */}
            <Route exact path="/dashboard/job-scheduling" element={<JobScheduling />} />
            <Route exact path="/dashboard/projects" element={<Projects />} />
            <Route exact path="/dashboard/projects/details" element={<ProjectDetails />} />
            <Route exact path="/dashboard/time-clock" element={<TimeClock />} />
            <Route exact path="/dashboard/tasks" element={<Tasks />} />
            {/* Client   */}
            <Route exact path="/dashboard/clients" element={<Clients />} />
            <Route exact path="/dashboard/projects" element={<Projects />} />
            <Route exact path="/dashboard/projects/details" element={<ProjectDetails />} />
            {/* Forms */}
            <Route exact path="/dashboard/forms" element={<Forms />} />
            <Route exact path="/dashboard/forms/:id" element={<CreateOrUpdateForms />} />
            <Route exact path="/forms" element={<ViewForm />} />
            <Route exact path="/dashboard/form-entries" element={<FormEntries />} />
            {/* Settings */}
            <Route exact path="/dashboard/settings" element={<Settings />} />

            {/* <Route exact path="/:username" element={<User />} /> */}
            {/* <Route exact path="/:username/:product_slug" element={<ProductDetailPage />} />
            <Route exact path="/dashboard" element={<UserDashboard />} />
            <Route exact path="/download-purchased-asset/" element={<DownloadPurchasedAsset />} />
            <Route exact path="/complete-your-profile" element={<CompleteYourProfilePage />} /> */}

          </Routes>
        </Layout>
      </Router>
    </Provider>
  );
};

export default App;
