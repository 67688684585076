import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import "./input_style.css"

import { FileUploader } from "react-drag-drop-files";

function MultiFileInput({ already_created_files_length, files, set_files }) {

    const handleChange = (file) => {
        set_files(file);
    };

    return (
        <Box>
            <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="file"
                maxSize={10000}
                minSize={0}
                children={<Box sx={{ border: "3px solid #ff5782", borderStyle: "dashed", borderRadius: 1, cursor: "pointer", display: "flex", width: 1, minHeight: "250px", justifyContent: "center", alignItems: "center", "&:hover": { bgcolor: "#F9F9F9" } }}>
                    <Typography variant='subtitle1'><span style={{ textDecoration: "underline" }}>Upload</span> or drop a file right here</Typography>
                </Box>}
            />
            {Object.keys(files).map((file) => {
                return (
                    <Typography variant='subtitle2'>{files[file] ? <> <span style={{ fontWeight: "bold" }}>File name:</span> {files[file].name} </> : "no files uploaded yet"}</Typography>
                );
            })}
            {files &&
                <Typography variant='subtitle2'><span style={{ fontWeight: "bold" }}>Total files:</span> {files.length}</Typography>
            }
        </Box>
    );
}

export default MultiFileInput



