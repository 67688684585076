import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import {
  login,
  facebookAuthenticate,
  googleAuthenticate,
} from "../../actions/auth";
import axios from "axios";
// import GoogleLogin from "react-google-login";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { gapi } from "gapi-script";

// UI elements
import {
  TextField,
  Button,
  Container,
  CircularProgress,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";

const Login = ({ login, user, facebookAuthenticate, googleAuthenticate }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const [loading, set_loading] = useState(false);
  const navigate = useNavigate();

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    set_loading(true);
    await login(email, password);
    set_loading(false);
  };
  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.auth2.init({
  //       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //       scope: 'email profile',
  //       fetch_basic_profile: false
  //     });
  //   };
  //   gapi.load('client:auth2', initClient);
  // });

  const continueWithGoogle = (res) => {
    googleAuthenticate(res.accessToken);
  };

  const continueWithFacebook = (res) => {
    facebookAuthenticate(res.accessToken);
    // try {
    //     const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/facebook`)

    //     window.location.replace(res.data.authorization_url);
    // } catch (err) {

    // }
  };
  useEffect(() => {
    if (user) {
      if (localStorage.getItem("access")) {
        navigate(`/dashboard`);
      }
    }
  }, [user]);
  return (
    <div
      className="container"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          border: "1px solid #EAEAEA",
          borderRadius: 15,
          padding: 100,
        }}
      >
        <h1 style={{ margin: 0, textAlign: "center" }}>Login</h1>
        <p style={{ textAlign: "center" }}>Log into your Account</p>
        <br />
        <div
          style={{
            display: "flex",
            "flex-direction": "column",
            alignItems: "center",
          }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "140%",
            }}
            onSubmit={(e) => onSubmit(e)}
          >
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              margin="dense"
              size="small"
              type="email"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              required
            />
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              margin="dense"
              size="small"
              type="password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              minLength="1"
              required
            />
            <Button variant="contained" type="submit">
              {loading ? <CircularProgress color="secondary" /> : "Login"}
            </Button>
          </form>
          <br />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "140%",
            }}
          >
            <Box style={{ flex: 1 }}>
              {/* <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="CONTINUE WITH GOOGLE"
                render={renderProps => (
                  <Button variant="outlined" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled}>Continue with Google</Button>
                )}

                className="kep-login-google"
                onSuccess={continueWithGoogle}
                onFailure={continueWithGoogle}
                redirect_uri={`${process.env.REACT_APP_FRONTEND_URL}/google`}
                cookiePolicy={"single_host_origin"}
              /> */}
            </Box>

            {/* <Box sx={{ minWidth: "100%" }}>
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}


                fields="email, username"
                callback={continueWithFacebook}
                render={renderProps => (
                  <Button variant="outlined" fullWidth onClick={renderProps.onClick}>Continue with Facebook</Button>
                )}
              />
            </Box> */}
          </Box>
        </div>
        <br />
        <p className="mt-3" style={{ textAlign: "center" }}>
          Don't have an account?{" "}
          <Link
            to="/signup"
            className="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-k7pzs0-MuiTypography-root-MuiLink-root"
          >
            Sign Up
          </Link>
        </p>
        <p style={{ margin: 0, textAlign: "center" }}>
          Forgot your Password?{" "}
          <Link
            to="/reset-password"
            className="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-k7pzs0-MuiTypography-root-MuiLink-root"
          >
            Reset Password
          </Link>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  login,
  facebookAuthenticate,
  googleAuthenticate,
})(Login);
