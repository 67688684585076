import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import { load_user } from "../actions/auth";
import { Paper } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { theme } from "./ThemeConfig";
import AppSidebar from "../components/AppSidebar";

const Layout = ({ load_user, children }) => {
  const location = useLocation().pathname;
  const [dashboard_layout, set_dashboard_layout] = useState();
  
  useEffect(() => {
    load_user();
  }, []);

  useEffect(() => {
    if (location.includes("dashboard")){
      set_dashboard_layout(true)
    }else{

      set_dashboard_layout(false)
    }
  }, [location])
  

  return (
    <ThemeProvider theme={theme}>

      <Paper sx={{ boxShadow:0, }} >
      {dashboard_layout ? 
        <>
        <AppSidebar children={children} />
       
        </>
        
        :
        <>
        <Navbar />
        {children}
        <Footer />
        </>
        }
      </Paper>
    </ThemeProvider>
  );
};

export default connect(null, { load_user })(Layout);
