import {
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

export let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      thin: "#fff2f5",
      light: "#ff5782",
      main: "rgb(234, 36, 86)",
      dark: "#bb1d45",
    },
    secondary: {
      light: "#6ab9e5",
      main: "rgb(70, 123, 152)",
      dark: "#345c72",
    },
    success: {
      light: "#6ab9e5",
      main: "rgb(0, 199, 255)",
      dark: "#345c72",
    },
    customBox: {
      default: "#f7f7f7",
      themeAdaptive: "#202020",
    },
    action: {
      hover: "rgba(234, 36, 86,0.05)",
    },
    blackAndWhite: "#000000",
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'gray_button' },
          style: {
            margin: "0px",
            lineHeight: "1.5",
            height: '48px', // Adjusted height
            border: "1px solid #dddddd",
            letterSpacing: "0.00938em",
            boxShadow: "none",
            transition: "0.1s ",
            textTransform: "none",
            color: "#717171",
            fontWeight: 500,
            fontSize: "14px", // Adjusted font size
            pl: 4,
            pr: 4,
            "&:hover": { backgroundColor: "rgba(34,34,34,0.1)", border:"none", boxShadow: "none" },
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 24,
          py: 1,
          px: 4,
          whiteSpace: "nowrap",
          height: '48px', // Adjusted height
          minWidth: '125px',
          fontSize: '14px', // Adjusted font size
          ...(ownerState.variant === 'contained' && {
            boxShadow: "none",
            transition: "0.1s ",
            fontWeight: 600,
            textTransform: "none",
            "&:hover": { boxShadow: "none" },
          }),
        }),
      },
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "sans-serif"
    ].join(","),
    fontSize: 14, // Adjusted base font size without units
    color: "#222",
    h1: {
      fontSize: "30px", // Adjusted font size
      fontWeight: 600,
      textAlign: "center",
      lineHeight: 1.2, // Unitless line height
    },
    h2: {
      fontSize: "24px",
      fontWeight: 600,
      textAlign: "center",
      lineHeight: 1.2, // Unitless line height
    },
    h3: {
      fontSize: "20px",
      fontWeight: "bold",
      lineHeight: 1.2, // Unitless line height
    },
    subtitle1: {
      fontSize: "12px", // Adjusted font size
      fontWeight: 300,
      color: "gray",
      lineHeight: 1.5, // Unitless line height
    },
    subtitle2: {
      margin: "0px",
      fontWeight: "400",
      fontSize: "16px", // Adjusted font size
      lineHeight: 1.5, // Unitless line height
      letterSpacing: "0.00938em",
      color: "gray",
    },
    body: {
      margin: "0px",
      fontWeight: "400",
      fontSize: "14px", // Adjusted font size
      lineHeight: 1.5, // Unitless line height
      letterSpacing: "0.00938em",
      color: "#606060",
    },
  },
  
});

theme = responsiveFontSizes(theme);
